import React from "react";
import { ListGroupItem, ListGroupItemProps } from "reactstrap";
import CheckmarkIcon from "src/components/common/icons/CheckmarkIcon";
import styles from "./index.module.scss";
import classnames from "classnames";

const DropdownListItem: React.FC<ListGroupItemProps> = (props) => {
  const { active, children, className, ...rest } = props;

  const classes = classnames(styles.DropdownListItem, className);

  return (
    <ListGroupItem
      className={classes}
      tag="button"
      type="button"
      action
      active={active}
      {...rest}
    >
      {children}
      {active && <CheckmarkIcon />}
    </ListGroupItem>
  );
};

export default DropdownListItem;
