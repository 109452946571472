import { Action, action } from "easy-peasy";

export interface DataModel<T> {
  loading: boolean;
  error: Error | null;
  data: T;
  setLoading: Action<DataModel<T>, boolean>;
  setError: Action<DataModel<T>, Error | null>;
  setData: Action<DataModel<T>, T>;
}

export const dataModel = <T>(initialValue: T): DataModel<T> => {
  return {
    loading: false,
    error: null,
    data: initialValue,
    setLoading: action((state, loading) => {
      state.loading = loading;
      state.loading = loading;
    }),
    setError: action((state, err) => {
      state.error = err;
    }),
    setData: action((state, payload) => {
      state.data = payload;
    }),
  } as DataModel<any>;
};
