import React, { useState, useEffect } from "react";
import DateField from "./DateField";
import moment, { Moment } from "moment";
import { TFifteenMinuteInterval } from "src/lib/fifteenMinIntervals";
import TimeField from "./TimeField";
import styles from "../index.module.scss";

interface IProps {
  date: Moment;
  id: "start" | "end";
  setFieldValue: (id: "start" | "end", value: Moment) => void;
}

const DateTime: React.FC<IProps> = (props) => {
  const { date, id, setFieldValue } = props;
  const [dateMoment, setDateMoment] = useState(date);
  const [time, setTime] = useState(
    date.format("h:mma") as TFifteenMinuteInterval
  );

  useEffect(() => {
    const d = dateMoment.format("YYYY-MM-DD");
    const result = moment(`${d} ${time}`, "YYYY-MM-DD h:mma");
    setFieldValue(id, result);
  }, [setFieldValue, dateMoment, time, id]);

  return (
    <div className={styles.DateTimeSection}>
      <TimeField value={time} handleChange={setTime} id={id} />
      <DateField
        date={dateMoment}
        handleChange={setDateMoment}
        placement={id === "start" ? "center" : "right"}
      />
    </div>
  );
};

export default DateTime;
