import React, { useState } from "react";
import PopperJS from "popper.js";
import PopupHandler, { IPopupHandlerBag } from "./PopupHandler";

interface IProps {
  renderPopup: (data: IPopupHandlerBag) => React.ReactNode;
  children: (data: IPopupHandlerBag) => React.ReactNode;
  fillHeight?: boolean;
  placement?: PopperJS.Placement;
}

const ControlledPopupHandler: React.FC<IProps> = (props) => {
  const { children, ...rest } = props;
  const [popupVisible, setPopupVisible] = useState(false);

  return (
    <PopupHandler {...rest} visible={popupVisible} setVisible={setPopupVisible}>
      {children}
    </PopupHandler>
  );
};

export default ControlledPopupHandler;
