import React from "react";
import { useStoreState } from "src/store";
import ControlledPopupHandler from "src/components/common/popup/ControlledPopupHandler";
import ScheduleStatusBadge from "../../ScheduleStatusBadge";
import PublishedDraftManagerPopup from "./PublishedDraftManagerPopup";

const PublishedDraftActions: React.FC = () => {
  const draft = useStoreState((state) => state.draft.draft.data);

  if (!draft) {
    return null;
  }

  return (
    <ControlledPopupHandler
      renderPopup={({ hide }) => (
        <PublishedDraftManagerPopup draft={draft} handleClose={hide} />
      )}
      placement="bottom"
    >
      {({ show }) => (
        <ScheduleStatusBadge onClick={show} status="published" showArrow />
      )}
    </ControlledPopupHandler>
  );
};

export default PublishedDraftActions;
