import { TAutoschedulerEmployeeData } from "src/api/api.types";
import {
  TEmployeeRecords,
  TEmployeeRecord,
} from "src/components/scheduler/scheduler.types";

export const findEmployeeFromAutoschedulerShift = (
  emp: TAutoschedulerEmployeeData,
  employees: TEmployeeRecords
): TEmployeeRecord | null => {
  const uid = +emp.user_id > 0 ? `${emp.user_id}` : emp.employee_id;
  let employee: TEmployeeRecord | null = null;
  if (employees[uid]) {
    employee = employees[uid];
  } else {
    const matchedId = Object.keys(employees).find(
      (id) => employees[id].name === `${emp.first_name} ${emp.last_name}`
    );
    employee = matchedId && employees[matchedId] ? employees[matchedId] : null;
  }
  return employee;
};
