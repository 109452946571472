import React, { useState, useCallback } from "react";
import { FormikHelpers } from "formik";
import { TShiftFormValues } from "../shift_form/types";
import ShiftForm from "../shift_form/ShiftForm";
import { errorIsViolation } from "src/lib/violations";
import { useStoreActions, useStoreState } from "src/store";
import { prepareDraftShift } from "src/lib/drafts";
import { evaluateDraftWorkshift } from "src/api";
import { AxiosError } from "axios";
import { TShiftViolationErrorData } from "src/api/api.types";
import { Moment } from "moment";

interface IProps {
  role?: string;
  employeeId: string;
  notifyGroups?: number[];
  reason?: string;
  nearbyLocations?: string[];
  start: Moment;
  end: Moment;
  eventId: string;
  handleClose: () => void;
}

const EditShiftForm: React.FC<IProps> = (props) => {
  const { role, employeeId, start, end, eventId, handleClose } = props;
  const [showViolations, setShowViolations] = useState(false);
  const [violationMessage, setViolationMessage] = useState("");
  const editDraftShift = useStoreActions((state) => state.draft.editDraftShift);
  const editDraftViolation = useStoreActions(
    (state) => state.draft.editDraftViolation
  );
  const view = useStoreState((state) => state.scheduler.view);
  const employeeRecordsById = useStoreState(
    (state) => state.employees.employeeRecordsById
  );
  const locationId = useStoreState((state) => state.location.locationId);
  const events = useStoreState((state) => state.events.events);

  const submit = useCallback(
    async (
      values: TShiftFormValues,
      helpers: FormikHelpers<TShiftFormValues>
    ) => {
      helpers.setSubmitting(true);
      const employee = employeeRecordsById[values.employeeId];
      const currentShift = {
        start_time: values.start.format("YYYY-MM-DD HH:mm:ss"),
        end_time: values.end.format("YYYY-MM-DD HH:mm:ss"),
      };
      try {
        // if we are currently showing violations,
        // it means that the submit button should
        // bypass validation
        const runValidation = !showViolations;
        if (runValidation) {
          const shifts = events
            .filter((e) => e.type === "draft_shift" || e.type === "shift")
            .filter(
              (shift) =>
                shift.resourceId === values.employeeId && shift.id !== eventId
            )
            .map((shift) => ({
              start_time: shift.start.format("YYYY-MM-DD HH:mm:ss"),
              end_time: shift.end.format("YYYY-MM-DD HH:mm:ss"),
            }));

          await evaluateDraftWorkshift({
            user_id: employee.userId ? +employee.userId : -1,
            employee_id: values.employeeId,
            location_id: locationId,
            current_shift: currentShift,
            time_interval: view,
            shifts: [currentShift, ...shifts],
          });
          // if we get to here, it means that the user has no violations
          setViolationMessage("");
        }
        const shift = prepareDraftShift(
          {
            role: values.role,
            userId: employee.userId ? +employee.userId : 0,
            employeeId: employee.employeeId || null,
            userName: employee.name,
            start: values.start,
            end: values.end,
          },
          eventId
        );
        await editDraftShift({
          shift,
          employeeUid: employee.uid,
          violation: violationMessage,
        });
        // If the assignee has changed,
        // we should circle back and run violation checks
        // against the old employee.
        const changedAssignee = values.employeeId !== employeeId;
        if (changedAssignee) {
          const oldAssignee = employeeRecordsById[employeeId];
          const oldAssigneeShifts = events
            .filter((e) => e.type === "draft_shift" || e.type === "shift")
            .filter(
              (shift) => shift.resourceId === employeeId && shift.id !== eventId
            )
            .map((shift) => ({
              start_time: shift.start.format("YYYY-MM-DD HH:mm:ss"),
              end_time: shift.end.format("YYYY-MM-DD HH:mm:ss"),
            }));
          let oldAssigneeViolation = "";

          try {
            await evaluateDraftWorkshift({
              user_id: oldAssignee.userId ? +oldAssignee.userId : -1,
              employee_id: employeeId,
              location_id: locationId,
              current_shift: currentShift,
              time_interval: view,
              shifts: [...oldAssigneeShifts],
            });
          } catch (err) {
            if (errorIsViolation(err)) {
              oldAssigneeViolation = err.response?.data.meta.detail || "";
            }
          } finally {
            await editDraftViolation({
              employeeUid: employeeId,
              violation: oldAssigneeViolation,
            });
            return;
          }
        }
        handleClose();
      } catch (err) {
        if (errorIsViolation(err)) {
          setShowViolations(true);
          const error = err as AxiosError<TShiftViolationErrorData>;
          setViolationMessage(error.response?.data.meta.detail || "");
        }
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [
      showViolations,
      locationId,
      employeeRecordsById,
      view,
      events,
      handleClose,
      editDraftShift,
      eventId,
      violationMessage,
      editDraftViolation,
      employeeId,
    ]
  );

  return (
    <ShiftForm
      role={role}
      employeeId={employeeId}
      start={start}
      end={end}
      handleSubmit={submit}
      violationMessage={violationMessage}
      setViolationMessage={setViolationMessage}
      setShowViolations={setShowViolations}
      showViolations={showViolations}
    />
  );
};

export default EditShiftForm;
