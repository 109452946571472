import React from "react";
import styles from "./index.module.scss";
import useRoleColor from "../../hooks/useRoleColor";

interface IProps {
  label: string;
  value: string;
  role?: string;
}

const ListItem: React.FC<IProps> = (props) => {
  const { label, value, role } = props;
  const roleColor = useRoleColor(role);

  return (
    <div className={styles.ListItem}>
      <div className={styles.ListItem_Label} style={{ color: roleColor }}>
        {label}
      </div>
      <div className={styles.ListItem_Value}>{value}</div>
    </div>
  );
};

export default ListItem;
