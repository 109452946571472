import {
  TDraftWorkshift,
  TDraftOpenShift,
  TDraftViolations,
  TAutoschedulerEmployeeData,
  TDraftModel,
  TDraftData,
} from "src/api/api.types";
import { Moment } from "moment";
import uuid from "uuid/v1";
import { TEmployeeRecord } from "src/components/scheduler/scheduler.types";

export const makeDraftData = (
  workshifts: TDraftWorkshift[],
  open_shifts: TDraftOpenShift[],
  violations?: TDraftViolations,
  acknowledgedEmployeeViolations?: string[]
): TDraftData => {
  return {
    workshifts,
    open_shifts,
    violations: violations || {},
    acknowledged_employee_violations: acknowledgedEmployeeViolations || [],
  };
};

export const commitViolationToDraftViolations = (
  draftViolations: TDraftViolations,
  employeeUid: string,
  message: string | null
): TDraftViolations => {
  let violations = { ...draftViolations };
  if (!message) {
    delete violations[employeeUid];
  } else {
    violations = {
      ...violations,
      [employeeUid]: message,
    };
  }
  return violations;
};

export const acknowledgeViolation = (
  acknowledgedEmployeeViolations: string[],
  employeeUid: string
): string[] => {
  return acknowledgedEmployeeViolations
    .filter((v) => v !== employeeUid)
    .concat(employeeUid);
};

export const unacknowledgeViolation = (
  acknowledgedEmployeeViolations: string[],
  employeeUid: string
): string[] => {
  return acknowledgedEmployeeViolations.filter((v) => v !== employeeUid);
};

export const prepareDraftShift = (
  draft: {
    role: string;
    userId: number;
    userName: string;
    employeeId: string | null;
    start: Moment;
    end: Moment;
  },
  id?: string
): TDraftWorkshift => {
  const uid = id || uuid();
  return {
    id: uid,
    role: draft.role,
    user_id: draft.userId,
    employee_id: draft.employeeId,
    user_name: draft.userName,
    start_time: draft.start.format("YYYY-MM-DD HH:mm:ss"),
    end_time: draft.end.format("YYYY-MM-DD HH:mm:ss"),
  };
};

export const prepareDraftShiftFromAutoscheduler = (
  employee: TEmployeeRecord,
  autoschedulerEmployeeData: TAutoschedulerEmployeeData,
  shift: { end_time: string; start_time: string }
): TDraftWorkshift => {
  return {
    id: uuid(),
    role: autoschedulerEmployeeData.role,
    user_id: employee.userId ? +employee.userId : 0,
    employee_id: employee.employeeId,
    user_name: employee.name,
    start_time: shift.start_time,
    end_time: shift.end_time,
  };
};

export const prepareDraftOpenShift = (
  draft: {
    role: string;
    groups: number[];
    reason: string;
    end: Moment;
    start: Moment;
    nearbyLocations: string[];
  },
  id?: string
): TDraftOpenShift => {
  const uid = id || uuid();
  return {
    id: uid,
    role: draft.role,
    groups: draft.groups,
    reason: draft.reason,
    nearby_locations: draft.nearbyLocations,
    start_time: draft.start.format("YYYY-MM-DD HH:mm:ss"),
    end_time: draft.end.format("YYYY-MM-DD HH:mm:ss"),
  };
};

export const getDraftWorkshifts = (
  draft: TDraftModel | null
): TDraftWorkshift[] =>
  draft && !draft.archived ? JSON.parse(draft.shifts).workshifts : [];

export const getDraftOpenShifts = (
  draft: TDraftModel | null
): TDraftOpenShift[] =>
  draft && !draft.archived ? JSON.parse(draft.shifts).open_shifts : [];

export const getDraftViolations = (
  draft: TDraftModel | null
): TDraftViolations =>
  draft && !draft.archived ? JSON.parse(draft.shifts).violations || {} : {};

export const getAcknowledgedViolations = (
  draft: TDraftModel | null
): string[] =>
  draft && !draft.archived
    ? JSON.parse(draft.shifts).acknowledged_employee_violations || []
    : [];
