import { TTemplate, TPrimaryKey, TSecondaryKey } from "src/api/api.types";

export const getSecondaryTemplateValues = (
  templates: TTemplate[],
  primaryKey: TPrimaryKey,
  secondaryKey: TSecondaryKey
) => {
  let values: string[] = [];
  const primary = templates.find((t) => t.primary_template_id === primaryKey);
  if (primary) {
    const secondary = primary.secondary_templates.find(
      (t) => t.secondary_template_id === secondaryKey
    );
    if (secondary) {
      values = secondary.values || [];
    }
  }
  return values;
};
