import React, { useState, useCallback } from "react";
import styles from "../index.module.scss";
import { DayPickerSingleDateController } from "react-dates";
import { Moment } from "moment";
import useEscapeClickHandler from "src/components/scheduler/hooks/useEscapeClickHandler";
import useOutsideClickHander from "src/components/scheduler/hooks/useOutsideClickHandler";
import classnames from "classnames";

interface IProps {
  date: Moment;
  handleChange: (m: Moment) => void;
  placement: "right" | "center";
}

const DateField: React.FC<IProps> = (props) => {
  const { date, handleChange, placement } = props;
  const ref = React.useRef<HTMLDivElement>(null);
  const [focused, setFocused] = useState(false);

  const onDateChange = useCallback(
    (m: Moment | null) => {
      if (m) handleChange(m);
      setFocused(false);
    },
    [handleChange, setFocused]
  );

  const toggleDatepicker = useCallback(
    (e: React.MouseEvent) => {
      setFocused((f) => !f);
      e.preventDefault();
    },
    [setFocused]
  );

  const unfocusDatepicker = useCallback(() => {
    if (focused) setFocused(false);
  }, [focused, setFocused]);

  useEscapeClickHandler(unfocusDatepicker);
  useOutsideClickHander(ref, unfocusDatepicker, focused);

  const calendarClasses = classnames(styles.ShiftCalendar, "shadow", {
    [styles.ShiftCalendar_Center]: placement === "center",
    [styles.ShiftCalendar_Right]: placement === "right",
  });

  return (
    <div className={styles.ShiftCalendarContainer} ref={ref}>
      <button className={styles.ShiftFormInput} onClick={toggleDatepicker}>
        {date.format("MMM D")}
      </button>
      {focused && (
        <div className={calendarClasses}>
          <DayPickerSingleDateController
            date={date}
            onDateChange={onDateChange}
            focused={true}
            onFocusChange={() => {}}
            enableOutsideDays={false}
            numberOfMonths={1}
          />
        </div>
      )}
    </div>
  );
};

export default DateField;
