import React, { useMemo, useState, useCallback } from "react";
import { TDecoratedShift, TShiftPopupAction } from "../../scheduler.types";
import InteractiveShiftWrapper from "../../InteractiveShiftWrapper";
import NearbyShiftPopup from "./NearbyShiftPopup";
import useRoleColor from "../../hooks/useRoleColor";
import BaseEventCard from "../../BaseEventCard";
import PrettyTimeRange from "src/components/common/pretty_time_range/PrettyTimeRange";
import useDuration from "src/hooks/useDuration";
import PopupHandler, {
  IPopupHandlerBag,
} from "src/components/common/popup/PopupHandler";

interface IProps {
  compact?: boolean;
  event: TDecoratedShift;
}

const NearbyShiftEvent: React.FC<IProps> = (props) => {
  const { event } = props;
  const [popupVisible, setPopupVisible] = useState(false);
  const [initialPopupView, setInitialPopupView] = useState<TShiftPopupAction>(
    "read"
  );
  const roleColor = useRoleColor(event.data.role);
  const duration = useDuration(event.start, event.end);

  const containerStyles = useMemo(
    () => ({
      backgroundColor: roleColor,
    }),
    [roleColor]
  );

  const setVisible = useCallback(
    (visible: boolean) => {
      if (!visible) {
        setInitialPopupView("read");
      }
      setPopupVisible(visible);
    },
    [setInitialPopupView, setPopupVisible]
  );

  const renderPopup = useCallback(
    ({ hide }: IPopupHandlerBag) => (
      <NearbyShiftPopup
        event={event}
        initialView={initialPopupView}
        close={hide}
      />
    ),
    [event, initialPopupView]
  );

  return (
    <InteractiveShiftWrapper event={event}>
      <PopupHandler
        renderPopup={renderPopup}
        placement="bottom"
        visible={popupVisible}
        setVisible={setVisible}
        fillHeight={true}
      >
        {({ visible, show }) => (
          <BaseEventCard
            event={event}
            isActive={visible}
            style={containerStyles}
            onClick={show}
          >
            <PrettyTimeRange start={event.start} end={event.end} />
            <div>
              <b>
                <i>Nearby</i>
              </b>{" "}
              - {event.data.role} ({duration}hrs)
            </div>
          </BaseEventCard>
        )}
      </PopupHandler>
    </InteractiveShiftWrapper>
  );
};

export default NearbyShiftEvent;
