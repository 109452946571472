import React from "react";
import ControlledPopupHandler from "src/components/common/popup/ControlledPopupHandler";
import IconButton from "src/components/common/icon-button/IconButton";
import PdfDownloadPopup from "./PdfDownloadPopup";
import { DownloadIcon } from "src/components/common/icons";

const PdfDownload: React.FC = () => {
  return (
    <ControlledPopupHandler
      placement="bottom-end"
      renderPopup={({ hide }) => <PdfDownloadPopup handleClose={hide} />}
    >
      {({ show }) => (
        <IconButton
          tooltip="Download PDF"
          id="DownloadPDFAction"
          onClick={show}
        >
          <DownloadIcon width="1rem" />
        </IconButton>
      )}
    </ControlledPopupHandler>
  );
};

export default PdfDownload;
