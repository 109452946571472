import React, { useCallback } from "react";
import { PopupCard } from "src/components/common/popup";
import { CardHeader, CardBody } from "reactstrap";
import PopupHeadIcons from "src/components/common/popup/PopupHeadIcons";
import IconButton from "src/components/common/icon-button/IconButton";
import CloseIcon from "src/components/common/icons/CloseIcon";
import ViolationMessage from "../components/violation/ViolationMessage";
import StatefulButton from "src/components/common/stateful-button";
import { useStoreActions } from "src/store";

interface IProps {
  handleClose: () => void;
  violationItems: string[];
  employeeId: string;
  isAcknowledged: boolean;
}

const popupStyles = {
  width: "400px",
};

const EmployeeDraftViolationPopup: React.FC<IProps> = (props) => {
  const { handleClose, violationItems, employeeId, isAcknowledged } = props;
  const acknowledgeViolation = useStoreActions(
    (state) => state.draft.acknowledgeViolation
  );

  const acknowledge = useCallback(async () => {
    await acknowledgeViolation(employeeId);
  }, [acknowledgeViolation, employeeId]);

  return (
    <PopupCard style={popupStyles} className="pb-0">
      <CardHeader>
        <h5>Draft violations</h5>
        <PopupHeadIcons>
          <IconButton
            tooltip="Close"
            onClick={handleClose}
            id="EmployeeDraftViolationPopupClose"
          >
            <CloseIcon width={16} />
          </IconButton>
        </PopupHeadIcons>
      </CardHeader>
      <CardBody className="pt-0">
        <ViolationMessage
          details={violationItems}
          description="The following rules are violated:"
        />
        {isAcknowledged ? (
          <p className="font-italic float-right">Acknowledged.</p>
        ) : (
          <StatefulButton
            size="sm"
            className="float-right"
            color="secondary"
            outline
            onClick={acknowledge}
            loading={false}
          >
            Mark as Acknowledged
          </StatefulButton>
        )}
      </CardBody>
    </PopupCard>
  );
};

export default EmployeeDraftViolationPopup;
