import { Moment } from "moment";

export const ISO_WEEKDAYS = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

export type TWeekdayString =
  | "sunday"
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday";

export const WEEKDAY_STRINGS: TWeekdayString[] = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

// returns an ordered array of
// weekdays starting from given offset
export const getOffsetWeekArray = (offset: number) => {
  if (offset === 0) return WEEKDAY_STRINGS;

  return [
    ...WEEKDAY_STRINGS.slice(offset),
    ...WEEKDAY_STRINGS.slice(0, offset),
  ];
};

export const nearestMinutes = (interval: number, someMoment: Moment) => {
  const roundedMinutes =
    Math.round(someMoment.clone().minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
};

export const nearestPastMinutes = (interval: number, someMoment: Moment) => {
  const roundedMinutes = Math.floor(someMoment.minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
};

export const nearestFutureMinutes = (interval: number, someMoment: Moment) => {
  const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
};
