import React from "react";
import { Navbar, NavbarBrand, Nav } from "reactstrap";
import { useStoreState } from "src/store";
import { ComboMark } from "../common/logos";
import DropdownButton from "../common/dropdown-button/DropdownButton";
import ControlledPopupHandler from "../common/popup/ControlledPopupHandler";
import LocationsPopup from "./LocationsPopup";
import styles from "./index.module.scss";
import SideMenu from "../side-menu/SideMenu";
import AccountDropdown from "../account-dropdown/AccountDropdown";
// build
const SiteNav: React.FC = () => {
  const location = useStoreState((state) => state.location.location);

  return (
    <Navbar className="bg-primary">
      <SideMenu />
      <NavbarBrand href="/" className={styles.Brand}>
        <ComboMark fill="white" height={30} />
      </NavbarBrand>
      <Nav navbar>
        <ControlledPopupHandler
          renderPopup={({ hide }) => <LocationsPopup handleClose={hide} />}
        >
          {({ show }) => (
            <DropdownButton color="primary" lightArrow onClick={show}>
              {location && location.location_name}
            </DropdownButton>
          )}
        </ControlledPopupHandler>
      </Nav>
      <AccountDropdown />
    </Navbar>
  );
};

export default SiteNav;
