import React from "react";
import {
  TDecoratedShift,
  TDecoratedOpenShift,
  TDecoratedTimeoff,
  TDecoratedDraftShift,
  TDecoratedDraftOpenShift,
} from "src/components/scheduler/scheduler.types";
import ShiftEvent from "./components/shift/ShiftEvent";
import OpenShiftEvent from "./components/open_shift/OpenShiftEvent";
import TimeoffEvent from "./components/timeoff/TimeoffEvent";
import DraftShiftEvent from "./components/draft_shift/DraftShiftEvent";
import DraftOpenShiftEvent from "./components/draft_shift/DraftOpenShiftEvent";
import { IDecoratedEvent } from "../schedulerTable/types";
import NearbyShiftEvent from "./components/shift/NearbyShiftEvent";

interface IProps {
  event: IDecoratedEvent;
  canResize: boolean;
  compact?: boolean;
}

const EventHandler: React.FC<IProps> = (props) => {
  const { event, canResize, compact } = props;

  if (event.type === "shift" && event.isNearby) {
    return (
      <NearbyShiftEvent event={event as TDecoratedShift} compact={compact} />
    );
  }

  if (event.type === "shift") {
    return (
      <ShiftEvent
        event={event as TDecoratedShift}
        compact={compact}
        canResize={canResize}
      />
    );
  }

  if (event.type === "draft_shift") {
    return (
      <DraftShiftEvent
        event={event as TDecoratedDraftShift}
        compact={compact}
        canResize={canResize}
      />
    );
  }

  if (event.type === "draft_open_shift") {
    return (
      <DraftOpenShiftEvent
        event={event as TDecoratedDraftOpenShift}
        compact={compact}
        canResize={canResize}
      />
    );
  }

  if (event.type === "open_shift") {
    return (
      <OpenShiftEvent
        event={event as TDecoratedOpenShift}
        compact={compact}
        canResize={canResize}
      />
    );
  }

  if (event.type === "timeoff") {
    return (
      <TimeoffEvent event={event as TDecoratedTimeoff} compact={compact} />
    );
  }

  return null;
};

export default EventHandler;
