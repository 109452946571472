import React from "react";
import SchedulerActions from "./SchedulerActions";
import DayView from "./components/day/DayView";
import WeekView from "./components/week/WeekView";
import ThreeDayView from "./components/three_day/ThreeDayView";
import { useStoreState } from "src/store";
import styles from "./index.module.scss";
import { Spinner } from "../common/loader";
import CalendarErrorBoundary from "./CalendarErrorBoundary";

const SchedulerView = () => {
  const view = useStoreState((state) => state.scheduler.view);
  const initialized = useStoreState((state) => state.scheduler.initialized);

  if (!initialized) {
    return <Spinner />;
  }

  return (
    <div className="App">
      <SchedulerActions />

      <div className={styles.SchedulerContainer}>
        <CalendarErrorBoundary>
          {view === "week" && <WeekView />}
          {view === "day" && <DayView />}
          {view === "three_day" && <ThreeDayView />}
        </CalendarErrorBoundary>
      </div>
    </div>
  );
};

export default SchedulerView;
