import React, { useCallback } from "react";
import styles from "./index.module.scss";
import { SchedulerTypes } from "../schedulerTable";
import { useStoreActions } from "src/store";
import classnames from "classnames";

interface IProps {
  col: SchedulerTypes.IColumn;
  format: string;
  allowClick: boolean;
}

const HeadColumn: React.FC<IProps> = (props) => {
  const { col, format, allowClick } = props;

  const setToDayView = useStoreActions(
    (actions) => actions.scheduler.setToDayView
  );

  const handleClick = useCallback(() => {
    if (allowClick) setToDayView(col.time);
  }, [allowClick, setToDayView, col.time]);

  const classes = classnames(styles.HeadColumn, {
    [styles.HeadColumn_Clickable]: allowClick,
  });

  return (
    <div className={classes} onClick={handleClick}>
      {col.time.format(format)}
    </div>
  );
};

export default HeadColumn;
