import React from "react";
import Drawer from "../drawer/Drawer";
import DemandForecast from "./DemandForecast";

const DemandForecastContainer: React.FC = () => {
  return (
    <Drawer buttonText="Demand Forecast">
      {({ open }) => (open ? <DemandForecast /> : null)}
    </Drawer>
  );
};

export default DemandForecastContainer;
