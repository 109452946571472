import React, { useState, useCallback } from "react";
import { FormikHelpers } from "formik";
import { TOpenShiftFormValues } from "./types";
import { errorIsViolation } from "src/lib/violations";
import { AxiosError } from "axios";
import { TShiftViolationErrorData } from "src/api/api.types";
import { useStoreActions } from "src/store";
import { validateAndUpdateShift } from "src/api";
import OpenShiftForm from "./OpenShiftForm";
import { Moment } from "moment";

interface IProps {
  role?: string;
  notifyGroups?: number[];
  reason?: string;
  nearbyLocations?: string[];
  start: Moment;
  end: Moment;
  eventId: string;
  handleClose: () => void;
}

const EditOpenShiftForm: React.FC<IProps> = (props) => {
  const {
    role,
    notifyGroups,
    reason,
    nearbyLocations,
    start,
    end,
    eventId,
    handleClose,
  } = props;
  const [showViolations, setShowViolations] = useState(false);
  const [violationMessage, setViolationMessage] = useState("");

  const updateOpenShift = useStoreActions(
    (actions) => actions.shifts.updateOpenShift
  );

  const submit = useCallback(
    async (
      values: TOpenShiftFormValues,
      helpers: FormikHelpers<TOpenShiftFormValues>
    ) => {
      helpers.setSubmitting(true);
      const startTime = values.start.format("YYYY-MM-DD HH:mm:ss");
      const endTime = values.end.format("YYYY-MM-DD HH:mm:ss");
      try {
        // if we are currently showing violations,
        // it means that the submit button should
        // bypass validation
        const runValidation = !showViolations;
        const res = await validateAndUpdateShift(eventId, {
          start_time: startTime,
          end_time: endTime,
          open_shift: "Y",
          role: values.role,
          reason: values.reason,
          groups: values.notifyGroups,
          skip_violation_check: !runValidation ? 1 : 0,
        });
        updateOpenShift(res.data.data);

        handleClose();
      } catch (err) {
        if (errorIsViolation(err)) {
          setShowViolations(true);
          const error = err as AxiosError<TShiftViolationErrorData>;
          setViolationMessage(error.response?.data.meta.detail || "");
        }
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [eventId, showViolations, handleClose, updateOpenShift]
  );

  return (
    <OpenShiftForm
      role={role}
      start={start}
      end={end}
      notifyGroups={notifyGroups}
      reason={reason}
      nearbyLocations={nearbyLocations}
      handleSubmit={submit}
      violationMessage={violationMessage}
      setViolationMessage={setViolationMessage}
      setShowViolations={setShowViolations}
      showViolations={showViolations}
    />
  );
};

export default EditOpenShiftForm;
