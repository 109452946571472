import React, { useState, useMemo, useCallback } from "react";
import { Label, ListGroup } from "reactstrap";
import { useStoreState } from "src/store";
import styles from "../index.module.scss";
import useOutsideClickHander from "src/components/scheduler/hooks/useOutsideClickHandler";
import useEscapeClickHandler from "src/components/scheduler/hooks/useEscapeClickHandler";
import DropdownListItem from "src/components/common/dropdown-list/DropdownListItem";
import ChevronDownIcon from "src/components/common/icons/ChevronDownIcon";

interface IProps {
  value: number[];
  setFieldValue: (id: "notifyGroups", value: number[]) => void;
}

const FIELD_ID = "notifyGroups";

const NotifyGroupsField: React.FC<IProps> = (props) => {
  const { value, setFieldValue } = props;
  const [open, setOpen] = useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  const notifyGroups = useStoreState((state) => state.roles.groups.data);

  const hide = useCallback(() => {
    if (open) setOpen(false);
  }, [open, setOpen]);

  useEscapeClickHandler(hide);
  useOutsideClickHander(ref, hide, open);

  const selectRole = useCallback(
    (role: number) => () => setFieldValue(FIELD_ID, [...value, role]),
    [setFieldValue, value]
  );

  const selectAll = useCallback(() => setFieldValue(FIELD_ID, []), [
    setFieldValue,
  ]);

  const valueLabel = useMemo(() => {
    if (value.length === 0) return "Everyone";
    return `${value.length} of ${notifyGroups.length}`;
  }, [value, notifyGroups]);

  return (
    <div className={`${styles.ShiftDropdownContainer} form-group`} ref={ref}>
      <Label for={FIELD_ID}>Notify</Label>
      <div className={styles.ShiftDropdownValue} onClick={() => setOpen(!open)}>
        {valueLabel}
        <ChevronDownIcon />
      </div>
      {open && (
        <div className={styles.ShiftDropdown}>
          <ListGroup flush>
            <DropdownListItem onClick={selectAll} active={value.length === 0}>
              Everyone
            </DropdownListItem>
            {notifyGroups.map((group) => (
              <DropdownListItem
                key={group.group_id}
                active={value.includes(+group.group_id)}
                onClick={selectRole(+group.group_id)}
              >
                {group.group_name}
              </DropdownListItem>
            ))}
          </ListGroup>
        </div>
      )}
    </div>
  );
};

export default NotifyGroupsField;
