import { useMemo } from "react";
import {
  TForecastMetricsModel,
  TScheduledMetricsModel,
} from "src/lib/scheduleMetrics";

export const useRoleMetrics = (
  forecast: TForecastMetricsModel,
  scheduled: TScheduledMetricsModel
) => {
  return useMemo(
    () =>
      Object.keys(forecast.roleTotalHours).map((roleName) => ({
        name: roleName,
        forecast: forecast.roleTotalHours[roleName],
        scheduled: scheduled.roleTotalHours[roleName],
      })),
    [forecast, scheduled]
  );
};
