import React, { useState, useCallback } from "react";
import StatefulButton from "src/components/common/stateful-button";
import { errorIsViolation } from "src/lib/violations";
import { AxiosError } from "axios";
import { TShiftViolationErrorData } from "src/api/api.types";
import ViolationMessage from "../violation/ViolationMessage";
import { useStoreActions } from "src/store";
import { deleteWorkshift } from "src/api";

interface IProps {
  eventId: string;
  handleClose: () => void;
}

const DeleteShift: React.FC<IProps> = (props) => {
  const { eventId, handleClose } = props;
  const [showViolations, setShowViolations] = useState(false);
  const [violationMessage, setViolationMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const removeWorkshift = useStoreActions(
    (actions) => actions.shifts.removeWorkshift
  );

  const handleDelete = useCallback(async () => {
    setLoading(true);
    try {
      const runValidation = !showViolations;
      await deleteWorkshift(eventId, runValidation);
      // We can now store the workshift locally
      removeWorkshift(eventId);
      handleClose();
    } catch (err) {
      if (errorIsViolation(err)) {
        setShowViolations(true);
        const error = err as AxiosError<TShiftViolationErrorData>;
        setViolationMessage(error.response?.data.meta.detail || "");
      }
    } finally {
      setLoading(false);
    }
  }, [eventId, handleClose, removeWorkshift, showViolations]);

  return (
    <>
      {showViolations ? (
        <ViolationMessage
          title="Encountered violations"
          details={violationMessage}
          description="The following violations occurred for this user when trying to delete this shift:"
          subtext="To ignore this warning, you can proceed below."
        />
      ) : (
        <p>Are you sure you want to delete this workshift?</p>
      )}

      <div className="d-flex align-items-center justify-content-between mt-2">
        <StatefulButton
          color="danger"
          onClick={handleDelete}
          className="ml-auto"
          loading={loading}
        >
          {showViolations ? "Proceed" : "Delete"}
        </StatefulButton>
      </div>
    </>
  );
};

export default DeleteShift;
