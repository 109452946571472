import React, { useMemo } from "react";
import ArrowDownIcon from "../icons/ArrowDownIcon";

interface IProps extends React.SVGProps<SVGSVGElement> {
  flip?: boolean;
  active: boolean;
}

const NORMAL = "0deg";
const FLIPPED = "180deg";

const ArrowToggle: React.FC<IProps> = (props) => {
  const { flip, active, ...rest } = props;

  const degree = useMemo(() => {
    if (flip) {
      return active ? NORMAL : FLIPPED;
    }
    return active ? FLIPPED : NORMAL;
  }, [flip, active]);

  const styles = useMemo(
    () => ({
      marginTop: "-0.1875em",
      transform: `rotate(${degree})`,
      transition: "transform 0.3s ease-in-out",
    }),
    [degree]
  );

  return <ArrowDownIcon style={styles} {...rest} />;
};

export default ArrowToggle;
