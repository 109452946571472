import React, { useMemo, useCallback } from 'react';
import { useForecastQuery, useForecastChartData } from './hooks';
import {
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  Rectangle,
  ContentRenderer,
} from 'recharts';
import styles from './index.module.scss';
import { DOMINOS_ROLES, TDominosRoleName } from 'src/lib/dominos';
import { useStoreState } from 'src/store';
import {
  getScheduledMetricsForDay,
  getForecastedMetricsForDay,
  getVarianceMetricsForDay,
} from 'src/lib/scheduleMetrics';
import TotalColumn from './TotalColumn';
import { TWeekdayString } from 'src/lib/dates';

const CustomBar: ContentRenderer<{ index: number }> = (props) => {
  return (
    <Rectangle
      {...props}
      fill={props.index % 2 > 0 ? '#fff' : '#efefef'}
      fillOpacity="50%"
    />
  );
};

const useDemandForecastLabelFormatter = () => {
  const start = useStoreState((state) => state.scheduler.start);
  return useCallback(
    (label: number | string) => {
      const startTime = start
        .clone()
        .add(+label * 15, 'minutes')
        .format('h:mm a');
      const endTime = start
        .clone()
        .add((+label + 1) * 15, 'minutes')
        .format('h:mm a');
      return `${startTime} - ${endTime}`;
    },
    [start]
  );
};

const DemandForecast: React.FC = () => {
  useForecastQuery();

  const { chartData, positionsWithData } = useForecastChartData();
  const roleColors = useStoreState((state) => state.templates.roleColors);
  const events = useStoreState((state) => state.events.events);
  const roles = useStoreState((state) => state.templates.templateRoles);
  const schedulingDemand = useStoreState(
    (state) => state.demand.schedulingDemand.data
  );
  const start = useStoreState((state) => state.scheduler.start);

  const formatLabel = useDemandForecastLabelFormatter();

  const weekday = start.format('dddd').toLowerCase() as TWeekdayString;

  const scheduledEventMetrics = useMemo(
    () => getScheduledMetricsForDay(roles, events, weekday),
    [roles, events, weekday]
  );

  const forecastMetrics = useMemo(() => {
    return getForecastedMetricsForDay(
      roles,
      schedulingDemand ? schedulingDemand[weekday] : null
    );
  }, [roles, schedulingDemand, weekday]);

  const variance = useMemo(
    () => getVarianceMetricsForDay(scheduledEventMetrics, forecastMetrics),
    [scheduledEventMetrics, forecastMetrics]
  );

  // as long as at least one position has data,
  // we should show the chart
  const hasData = useMemo(() => {
    return Object.keys(positionsWithData).some(
      (p) => !!positionsWithData[p as TDominosRoleName]
    );
  }, [positionsWithData]);

  return (
    <div className={styles.DemandForecast}>
      <div className={styles.DemandForecast_Aside}>
        <div className={styles.DemandForecast_AsideCol}>
          <TotalColumn
            scheduled={scheduledEventMetrics}
            forecasted={forecastMetrics}
            variance={variance}
          />
        </div>
      </div>
      <div className={styles.DemandForecast_Main}>
        <div style={{ width: '100%', minWidth: '1200px', height: 200 }}>
          {hasData && (
            <ResponsiveContainer>
              <BarChart data={chartData} stackOffset="sign">
                <XAxis hide={true} interval={3} />

                <Tooltip labelFormatter={formatLabel} />
                <Legend align="left" />
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <ReferenceLine y={0} stroke="#000" />
                {positionsWithData[DOMINOS_ROLES.D] && (
                  <Bar
                    barSize={15}
                    dataKey={DOMINOS_ROLES.D}
                    stackId="a"
                    fill={roleColors[DOMINOS_ROLES.D]}
                    background={CustomBar}
                  />
                )}
                {positionsWithData[DOMINOS_ROLES.I] && (
                  <Bar
                    barSize={15}
                    dataKey={DOMINOS_ROLES.I}
                    stackId="a"
                    fill={roleColors[DOMINOS_ROLES.I]}
                  />
                )}
                {positionsWithData[DOMINOS_ROLES.A] && (
                  <Bar
                    barSize={15}
                    dataKey={DOMINOS_ROLES.A}
                    stackId="a"
                    fill={roleColors[DOMINOS_ROLES.A]}
                  />
                )}
                {positionsWithData[DOMINOS_ROLES.H] && (
                  <Bar
                    barSize={15}
                    dataKey={DOMINOS_ROLES.H}
                    stackId="a"
                    fill={roleColors[DOMINOS_ROLES.H]}
                  />
                )}
                {positionsWithData[DOMINOS_ROLES.C] && (
                  <Bar
                    barSize={15}
                    dataKey={DOMINOS_ROLES.C}
                    stackId="a"
                    fill={roleColors[DOMINOS_ROLES.C]}
                  />
                )}
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default DemandForecast;
