import React, { useState, useEffect } from "react";

const useHoverFlag = (ref: React.MutableRefObject<HTMLElement | null>) => {
  const [on, setOn] = useState(false);
  const openSesame = () => setOn(true);
  const closeSesame = () => setOn(false);

  useEffect(() => {
    const curr = ref?.current;
    if (curr) {
      curr.addEventListener("mouseenter", openSesame);
      curr.addEventListener("mouseleave", closeSesame);
    }

    return () => {
      if (curr) {
        curr.removeEventListener("mouseenter", openSesame);
        curr.removeEventListener("mouseleave", closeSesame);
      }
    };
  }, [ref]);

  return on;
};

export default useHoverFlag;
