import React from "react";
import { ListGroup, CardBody } from "reactstrap";
import { PopupCard } from "src/components/common/popup";
import { useStoreActions, useStoreState } from "src/store";
import DropdownListItem from "src/components/common/dropdown-list/DropdownListItem";
import moment from "moment";
import { VIEW_LABELS } from "src/lib/rangeHandler";

interface IProps {
  handleClose: () => void;
}

const styles = {
  popup: {
    width: "150px",
    maxHeight: "250px",
  },
};

const ViewPickerPopup: React.FC<IProps> = (props) => {
  const view = useStoreState((state) => state.scheduler.view);
  const setToDayView = useStoreActions(
    (actions) => actions.scheduler.setToDayView
  );
  const setToWeekView = useStoreActions(
    (actions) => actions.scheduler.setToWeekView
  );
  const setToThreeDayView = useStoreActions(
    (actions) => actions.scheduler.setToThreeDayView
  );

  const selectDayView = () => {
    setToDayView(moment());
    props.handleClose();
  };

  const selectWeekView = () => {
    setToWeekView();
    props.handleClose();
  };

  const selectThreeDayView = () => {
    setToThreeDayView();
    props.handleClose();
  };

  return (
    <PopupCard style={styles.popup}>
      <CardBody className="px-0 py-2">
        <ListGroup flush>
          <DropdownListItem active={view === "day"} onClick={selectDayView}>
            {VIEW_LABELS.day}
          </DropdownListItem>
          <DropdownListItem active={view === "week"} onClick={selectWeekView}>
            {VIEW_LABELS.week}
          </DropdownListItem>
          <DropdownListItem
            active={view === "three_day"}
            onClick={selectThreeDayView}
          >
            {VIEW_LABELS.three_day}
          </DropdownListItem>
        </ListGroup>
      </CardBody>
    </PopupCard>
  );
};

export default ViewPickerPopup;
