import React from "react";
import { IColumn, RenderHeadCol } from "./types";
import styles from "./index.module.scss";
import { useStoreState } from "src/store";

interface IProps {
  col: IColumn;
  renderHeadCol: RenderHeadCol;
}

const HeadColWrapper: React.FC<IProps> = (props) => {
  const minColWidth = useStoreState(
    (state) => state.scheduler.layout.minColWidth
  );
  const { col, renderHeadCol } = props;

  const style = {
    flexBasis: `${minColWidth}px`,
  };

  return (
    <div className={styles.HeadColWrapper} style={style}>
      {renderHeadCol(col)}
    </div>
  );
};

export default HeadColWrapper;
