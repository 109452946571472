import React, { useEffect } from "react";
import useDisableScroll from "./useDisableScroll";
import { useScroll } from "react-use";

const useScrollBind = <T, K>(
  master: React.RefObject<HTMLElement>,
  drone: React.RefObject<HTMLElement>
) => {
  // first we disable scroll handlers on the drone
  useDisableScroll(drone);

  const { x: scrollX } = useScroll(master);

  useEffect(() => {
    if (drone.current !== null) {
      drone.current.scrollLeft = scrollX;
    }
  }, [scrollX, drone]);
};

export default useScrollBind;
