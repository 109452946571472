import React from "react";
import { useStoreState } from "src/store";
import ControlledPopupHandler from "src/components/common/popup/ControlledPopupHandler";
import DraftManagerPopup from "./DraftManagerPopup";
import ScheduleStatusBadge from "../../ScheduleStatusBadge";

const DraftActions: React.FC = () => {
  const draft = useStoreState((state) => state.draft.draft.data);

  if (!draft) {
    return null;
  }

  return (
    <ControlledPopupHandler
      renderPopup={({ hide }) => (
        <DraftManagerPopup draft={draft} handleClose={hide} />
      )}
      placement="bottom"
    >
      {({ show }) => (
        <ScheduleStatusBadge onClick={show} status="draft" showArrow />
      )}
    </ControlledPopupHandler>
  );
};

export default DraftActions;
