import React, { useMemo } from "react";
import { ListGroup, CardBody } from "reactstrap";
import { PopupCard } from "src/components/common/popup";
import { useStoreActions, useStoreState } from "src/store";
import DropdownListItem from "src/components/common/dropdown-list/DropdownListItem";

interface IProps {
  handleClose: () => void;
}

const LocationsPopup: React.FC<IProps> = (props) => {
  const userLocations = useStoreState(
    (state) => state.userLocations.locations.data
  );
  const setLocation = useStoreActions(
    (actions) => actions.location.setLocation
  );

  const sortedLocations = useMemo(
    () =>
      userLocations.sort((a, b) =>
        a.location_name > b.location_name
          ? 1
          : b.location_name > a.location_name
          ? -1
          : 0
      ),
    [userLocations]
  );

  return (
    <PopupCard
      style={{
        maxHeight: "250px",
        width: "300px",
        overflowY: "auto",
      }}
    >
      <CardBody className="px-0 py-2">
        <ListGroup flush>
          {sortedLocations.map((loc) => (
            <DropdownListItem
              key={loc.location_id}
              onClick={() => setLocation(loc)}
            >
              {loc.location_name}
            </DropdownListItem>
          ))}
        </ListGroup>
      </CardBody>
    </PopupCard>
  );
};

export default LocationsPopup;
