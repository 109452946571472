import React from "react";
import { FormikProps } from "formik";
import { TShiftFormValues } from "./types";
import { Row, Col, Label } from "reactstrap";
import RoleField from "./fields/RoleField";
import EmployeeField from "./fields/EmployeeField";
import DateTime from "./fields/DateTime";
import styles from "./index.module.scss";

interface IProps {
  formik: FormikProps<TShiftFormValues>;
}

const ShiftFormFields: React.FC<IProps> = ({ formik }) => {
  return (
    <>
      <Label>When</Label>
      <div className="d-flex align-items-center">
        <DateTime
          date={formik.values.start}
          id="start"
          setFieldValue={formik.setFieldValue}
        />
        <div className={styles.DateTimeTo}>to</div>
        <DateTime
          date={formik.values.end}
          id="end"
          setFieldValue={formik.setFieldValue}
        />
      </div>
      <Row>
        <Col md={8}>
          <EmployeeField
            value={formik.values.employeeId}
            setFieldValue={formik.setFieldValue}
          />
        </Col>
        <Col md={4}>
          <RoleField
            value={formik.values.role}
            setFieldValue={formik.setFieldValue}
          />
        </Col>
      </Row>
    </>
  );
};

export default ShiftFormFields;
