import React, { useRef } from "react";
import { Tooltip } from "reactstrap";
import styles from "./index.module.scss";
import classnames from "classnames";
import useHoverFlag from "src/hooks/useHoverFlag";
import useInitials from "src/hooks/useInitials";

interface IProps {
  name: string;
  imgSrc: string | null | undefined;
  nameOnHover?: boolean;
  size: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
  active?: boolean;
}

const Avatar: React.FC<IProps> = (props) => {
  const { imgSrc, name, active, nameOnHover, size } = props;
  const itemRef = useRef<HTMLDivElement>(null);
  const initials = useInitials(name);
  const tooltipOpen = useHoverFlag(itemRef);
  const classes = classnames(styles.Avatar, styles[size]);
  const inlineStyles = {
    backgroundImage: imgSrc ? `url('${imgSrc}')` : undefined,
  };

  const initialsText = !imgSrc && !!initials ? initials : null;

  return (
    <>
      <div className={classes} style={inlineStyles} ref={itemRef}>
        {initialsText}
        {active && <div className={styles.active} />}
      </div>
      {nameOnHover && itemRef.current && (
        <Tooltip
          placement="bottom"
          target={itemRef.current}
          isOpen={tooltipOpen}
          hideArrow
        >
          {name}
        </Tooltip>
      )}
    </>
  );
};

Avatar.defaultProps = {
  size: "md",
  nameOnHover: false,
  active: false,
};

export default Avatar;
