import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

const DownloadIcon: React.FC<Props> = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g fill="#000" fillRule="nonzero">
      <path d="M22.667 16.26c-.67 0-1.212.521-1.212 1.164v1.78c-.001.833-.704 1.507-1.571 1.508H4.116c-.867 0-1.57-.675-1.571-1.507v-1.781c0-.643-.543-1.164-1.212-1.164-.67 0-1.212.521-1.212 1.164v1.78c0 2.12 1.788 3.838 3.995 3.84h15.768c2.21 0 4-1.718 4-3.84v-1.78c0-.31-.128-.606-.357-.824a1.238 1.238 0 00-.86-.34z" />
      <path d="M12 0c-1.07 0-1.939.833-1.939 1.861v8.704c0 .132-.112.24-.25.24H7.152a.973.973 0 00-.882.545.902.902 0 00.15.997l4.848 5.353c.184.203.451.32.732.32.28 0 .548-.117.732-.32l4.848-5.353a.902.902 0 00.15-.997.973.973 0 00-.882-.545h-2.659a.245.245 0 01-.25-.24V1.86C13.939.833 13.071 0 12 0z" />
    </g>
  </svg>
);

export default DownloadIcon;
