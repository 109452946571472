import {
  IResource,
  IDecoratedResource,
  TComputeEventPosition,
  IEvent,
  IDecoratedEvent,
} from "../types";
import { Moment } from "moment";

export const decorateResources = (
  resources: IResource[]
): IDecoratedResource[] =>
  resources.map((resource) => ({
    ...resource,
    groupCount: 0,
  }));

export const decorateEvents = (
  events: IEvent[],
  computePosition: TComputeEventPosition,
  range: { start: Moment; end: Moment }
): IDecoratedEvent[] =>
  events.map((event) => {
    const clipStart = event.start.isBefore(range.start);
    const clipEnd = event.end.isAfter(range.end);
    const posStart = clipStart ? range.start : event.start;
    const posEnd = clipEnd ? range.end : event.end;
    const { start, end } = computePosition(posStart, posEnd);

    return {
      ...event,
      group: 0,
      clipStart,
      clipEnd,
      posEnd: end,
      posStart: start,
    };
  });
