import React from "react";
import styles from "./index.module.scss";
import { ListGroupItem } from "reactstrap";

interface IProps {
  children: React.ReactNode;
}

const FilterSection: React.FC<IProps> = ({ children }) => {
  return (
    <ListGroupItem className={styles.FilterSection}>{children}</ListGroupItem>
  );
};

export default FilterSection;
