import React, { useState, useRef, useMemo } from "react";
import { Button } from "reactstrap";
import styles from "./index.module.scss";
import classnames from "classnames";
import ArrowToggle from "src/components/common/arrow-toggle/ArrowToggle";

interface IProps {
  buttonText: string;
  children: (props: { open: boolean }) => React.ReactNode;
}

const Drawer: React.FC<IProps> = (props) => {
  const { buttonText, children } = props;
  const ref = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);

  const drawerClasses = classnames(styles.DrawerContainer, {
    [styles.DrawerContainer_Open]: open,
  });

  const childProps = useMemo(
    () => ({
      open,
    }),
    [open]
  );

  return (
    <>
      <div className={drawerClasses} ref={ref}>
        <Button className={styles.DrawerAction} onClick={() => setOpen(!open)}>
          {buttonText} <ArrowToggle width="0.75rem" active={open} flip={true} />
        </Button>
        <div className={styles.DrawerContentContainer}>
          {children(childProps)}
        </div>
      </div>
    </>
  );
};

export default Drawer;
