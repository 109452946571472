import { getTokenCookie } from "src/lib/token";

const url = process.env.REACT_APP_API_URL;

// Intercepts all requests to methods on the grpc client and
// adds sitewide headers to the second argument.
//
// This way, you don't need to pass those headers in for every single request. :)
//

export default class AuthorizedGrpcClient<T> {
  public readonly client: T;

  constructor(client: { new (arg1: any, arg2: any, arg3: any): T }) {
    const c = new client(url, null, null);
    this.client = this.mapMetadata(c);
  }

  private mapMetadata<TClient>(client: TClient): TClient {
    for (const prop in client) {
      if (typeof client[prop] !== "function") {
        continue;
      }

      const original = (client[prop] as unknown) as Function;
      client[prop] = ((...args: any[]) => {
        args[1] = {
          ...args[1],
          authorization: `Bearer ${getTokenCookie()}`,
        };
        return original.call(client, ...args);
      }) as any;
    }
    return client;
  }
}
