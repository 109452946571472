import React, { useMemo } from "react";
import styles from "./index.module.scss";
import classnames from "classnames";

interface IProps extends React.HTMLProps<HTMLDivElement> {
  active: boolean;
}

const CellWrapper = (props: IProps) => {
  const { active, children, ...rest } = props;

  const classes = useMemo(
    () =>
      classnames(styles.CellWrapper, {
        [styles.CellWrapper_Active]: active,
      }),
    [active]
  );

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

export default CellWrapper;
