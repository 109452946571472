import { Thunk, thunk, computed, Computed } from "easy-peasy";
import { getDraft, addDraft, editDraft, deleteDraft } from "src/api";
import {
  TDraftModel,
  TDraftWorkshift,
  TDraftOpenShift,
  TDraftViolations,
  TAutoschedulerEmployeeData,
  TDraftData,
} from "src/api/api.types";
import { DataModel, dataModel } from "src/lib/dataModel";
import { StoreModel } from ".";
import {
  makeDraftData,
  commitViolationToDraftViolations,
  prepareDraftShiftFromAutoscheduler,
  getDraftWorkshifts,
  getDraftOpenShifts,
  getDraftViolations,
  getAcknowledgedViolations,
  acknowledgeViolation,
  unacknowledgeViolation,
} from "src/lib/drafts";
import {
  TDraftStatus,
  TDeleteDraftShiftParams,
  TEditDraftShiftParams,
  TAddDraftShiftParams,
  TEditDraftViolationParams,
} from "src/components/scheduler/scheduler.types";
import { findEmployeeFromAutoschedulerShift } from "src/lib/autoschedule";
import uuid from "uuid/v1";

interface DraftStoreModel {
  draft: DataModel<TDraftModel | null>;
  draftArchived: Computed<DraftStoreModel, boolean>;
  draftWorkshifts: Computed<DraftStoreModel, TDraftWorkshift[]>;
  draftOpenShifts: Computed<DraftStoreModel, TDraftOpenShift[]>;
  draftViolations: Computed<DraftStoreModel, TDraftViolations>;
  acknowledgedViolations: Computed<DraftStoreModel, string[]>;
  getDraft: Thunk<DraftStoreModel, undefined, any, StoreModel>;
  createDraft: Thunk<DraftStoreModel, string, any, StoreModel>;
  deleteDraft: Thunk<DraftStoreModel, number>;
  editDraft: Thunk<DraftStoreModel, string, any, StoreModel>;
  addDraftShift: Thunk<DraftStoreModel, TAddDraftShiftParams, any, StoreModel>;
  editDraftShift: Thunk<DraftStoreModel, TEditDraftShiftParams>;
  editDraftViolation: Thunk<DraftStoreModel, TEditDraftViolationParams>;
  deleteDraftShift: Thunk<DraftStoreModel, TDeleteDraftShiftParams>;
  addDraftOpenShift: Thunk<DraftStoreModel, TDraftOpenShift, any, StoreModel>;
  editDraftOpenShift: Thunk<DraftStoreModel, TDraftOpenShift>;
  acknowledgeViolation: Thunk<DraftStoreModel, string>;
  deleteDraftOpenShift: Thunk<DraftStoreModel, string>;
  migrateDraftData: Thunk<DraftStoreModel, TDraftModel>;
  draftsAreEqual: Computed<DraftStoreModel, (jsonDraft: string) => boolean>;
  draftStatus: Computed<DraftStoreModel, TDraftStatus>;
  commitDraft: Thunk<DraftStoreModel, TDraftData>;
  parseAutoschedulerDataToDraft: Thunk<
    DraftStoreModel,
    TAutoschedulerEmployeeData[],
    any,
    StoreModel
  >;
}

const draftStore: DraftStoreModel = {
  draft: dataModel(null),
  getDraft: thunk(async (actions, _, { getStoreState }) => {
    const {
      scheduler: { currentWeekRange },
      location: { locationId },
    } = getStoreState();
    const { setLoading, setError, setData } = actions.draft;
    setLoading(true);
    setError(null);

    const startDate = currentWeekRange.start.format("YYYY-MM-DD");
    const endDate = currentWeekRange.end.format("YYYY-MM-DD");
    const params = {
      locationId,
      startDate,
      endDate,
    };

    try {
      const res = await getDraft(params);
      const { data } = res.data;
      setData(data);
      actions.migrateDraftData(data);
    } catch (err) {
      setData(null);
      setError(err);
    } finally {
      setLoading(false);
    }
  }),
  // this method migrates existing v1 drafts
  // to the new draft model. v1 did not include draft
  // IDs, so we check if any workshifts or open shifts
  // exist that don't have draft IDs.
  migrateDraftData: thunk(async (actions, draft) => {
    const workshifts = getDraftWorkshifts(draft);
    const openShifts = getDraftOpenShifts(draft);

    const hasUnidentifiedWorkshifts =
      workshifts.length > 0 && !!workshifts.find((s) => !s.id);
    const hasUnidentifiedOpenShifts =
      openShifts.length > 0 && !!openShifts.find((s) => !s.id);

    const shouldMigrate =
      !draft.archived &&
      (hasUnidentifiedWorkshifts || hasUnidentifiedOpenShifts);

    if (shouldMigrate) {
      const migratedWorkshifts = workshifts.map((shift) => ({
        ...shift,
        id: shift.id || uuid(),
      }));
      const migratedOpenShifts = openShifts.map((shift) => ({
        ...shift,
        id: shift.id || uuid(),
      }));

      const shifts = makeDraftData(migratedWorkshifts, migratedOpenShifts);
      actions.editDraft(JSON.stringify(shifts));
    }
  }),
  deleteDraft: thunk(async (actions, draftId) => {
    const { setData } = actions.draft;

    try {
      await deleteDraft(draftId);
      setData(null);
    } catch (err) {
      console.error(err);
    }
  }),
  createDraft: thunk(async (actions, shifts, { getStoreState }) => {
    const { setData } = actions.draft;
    const {
      user: { userId },
      location: { locationId },
      scheduler: {
        currentWeekRange: { start, end },
      },
    } = getStoreState();

    const draft = {
      user_id: userId,
      location_id: locationId,
      start_date: start.format("YYYY-MM-DD"),
      end_date: end.format("YYYY-MM-DD"),
      shifts,
    };

    try {
      const res = await addDraft(draft);
      const { data } = res.data;
      setData(data);
    } catch (err) {
      console.error(err);
    }
  }),
  editDraft: thunk(
    async (actions, shifts, { getState, getStoreState, getStoreActions }) => {
      const { setData } = actions.draft;
      const draft = getState().draft.data;

      const {
        user: { userId },
      } = getStoreState();

      const params = {
        user_id: userId,
        shifts,
      };

      if (!draft) {
        throw new Error("tried to edit an unknown draft");
      }

      setData({
        ...draft,
        shifts,
      });

      try {
        await editDraft(draft.id, params);
      } catch (err) {
        setData({
          ...draft,
        });
        console.error(err);
      }
    }
  ),
  draftWorkshifts: computed([(state) => state.draft.data], getDraftWorkshifts),
  draftOpenShifts: computed([(state) => state.draft.data], getDraftOpenShifts),
  draftViolations: computed([(state) => state.draft.data], getDraftViolations),
  acknowledgedViolations: computed(
    [(state) => state.draft.data],
    getAcknowledgedViolations
  ),
  addDraftShift: thunk(async (actions, payload, { getState }) => {
    const { shift, employeeUid, violation } = payload;
    const {
      draftOpenShifts,
      draftWorkshifts,
      draftViolations,
      acknowledgedViolations,
    } = getState();
    const violations = commitViolationToDraftViolations(
      draftViolations,
      employeeUid,
      violation
    );
    const acknowledged = unacknowledgeViolation(
      acknowledgedViolations,
      employeeUid
    );
    const draftData = makeDraftData(
      [...draftWorkshifts, shift],
      draftOpenShifts,
      violations,
      acknowledged
    );

    actions.commitDraft(draftData);
  }),
  editDraftShift: thunk(async (actions, payload, { getState }) => {
    const { shift, employeeUid, violation } = payload;
    const {
      draftOpenShifts,
      draftWorkshifts,
      draftViolations,
      acknowledgedViolations,
    } = getState();
    const filteredWorkshifts = draftWorkshifts.filter((w) => w.id !== shift.id);
    const filteredOpenShifts = draftOpenShifts.filter((w) => w.id !== shift.id);
    const violations = commitViolationToDraftViolations(
      draftViolations,
      employeeUid,
      violation
    );
    const acknowledged = unacknowledgeViolation(
      acknowledgedViolations,
      employeeUid
    );
    const draftData = makeDraftData(
      [...filteredWorkshifts, shift],
      filteredOpenShifts,
      violations,
      acknowledged
    );

    actions.commitDraft(draftData);
  }),
  editDraftViolation: thunk(async (actions, payload, { getState }) => {
    const { employeeUid, violation } = payload;
    const {
      draftOpenShifts,
      draftWorkshifts,
      draftViolations,
      acknowledgedViolations,
    } = getState();
    const violations = commitViolationToDraftViolations(
      draftViolations,
      employeeUid,
      violation
    );
    const acknowledged = unacknowledgeViolation(
      acknowledgedViolations,
      employeeUid
    );
    const draftData = makeDraftData(
      draftWorkshifts,
      draftOpenShifts,
      violations,
      acknowledged
    );

    actions.commitDraft(draftData);
  }),
  deleteDraftShift: thunk(async (actions, payload, { getState }) => {
    const {
      draftOpenShifts,
      draftWorkshifts,
      draftViolations,
      acknowledgedViolations,
    } = getState();
    const { eventId, violation, employeeUid } = payload;
    const draftShifts = draftWorkshifts.filter((w) => w.id !== eventId);
    const violations = commitViolationToDraftViolations(
      draftViolations,
      employeeUid,
      violation
    );
    const acknowledged = unacknowledgeViolation(
      acknowledgedViolations,
      employeeUid
    );
    const draftData = makeDraftData(
      draftShifts,
      draftOpenShifts,
      violations,
      acknowledged
    );

    actions.commitDraft(draftData);
  }),
  addDraftOpenShift: thunk(
    async (actions, shift, { getState, getStoreState }) => {
      const { draftOpenShifts, draftWorkshifts, draftViolations } = getState();
      const draftData = makeDraftData(
        draftWorkshifts,
        [...draftOpenShifts, shift],
        draftViolations
      );

      actions.commitDraft(draftData);
    }
  ),
  editDraftOpenShift: thunk(async (actions, shift, { getState }) => {
    const { draftOpenShifts, draftWorkshifts, draftViolations } = getState();
    const filteredWorkshifts = draftWorkshifts.filter((w) => w.id !== shift.id);
    const filteredOpenShifts = draftOpenShifts.filter((w) => w.id !== shift.id);
    const draftData = makeDraftData(
      filteredWorkshifts,
      [...filteredOpenShifts, shift],
      draftViolations
    );

    actions.commitDraft(draftData);
  }),
  deleteDraftOpenShift: thunk(async (actions, draftId, { getState }) => {
    const { draftOpenShifts, draftWorkshifts, draftViolations } = getState();
    const draftShifts = draftOpenShifts.filter((w) => w.id !== draftId);
    const draftData = makeDraftData(
      draftWorkshifts,
      draftShifts,
      draftViolations
    );

    actions.commitDraft(draftData);
  }),
  acknowledgeViolation: thunk(async (actions, payload, { getState }) => {
    const {
      draftOpenShifts,
      draftWorkshifts,
      draftViolations,
      acknowledgedViolations,
    } = getState();
    const acknowledged = acknowledgeViolation(acknowledgedViolations, payload);
    const draftData = makeDraftData(
      draftWorkshifts,
      draftOpenShifts,
      draftViolations,
      acknowledged
    );

    actions.commitDraft(draftData);
  }),
  draftStatus: computed(
    [(state) => state.draftArchived, (state) => state.draft.data],
    (archived, draft): TDraftStatus => {
      if (archived) {
        return "published";
      }
      if (!draft && !archived) {
        return "unpublished";
      }
      return "draft";
    }
  ),
  draftArchived: computed([(state) => state.draft.data], (draft) =>
    !draft ? false : draft.archived
  ),
  draftsAreEqual: computed(
    [(state) => state.draft],
    (draft) => (jsonDraft: string) => draft.data?.shifts === jsonDraft
  ),
  parseAutoschedulerDataToDraft: thunk(
    async (actions, data, { getStoreState }) => {
      let workshifts: TDraftWorkshift[] = [];
      const employees = getStoreState().employees.employeeRecordsById;
      data.forEach((item) => {
        const employee = findEmployeeFromAutoschedulerShift(item, employees);
        if (employee) {
          item.shifts.forEach((shift) => {
            workshifts.push(
              prepareDraftShiftFromAutoscheduler(employee, item, shift)
            );
          });
        }
      });

      const draftData = makeDraftData(workshifts, []);
      actions.commitDraft(draftData);
    }
  ),
  commitDraft: thunk(async (actions, payload, { getState }) => {
    const { draft } = getState();
    const jsonDraftData = JSON.stringify(payload);

    if (!draft.data) {
      await actions.createDraft(jsonDraftData);
    } else {
      await actions.editDraft(jsonDraftData);
    }
  }),
};

export default draftStore;
