import React from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { StoreProvider } from 'easy-peasy';
import { store } from './store';
import Router from './Router';

const App = () => {
  return (
    <StoreProvider store={store}>
      <DndProvider backend={HTML5Backend}>
        <Router />
      </DndProvider>
    </StoreProvider>
  );
};

export default App;
