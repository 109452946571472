import { IAvailabilityRecord } from "src/lib/availability";
import { Moment } from "moment";
import { AvailabilityType } from "./HourlyAvailability";

export const getHourlyAvailabilityType = (
  records: IAvailabilityRecord[],
  time: Moment
): AvailabilityType => {
  if (records.length > 0) {
    const hasAllDayUnavailableRecord = records.some(
      (record) => record.allDay && !record.available
    );
    if (hasAllDayUnavailableRecord) {
      return "unavailable";
    }
    let availabilityType: AvailabilityType = "open";
    records.forEach((r) => {
      // The startTime and endTime are formatted like so:
      // `hh:mm:ss`
      //
      // If we remove the colons, we get a string `hhmmss` which
      // we can convert to a number and run comparison checks
      // on against the current cell's time./abc/g,
      const recordStart = +r.startTime.replace(/:/g, "");
      const recordEnd = +r.endTime.replace(/:/g, "");
      const cellTime = +time.format("HHmmss");
      if (cellTime >= recordStart && cellTime < recordEnd) {
        availabilityType = r.available ? "available" : "unavailable";
      }
    });
    return availabilityType;
  }
  return "open";
};
