import React from "react";
import styles from "./index.module.scss";
import { IRow, RenderResource } from "./types";
import useRowHeight from "./hooks/useRowHeight";

interface IProps {
  row: IRow;
  renderResource: RenderResource;
}

const ResourceWrapper: React.FC<IProps> = (props) => {
  const { row, renderResource } = props;

  const rowHeight = useRowHeight(row);

  return (
    <div className={styles.ResourceWrapper}>
      <div style={{ height: `${rowHeight}px` }}>{renderResource(row)}</div>
    </div>
  );
};

export default ResourceWrapper;
