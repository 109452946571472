type Size = {
  width: number;
  height: number;
};

type PropSize = {
  width?: number;
  height?: number;
};

export const calculateSvgSize = (
  viewBoxSize: Size,
  propSize: PropSize
): Size => {
  // if either height or width are defined
  if (propSize.height || propSize.width) {
    if (propSize.width) {
      // To calculate height, find xx:
      // vW   pW
      // -- = --
      // vH   xx
      return {
        width: propSize.width,
        height: (propSize.width * viewBoxSize.height) / viewBoxSize.width,
      };
    }
    if (propSize.height) {
      // To calculate width, find xx:
      // vW   xx
      // -- = --
      // vH   pH
      return {
        width: (propSize.height * viewBoxSize.width) / viewBoxSize.height,
        height: propSize.height,
      };
    }
  }
  return viewBoxSize;
};
