import React, { forwardRef } from "react";
import classnames from "classnames";
import styles from "./index.module.scss";

interface IProps extends React.HTMLProps<HTMLDivElement> {
  enabled: boolean;
}

const ToggleSwitch = forwardRef<HTMLDivElement, IProps>(
  ({ enabled, className, ...rest }: IProps, ref) => {
    const classes = classnames(className, styles.SwitchToggle, {
      [styles.SwitchToggle_On]: enabled,
    });

    return <div className={classes} ref={ref} {...rest} />;
  }
);

export default ToggleSwitch;
