import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import { Button, Card, CardBody } from "reactstrap";

class CalendarErrorBoundary extends Component<{}, { eventId?: string }> {
  state = {
    eventId: undefined,
  };

  componentDidCatch(error: Error | null, errorInfo: Object) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (!!this.state.eventId) {
      return (
        <Card className="shadow m-4 text-center">
          <CardBody>
            <h2>Something went wrong</h2>
            <p>If this continues to happen, please report it to us.</p>
            <Button
              color="primary"
              onClick={() =>
                Sentry.showReportDialog({ eventId: this.state.eventId })
              }
            >
              Report feedback
            </Button>
          </CardBody>
        </Card>
      );
    }

    //when there's not an error, render children untouched
    return this.props.children;
  }
}

export default CalendarErrorBoundary;
