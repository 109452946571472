import { Thunk, thunk, Computed, computed } from "easy-peasy";
import { TAvailabilityItem } from "src/api/api.types";
import createCancelToken from "src/api/cancelToken";
import axios from "axios";
import {
  createAvailabilityRecord,
  IAvailabilityRecord,
} from "src/lib/availability";
import { DataModel, dataModel } from "src/lib/dataModel";
import { getAvailabilityData } from "src/api";
import { StoreModel } from ".";

const availabilityCancelToken = createCancelToken("availability");

interface IUserAvailability {
  [userId: number]: IAvailabilityRecord[];
}

interface AvailabilityModel {
  availability: DataModel<TAvailabilityItem[]>;
  fetchAvailability: Thunk<AvailabilityModel, undefined, any, StoreModel>;
  userAvailability: Computed<AvailabilityModel, IUserAvailability>;
}

const availabilityStore: AvailabilityModel = {
  ///////////////////////
  // AVAILABILITY
  ///////////////////////

  availability: dataModel([]),

  fetchAvailability: thunk(async (actions, _, { getStoreState }) => {
    const {
      location: { locationId },
      scheduler: { start, end },
    } = getStoreState();
    const { setLoading, setError, setData } = actions.availability;
    const call = availabilityCancelToken.run();

    setLoading(true);
    setError(null);

    const params = {
      locationId,
      startDate: start.clone().subtract(1, "day").format("YYYY-MM-DD"),
      endDate: end.clone().add(1, "day").format("YYYY-MM-DD"),
    };

    try {
      const res = await getAvailabilityData(params, {
        cancelToken: call.token,
      });

      setData(res.data.response);
      setLoading(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        setError(err);
        setLoading(false);
      }
    }
  }),

  userAvailability: computed(
    [(state) => state.availability.data],
    (availability) => {
      const result: IUserAvailability = {};
      availability.forEach((item) => {
        const id = item.UserId;
        const availability = createAvailabilityRecord(item);

        if (result[id]) {
          result[id].push(availability);
        } else {
          result[id] = [availability];
        }
      });
      return result;
    }
  ),
};

export default availabilityStore;
