import { Thunk, thunk } from "easy-peasy";
import { DataModel, dataModel } from "src/lib/dataModel";
import { getForecastData, getNodeAutoSchedulerDemand } from "src/api";
import {
  TAutoschedulerDemand,
  TGetForecastDataRequest,
  TForecastData,
} from "src/api/api.types";
import { StoreModel } from ".";

interface DemandModel {
  schedulingDemand: DataModel<TAutoschedulerDemand | null>;
  fetchSchedulingDemand: Thunk<DemandModel, undefined, any, StoreModel>;
  forecastData: DataModel<TForecastData[]>;
  fetchForecastData: Thunk<DemandModel, TGetForecastDataRequest>;
}

const demandStore: DemandModel = {
  ///////////////////////
  // DEMAND
  ///////////////////////

  schedulingDemand: dataModel(null),

  fetchSchedulingDemand: thunk(async (actions, _, { getStoreState }) => {
    const {
      scheduler: { view, currentWeekRange },
      location: { locationId },
    } = getStoreState();
    const { setLoading, setError, setData } = actions.schedulingDemand;

    if (view === "week" || view === "day") {
      setLoading(true);
      setError(null);

      try {
        const res = await getNodeAutoSchedulerDemand({
          locationId,
          start_date: currentWeekRange.start.format("YYYY-MM-DD"),
          end_date: currentWeekRange.end.format("YYYY-MM-DD"),
        });
        setData(res.data.response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    }
  }),

  forecastData: dataModel([]),

  fetchForecastData: thunk(async (actions, params) => {
    const { setLoading, setError, setData } = actions.forecastData;

    setLoading(true);
    setError(null);

    try {
      const res = await getForecastData(params);
      if (res.data.response.length > 0) {
        const data = JSON.parse(res.data.response[0].ForecastData);
        setData(data);
      }
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  }),
};

export default demandStore;
