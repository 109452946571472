import { createStore, createTypedHooks } from "easy-peasy";
import shifts from "./shifts.store";
import location from "./location.store";
import employees from "./employees.store";
import draft from "./draft.store";
import availability from "./availability.store";
import roles from "./roles.store";
import nearby from "./nearby.store";
import userLocations from "./userLocations.store";
import user from "./user.store";
import templates from "./templates.store";
import scheduler from "./scheduler.store";
import timeoffs from "./timeoffs.store";
import events from "./events.store";
import resources from "./resources.store";
import filters from "./filters.store";
import demand from "./demand.store";
import sort from "./sort.store";

const storeModel = {
  shifts,
  employees,
  draft,
  location,
  availability,
  roles,
  nearby,
  userLocations,
  user,
  templates,
  scheduler,
  timeoffs,
  events,
  resources,
  filters,
  demand,
  sort,
};

export type StoreModel = typeof storeModel;

const store = createStore(storeModel);

const {
  useStoreActions,
  useStoreState,
  useStoreDispatch,
  useStore,
} = createTypedHooks<StoreModel>();

export { useStoreActions, useStoreState, useStoreDispatch, useStore, store };
