import React from "react";
import styles from "./index.module.scss";

interface IProps {
  children: React.ReactNode;
  isOpen: boolean;
}

const FilterSectionContent: React.FC<IProps> = ({ children, isOpen }) => {
  return (
    <div className={styles.FilterSectionContent}>{isOpen && children}</div>
  );
};

export default FilterSectionContent;
