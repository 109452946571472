import { useMemo } from "react";
import { useStoreState } from "src/store";

const useSchedulerLayout = () => {
  const asideWidth = useStoreState(
    (state) => state.scheduler.layout.asideWidth
  );
  const headHeight = useStoreState(
    (state) => state.scheduler.layout.headHeight
  );
  const bodyTop = useStoreState((state) => state.scheduler.tableBodyTop);
  const mainWidth = useStoreState((state) => state.scheduler.tableMainWidth);

  const styles = useMemo(() => {
    const asideWidthPx = `${asideWidth}px`;
    const mainWidthPx = `${mainWidth}px`;
    const headHeightPx = `${headHeight}px`;
    const bodyHeightPx = `${bodyTop}px`;

    return {
      container: {
        paddingLeft: asideWidthPx,
      },
      containerBody: {
        height: `calc(100vh - ${bodyHeightPx})`,
        paddingLeft: asideWidthPx,
      },
      aside: {
        width: asideWidthPx,
      },
      asideHead: {
        height: headHeightPx,
      },
      headRow: {
        height: headHeightPx,
        width: mainWidthPx,
        minWidth: `calc(100% - ${asideWidthPx}`,
      },
      rows: {
        width: mainWidthPx,
        minWidth: `calc(100% - ${asideWidthPx}`,
      },
    };
  }, [asideWidth, mainWidth, headHeight, bodyTop]);

  return styles;
};

export default useSchedulerLayout;
