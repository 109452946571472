import React from "react";
import ControlledPopupHandler from "src/components/common/popup/ControlledPopupHandler";
import FilterIcon from "src/components/common/icons/FilterIcon";
import FiltersPopup from "./FiltersPopup";
import IconButton from "src/components/common/icon-button/IconButton";
import { useStoreState } from "src/store";

const Filters: React.FC = () => {
  const hasFilters = useStoreState((state) => state.filters.hasFilters);

  return (
    <ControlledPopupHandler
      placement="bottom-end"
      renderPopup={({ hide }) => <FiltersPopup handleClose={hide} />}
    >
      {({ show }) => (
        <IconButton
          tooltip="Filter"
          id="FilterAction"
          onClick={show}
          hasAlert={hasFilters}
        >
          <FilterIcon width="1rem" />
        </IconButton>
      )}
    </ControlledPopupHandler>
  );
};

export default Filters;
