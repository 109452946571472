import {
  Thunk,
  Action,
  thunk,
  action,
  Computed,
  computed,
  ThunkOn,
  thunkOn,
} from 'easy-peasy';
import { getLocationDetails } from 'src/api';
import { getLocationSettings } from 'src/api/grpc';
import { TLocation, TLocationDetails } from 'src/api/api.types';
import { StoreModel } from './';
import { setLocationCookie } from 'src/lib/cookies';
import { DataModel, dataModel } from 'src/lib/dataModel';

interface LocationModel {
  location: TLocation | null;
  setLocation: Action<LocationModel, TLocation>;
  isManager: Computed<LocationModel, boolean>;
  isDominos: Computed<LocationModel, boolean>;

  details: DataModel<TLocationDetails | null>;
  fetchDetails: Thunk<LocationModel, string>;

  settings: DataModel<[string, string][]>;
  fetchSettings: Thunk<LocationModel, string>;

  onLocationChange: ThunkOn<LocationModel, null, StoreModel>;

  locationId: Computed<LocationModel, string>;
  settingsMap: Computed<LocationModel, { [key: string]: string }>;
}

const locationStore: LocationModel = {
  ///////////////////////
  // LOCATION
  ///////////////////////

  location: null,

  setLocation: action((state, payload) => {
    setLocationCookie(payload);
    state.location = payload;
  }),

  isManager: computed(({ location }) =>
    location ? location.position_type === 'manager' : false
  ),

  isDominos: computed(({ location }) =>
    location ? location.location_name.toLowerCase().includes('domino') : false
  ),

  ///////////////////////
  // CURRENT LOCATION DETAILS
  ///////////////////////

  details: dataModel(null),

  fetchDetails: thunk(async (actions, locationId) => {
    const { setLoading, setError, setData } = actions.details;
    setLoading(true);
    setError(null);
    try {
      const res = await getLocationDetails(locationId);
      setData(res.data.response.info);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(true);
    }
  }),

  ///////////////////////
  // CURRENT LOCATION SETTINGS
  ///////////////////////

  settings: dataModel([]),

  fetchSettings: thunk(async (actions, locationId) => {
    const { setLoading, setError, setData } = actions.settings;
    setLoading(true);
    setError(null);
    try {
      const res = await getLocationSettings(locationId);
      setData(res.getSettingsMap().toArray());
    } catch (err) {
      setError(err);
    } finally {
      setLoading(true);
    }
  }),

  onLocationChange: thunkOn(
    (actions) => actions.setLocation,
    (actions, { payload }) => {
      actions.fetchDetails(payload.location_id);
      actions.fetchSettings(payload.location_id);
    }
  ),

  locationId: computed(
    [(store) => store.location?.location_id],
    (locationId) => locationId || ''
  ),

  settingsMap: computed([(store) => store.settings.data], (settings) => {
    let mapped: { [key: string]: string } = {};
    settings.forEach((setting) => {
      mapped[setting[0]] = setting[1];
    });
    return mapped;
  }),
};

export default locationStore;
