import { TLocation } from 'src/api/api.types';
import Cookies from 'js-cookie';

const LOCATION_COOKIE_KEY = 'current_location';
const BETA_CALENDAR_COOKIE_KEY = 'beta_calendar';

const cookieOptions = {
  domain: process.env.REACT_APP_COOKIE_DOMAIN,
};

export const setLocationCookie = (loc: TLocation): TLocation => {
  // replace Bearer if it exists in the string
  Cookies.set(LOCATION_COOKIE_KEY, JSON.stringify(loc), cookieOptions);
  return loc;
};

export const getLocationCookie = (): TLocation | null => {
  const loc = Cookies.get(LOCATION_COOKIE_KEY);
  return loc ? JSON.parse(loc) : null;
};

export const removeLocationCookie = (): void => {
  return Cookies.remove(LOCATION_COOKIE_KEY, cookieOptions);
};

export const removeBetaCookie = (): void => {
  return Cookies.remove(BETA_CALENDAR_COOKIE_KEY, cookieOptions);
};
