import { Computed, computed } from 'easy-peasy';
import { StoreModel } from './index';
import { eventIsBetweenMoments } from 'src/lib/rangeHandler';
import { untimezonedMoment } from 'src/lib/timezone';
import { splitTimeoffEvents } from 'src/lib/timeoffs';
import {
  TStateEvents,
  TStateOpenShift,
  TStateShift,
  TStateTimeoff,
  TEmployeeRecord,
  TScheduledEmployees,
  TStateDraftShift,
  TStateDraftOpenShift,
} from 'src/components/scheduler/scheduler.types';

interface EventsModel {
  openShiftEvents: Computed<EventsModel, TStateOpenShift[], StoreModel>;
  timeoffEvents: Computed<EventsModel, TStateTimeoff[], StoreModel>;
  workshiftEvents: Computed<EventsModel, TStateShift[], StoreModel>;
  draftWorkshiftEvents: Computed<EventsModel, TStateDraftShift[], StoreModel>;
  draftOpenShiftEvents: Computed<
    EventsModel,
    TStateDraftOpenShift[],
    StoreModel
  >;
  events: Computed<EventsModel, TStateEvents, StoreModel>;
  scheduledEmployees: Computed<EventsModel, TScheduledEmployees>;
}

const eventsStore: EventsModel = {
  openShiftEvents: computed(
    [(_, store) => store.shifts.openShifts.data],
    (openShifts) =>
      openShifts.map((shift) => ({
        type: 'open_shift',
        id: `${shift.id}`,
        start: untimezonedMoment(shift.start_time),
        end: untimezonedMoment(shift.end_time),
        isNearby: false,
        resourceId: 'open',
        data: shift,
      }))
  ),

  draftOpenShiftEvents: computed(
    [(_, store) => store.draft.draftOpenShifts],
    (openShifts) =>
      openShifts.map((shift) => ({
        type: 'draft_open_shift',
        id: shift.id,
        start: untimezonedMoment(shift.start_time),
        end: untimezonedMoment(shift.end_time),
        isNearby: false,
        resourceId: 'open',
        data: shift,
      }))
  ),

  timeoffEvents: computed(
    [(_, store) => store.timeoffs.visibleTimeoffs],
    (visibleTimeoffs) => splitTimeoffEvents(visibleTimeoffs)
  ),

  workshiftEvents: computed(
    [
      (_, store) => store.employees.employeeRecords,
      (_, store) => store.shifts.workshifts.data,
    ],
    (employeeRecords, shifts) =>
      shifts.map((shift) => {
        const resource = employeeRecords.find(
          (emp) =>
            `${shift.user.id}` === emp.userId || shift.user.name === emp.name
        );
        const isNearbyEmployee = !!shift.user.location;

        // if there is still no resource,
        // just fall back to referring to the resource
        // id as the shift employee's name
        let resourceId: string;
        if (resource) {
          resourceId = resource.uid;
        } else {
          resourceId = shift.user.name;
        }

        return {
          type: 'shift',
          id: `${shift.id}`,
          start: untimezonedMoment(shift.start_time),
          end: untimezonedMoment(shift.end_time),
          resourceId: resourceId,
          isNearby: isNearbyEmployee,
          data: shift,
        };
      })
  ),

  draftWorkshiftEvents: computed(
    [
      (_, store) => store.employees.employeeRecords,
      (_, store) => store.employees.employeeRecordsById,
      (_, store) => store.draft.draftWorkshifts,
    ],
    (employeeRecords, employeeRecordsById, shifts) =>
      shifts.map((shift) => {
        let id = `${shift.user_id}`;
        let resource: TEmployeeRecord | undefined = employeeRecordsById[id];

        if (!resource) {
          resource = employeeRecords.find(
            (emp) => shift.user_name === emp.name
          );
        }

        const resourceId = !resource ? 'none' : resource.uid;

        return {
          type: 'draft_shift',
          id: shift.id,
          start: untimezonedMoment(shift.start_time),
          end: untimezonedMoment(shift.end_time),
          resourceId: resourceId,
          isNearby: false,
          data: shift,
        };
      })
  ),

  scheduledEmployees: computed([(state) => state.events], (events) => {
    const scheduled: TScheduledEmployees = {};
    events.forEach((event) => {
      scheduled[event.resourceId] = true;
    });
    return scheduled;
  }),

  events: computed(
    [
      (_, store) => store.scheduler.start,
      (_, store) => store.scheduler.end,
      (_, store) => store.scheduler.view,
      (state) => state.workshiftEvents,
      (state) => state.openShiftEvents,
      (state) => state.timeoffEvents,
      (state) => state.draftWorkshiftEvents,
      (state) => state.draftOpenShiftEvents,
    ],
    (
      start,
      end,
      view,
      workshiftEvents,
      openShiftEvents,
      timeoffEvents,
      draftWorkshiftEvents,
      draftOpenShiftEvents
    ) => {
      const allEvents = [
        ...workshiftEvents,
        ...timeoffEvents,
        ...openShiftEvents,
        ...draftWorkshiftEvents,
        ...draftOpenShiftEvents,
      ];

      const all = allEvents.filter(
        (event) =>
          event.start.isBefore(event.end) &&
          eventIsBetweenMoments(
            {
              start: event.start,
              end: event.end,
            },
            { start, end },
            view === 'week'
          )
      );
      return all;
    }
  ),
};

export default eventsStore;
