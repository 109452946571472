import React, { useState, useCallback } from "react";
import { FormikHelpers } from "formik";
import { TShiftFormValues } from "./types";
import { validateAndEditWorkshift, prepareCustomWorkshiftRequest } from "./lib";
import { errorIsViolation } from "src/lib/violations";
import { AxiosError } from "axios";
import { TShiftViolationErrorData } from "src/api/api.types";
import { useStoreState, useStoreActions } from "src/store";
import {
  evaluateDraftWorkshift,
  editCustomWorkshift,
  getWorkshift,
} from "src/api";
import ShiftForm from "./ShiftForm";
import { Moment } from "moment";

interface IProps {
  role?: string;
  employeeId?: string;
  start: Moment;
  end: Moment;
  eventId: string;
  handleClose: () => void;
}

const EditShiftForm: React.FC<IProps> = (props) => {
  const { role, employeeId, start, end, eventId, handleClose } = props;
  const [showViolations, setShowViolations] = useState(false);
  const [violationMessage, setViolationMessage] = useState("");
  const userId = useStoreState((state) => state.user.userId);
  const locationId = useStoreState((state) => state.location.locationId);
  const events = useStoreState((state) => state.events.events);
  const employeeRecordsById = useStoreState(
    (state) => state.employees.employeeRecordsById
  );
  const view = useStoreState((state) => state.scheduler.view);
  const updateWorkshift = useStoreActions(
    (actions) => actions.shifts.updateWorkshift
  );

  const submit = useCallback(
    async (
      values: TShiftFormValues,
      helpers: FormikHelpers<TShiftFormValues>
    ) => {
      helpers.setSubmitting(true);
      const startTime = values.start.format("YYYY-MM-DD HH:mm:ss");
      const endTime = values.end.format("YYYY-MM-DD HH:mm:ss");
      const employee = employeeRecordsById[values.employeeId];
      try {
        // if we are currently showing violations,
        // it means that the submit button should
        // bypass validation
        const runValidation = !showViolations;

        if (employee.hasBranchAccount) {
          const res = await validateAndEditWorkshift(
            {
              id: eventId,
              owner: +values.employeeId,
              role: values.role,
              start: values.start,
              end: values.end,
            },
            runValidation
          );
          updateWorkshift(res.data.data);
        } else {
          if (runValidation) {
            const shift = {
              start_time: startTime,
              end_time: endTime,
            };
            const userShifts = events
              .filter((e) => e.type === "draft_shift" || e.type === "shift")
              .filter((shift) => shift.resourceId === employeeId)
              .filter((shift) => shift.id !== eventId)
              .map((shift) => ({
                start_time: shift.start.format("YYYY-MM-DD HH:mm:ss"),
                end_time: shift.end.format("YYYY-MM-DD HH:mm:ss"),
              }));
            await evaluateDraftWorkshift({
              user_id: -1,
              employee_id: employeeId as string,
              location_id: locationId,
              current_shift: shift,
              time_interval: view,
              shifts: [...userShifts, shift],
            });
          }
          const request = prepareCustomWorkshiftRequest(
            userId,
            locationId,
            employee,
            values
          );
          await editCustomWorkshift(eventId, request);
          const customShiftRes = await getWorkshift(eventId);
          updateWorkshift(customShiftRes.data.data);
        }

        handleClose();
      } catch (err) {
        if (errorIsViolation(err)) {
          setShowViolations(true);
          const error = err as AxiosError<TShiftViolationErrorData>;
          const detail = error.response?.data.meta.detail || "";
          setViolationMessage(detail);
        }
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [
      eventId,
      employeeId,
      showViolations,
      employeeRecordsById,
      locationId,
      userId,
      view,
      events,
      handleClose,
      updateWorkshift,
    ]
  );

  return (
    <ShiftForm
      role={role}
      employeeId={employeeId}
      start={start}
      end={end}
      handleSubmit={submit}
      violationMessage={violationMessage}
      setViolationMessage={setViolationMessage}
      setShowViolations={setShowViolations}
      showViolations={showViolations}
    />
  );
};

export default EditShiftForm;
