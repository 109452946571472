import React from "react";
import { PopupCard } from "src/components/common/popup";
import RoleFilter from "./RoleFilter";
import EmployeeFilter from "./EmployeeFilter";
import styles from "./index.module.scss";
import { ListGroup, CardHeader, CardBody } from "reactstrap";
import PopupHeadIcons from "src/components/common/popup/PopupHeadIcons";
import IconButton from "src/components/common/icon-button/IconButton";
import CloseIcon from "src/components/common/icons/CloseIcon";
import BinIcon from "src/components/common/icons/BinIcon";
import ToggleSwitch from "src/components/common/toggleswitch";
import { useStoreActions, useStoreState } from "src/store";

interface IProps {
  handleClose: () => void;
}

const popupStyles = {
  width: "350px",
};

const FiltersPopup: React.FC<IProps> = (props) => {
  const { handleClose } = props;
  const clearFilters = useStoreActions((state) => state.filters.clearFilters);
  const toggleShowOnlyScheduled = useStoreActions(
    (state) => state.filters.toggleShowOnlyScheduled
  );
  const showOnlyScheduled = useStoreState(
    (state) => state.filters.showOnlyScheduled
  );
  return (
    <PopupCard style={popupStyles} className="pb-0">
      <CardHeader>
        <h5>Filters</h5>
        <PopupHeadIcons>
          <IconButton
            tooltip="Clear filters"
            onClick={clearFilters}
            id="FiltersPopupClear"
          >
            <BinIcon width={16} />
          </IconButton>
          <IconButton
            tooltip="Close"
            onClick={handleClose}
            id="FiltersPopupClose"
          >
            <CloseIcon width={16} />
          </IconButton>
        </PopupHeadIcons>
      </CardHeader>
      <CardBody className="py-0">
        <div
          className={`${styles.FilterSectionHead} ${styles.FilterSectionHead_Scheduled}`}
          onClick={() => toggleShowOnlyScheduled()}
        >
          <span className="font-weight-bold">Only show scheduled</span>
          <ToggleSwitch enabled={showOnlyScheduled} onClick={() => {}} />
        </div>

        <div className={styles.FilterList}>
          <ListGroup flush>
            <RoleFilter />
            <EmployeeFilter />
          </ListGroup>
        </div>
      </CardBody>
    </PopupCard>
  );
};

export default FiltersPopup;
