import React, { useCallback, useState } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { useStoreState, useStoreActions } from "src/store";
import FilterSection from "./FilterSection";
import FilterSectionHead from "./FilterSectionHead";
import FilterSectionContent from "./FilterSectionContent";

const RoleFilter: React.FC = () => {
  const roles = useStoreState((state) => state.templates.templateRoles);
  const filters = useStoreState((state) => state.filters.roleFilters);
  const count = useStoreState((state) => state.filters.roleFiltersCount);
  const addFilter = useStoreActions((actions) => actions.filters.addRoleFilter);
  const removeFilter = useStoreActions(
    (actions) => actions.filters.removeRoleFilter
  );

  const [open, setOpen] = useState(count > 0);
  const toggleAccordion = useCallback(() => setOpen((open) => !open), [
    setOpen,
  ]);
  const handleItemClick = useCallback(
    (role: string) => {
      // remove if it exists, otherwise add
      return !!filters[role] ? removeFilter(role) : addFilter(role);
    },
    [filters, addFilter, removeFilter]
  );

  return (
    <FilterSection>
      <FilterSectionHead
        title="Roles"
        isOpen={open}
        handleClick={toggleAccordion}
        count={count}
      />
      <FilterSectionContent isOpen={open}>
        {roles.map((role) => (
          <FormGroup key={role} check>
            <Label check>
              <Input
                type="checkbox"
                checked={!!filters[role]}
                onChange={() => handleItemClick(role)}
              />{" "}
              {role}
            </Label>
          </FormGroup>
        ))}
      </FilterSectionContent>
    </FilterSection>
  );
};

export default RoleFilter;
