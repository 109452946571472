import React, { useCallback } from "react";
import { Form, Button } from "reactstrap";
import { Formik, FormikProps, FormikHelpers } from "formik";
import { Moment } from "moment";
import StatefulButton from "src/components/common/stateful-button";
import { TShiftFormValues } from "./types";
import ShiftFormFields from "./ShiftFormFields";
import ViolationMessage from "../violation/ViolationMessage";
import * as yup from "yup";

interface IProps {
  role?: string;
  employeeId?: string;
  start: Moment;
  end: Moment;
  handleSubmit: (
    values: TShiftFormValues,
    helpers: FormikHelpers<TShiftFormValues>
  ) => Promise<void>;
  setShowViolations: React.Dispatch<React.SetStateAction<boolean>>;
  setViolationMessage: React.Dispatch<React.SetStateAction<string>>;
  showViolations: boolean;
  violationMessage: string;
}

const schema = yup.object().shape({
  employeeId: yup.string().required().min(1),
});

const ShiftForm: React.FC<IProps> = (props) => {
  const {
    role = "",
    employeeId = "",
    start,
    end,
    handleSubmit,
    setShowViolations,
    showViolations,
    violationMessage,
    setViolationMessage,
  } = props;

  const handleBack = useCallback(() => {
    setShowViolations(false);
    setViolationMessage("");
  }, [setShowViolations, setViolationMessage]);

  return (
    <Formik
      initialValues={{
        role,
        employeeId,
        start,
        end,
      }}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {(formik: FormikProps<TShiftFormValues>) => (
        <Form onSubmit={formik.handleSubmit}>
          {showViolations ? (
            <ViolationMessage
              title="Encountered violations"
              details={violationMessage}
              description="The following violations occurred for this user when trying to modify this shift:"
              subtext="You can either proceed to ignore this warning or go back and edit the form."
            />
          ) : (
            <ShiftFormFields formik={formik} />
          )}

          <div className="d-flex align-items-center justify-content-between mt-4">
            {showViolations && (
              <Button color="secondary" outline onClick={handleBack}>
                Go Back
              </Button>
            )}

            <StatefulButton
              color="primary"
              type="submit"
              className="ml-auto"
              disabled={!formik.isValid}
              loading={formik.isSubmitting}
            >
              {showViolations ? "Proceed" : "Save"}
            </StatefulButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ShiftForm;
