import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

const ChevronDownIcon = (props: Props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        d="M10.712 6.712a.998.998 0 10-1.416 1.405L13.17 12l-3.874 3.883a.997.997 0 00-.001 1.412c.39.39 1.028.382 1.417-.007l4.576-4.576a1.01 1.01 0 000-1.424l-4.576-4.576z"
        id="chevron_down_svg__a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use
        fill="#212121"
        transform="rotate(90 12.295 12)"
        xlinkHref="#chevron_down_svg__a"
      />
    </g>
  </svg>
);

export default ChevronDownIcon;
