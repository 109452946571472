import { useEffect } from 'react';
import { getLocationCookie } from 'src/lib/cookies';
import { useStoreActions, useStoreState } from 'src/store';

const useInitUserLocations = () => {
  const fetchUserLocations = useStoreActions(
    (actions) => actions.userLocations.fetchUserLocations
  );

  const setLocation = useStoreActions(
    (actions) => actions.location.setLocation
  );
  const userLocations = useStoreState(
    (state) => state.userLocations.locations.data
  );

  useEffect(() => {
    fetchUserLocations();
  }, [setLocation, fetchUserLocations]);

  useEffect(() => {
    const cachedLoc = getLocationCookie();
    const loc = userLocations[0];
    if (cachedLoc || loc) {
      setLocation(cachedLoc || loc);
    }
  }, [userLocations, setLocation]);
};

export default useInitUserLocations;
