import React, { useMemo } from "react";
import { useStoreState } from "src/store";
import styles from "./index.module.scss";
import { getOffsetWeekArray } from "src/lib/dates";
import TotalColumn from "./TotalColumn";
import WeekdayColumn from "./WeekdayColumn";
import {
  getScheduledMetrics,
  getForecastedMetrics,
  getVarianceMetrics,
} from "src/lib/scheduleMetrics";

const WeeklyForecast: React.FC = () => {
  const schedulingDemand = useStoreState(
    (state) => state.demand.schedulingDemand.data
  );

  const events = useStoreState((state) => state.events.events);
  const roles = useStoreState((state) => state.templates.templateRoles);
  const offset = useStoreState((state) => state.scheduler.weekStartOffset);

  const forecastMetrics = useMemo(
    () => getForecastedMetrics(roles, schedulingDemand),
    [roles, schedulingDemand]
  );

  const scheduledEventMetrics = useMemo(
    () => getScheduledMetrics(roles, events),
    [roles, events]
  );

  const variance = useMemo(
    () => getVarianceMetrics(scheduledEventMetrics, forecastMetrics),
    [scheduledEventMetrics, forecastMetrics]
  );

  const offsetWeekdays = useMemo(() => getOffsetWeekArray(offset), [offset]);

  return (
    <div className={styles.WeeklyForecastTable}>
      <div className={styles.WeeklyForecastTable_Aside}>
        <div className={styles.WeeklyForecastTable_AsideCol}>
          <TotalColumn
            forecast={forecastMetrics.totals}
            scheduled={scheduledEventMetrics.totals}
            variance={variance.totals}
          />
        </div>
      </div>

      <div className={styles.WeeklyForecastTable_Main}>
        {offsetWeekdays.map((weekday) => (
          <div key={weekday} className={styles.WeeklyForecastTable_Col}>
            <WeekdayColumn
              forecast={forecastMetrics.daily[weekday]}
              scheduled={scheduledEventMetrics.daily[weekday]}
              variance={variance.daily[weekday]}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default WeeklyForecast;
