import React from "react";
import PopupHeadIcons from "src/components/common/popup/PopupHeadIcons";
import IconButton from "src/components/common/icon-button/IconButton";
import CloseIcon from "src/components/common/icons/CloseIcon";
import { CardHeader, CardBody } from "reactstrap";
import { PopupCard } from "src/components/common/popup";
import AddDraftOpenShiftForm from "./AddDraftOpenShiftForm";
import { Moment } from "moment";

interface IProps {
  time: Moment;
  role?: string | null;
  close: () => void;
}

// optimization so that this component only re-renders if
// props have changed
const areEqual = (prev: IProps, next: IProps) =>
  prev.time === next.time &&
  prev.role === next.role &&
  prev.close === next.close;

const styles = {
  popup: {
    width: "500px",
  },
};

const AddDraftOpenShiftPopup: React.FC<IProps> = React.memo((props) => {
  const { time, role, close } = props;

  return (
    <PopupCard style={styles.popup}>
      <CardHeader>
        <h5>Add open draft shift</h5>
        <PopupHeadIcons>
          <IconButton tooltip="Close" onClick={close} id="AddShiftPopupClose">
            <CloseIcon width={16} />
          </IconButton>
        </PopupHeadIcons>
      </CardHeader>
      <CardBody>
        <AddDraftOpenShiftForm
          start={time}
          end={time.clone().add(8, "hours")}
          role={role || ""}
          handleClose={close}
        />
      </CardBody>
    </PopupCard>
  );
}, areEqual);

export default AddDraftOpenShiftPopup;
