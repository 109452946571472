import React from "react";
import { ListGroup, CardBody } from "reactstrap";
import { PopupCard } from "src/components/common/popup";
import DropdownListItem from "src/components/common/dropdown-list/DropdownListItem";
import useDownloadSchedulerCSV from "./useDownloadSchedulerCSV";

const styles = {
  popup: {
    width: "250px",
    maxHeight: "250px",
  },
};

const GeneralMenuPopup: React.FC = () => {
  const downloadCSV = useDownloadSchedulerCSV();

  return (
    <PopupCard style={styles.popup}>
      <CardBody className="px-0 py-2">
        <ListGroup flush>
          <DropdownListItem onClick={downloadCSV}>
            Download CSV
          </DropdownListItem>
        </ListGroup>
      </CardBody>
    </PopupCard>
  );
};

export default GeneralMenuPopup;
