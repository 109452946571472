import React from "react";
import classnames from "classnames";
import { calculateSvgSize } from "src/lib/svgSize";

interface IProps {
  fill: "primary" | "white";
  className?: string;
  width?: number;
  height?: number;
}

const fills = {
  primary: {
    logo: "#4CB258",
    type: "#515251",
  },
  white: {
    logo: "#FFFFFF",
    type: "#FFFFFF",
  },
};

const ComboMark = ({ fill, className, width, height }: IProps) => {
  const classes = classnames("combo-mark", className);
  const viewBoxSize = {
    width: 253,
    height: 74,
  };
  const size = calculateSvgSize(viewBoxSize, { width, height });

  return (
    <svg
      width={`${size.width}px`}
      height={`${size.height}px`}
      viewBox={`0 0 ${viewBoxSize.width} ${viewBoxSize.height}`}
      className={classes}
    >
      <title>Branch</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M239.217,28.093 C235.085,28.093 231.638,29.582 229.094,32.433 L229.094,17.762 C229.094,16.345 227.985,15.235 226.571,15.235 C225.19,15.235 224.108,16.345 224.108,17.762 L224.108,59.488 C224.108,60.929 225.193,62.015 226.631,62.015 C228.059,62.015 229.094,60.952 229.094,59.488 L229.094,42.87 C229.094,37.06 233.173,32.843 238.794,32.843 C244.299,32.843 247.585,36.41 247.585,42.384 L247.585,59.488 C247.585,60.929 248.67,62.015 250.109,62.015 C251.536,62.015 252.571,60.952 252.571,59.488 L252.571,41.839 C252.571,33.488 247.33,28.093 239.217,28.093"
          fill={fills[fill].type}
        />
        <path
          d="M216.53,52.776 C215.718,52.776 215.179,53.222 214.846,53.558 C212.111,56.298 209.094,57.688 205.879,57.688 C199.321,57.688 194.183,52.245 194.183,45.296 L194.183,45.175 C194.183,38.294 199.215,32.904 205.637,32.904 C209.67,32.904 212.381,34.97 214.481,36.972 C214.895,37.387 215.546,37.634 216.227,37.634 C217.641,37.634 218.75,36.524 218.75,35.107 C218.75,34.437 218.474,33.745 218.006,33.278 C215.431,30.863 211.703,28.093 205.698,28.093 C196.466,28.093 188.954,35.81 188.954,45.296 L188.954,45.417 C188.954,54.836 196.466,62.5 205.698,62.5 C210.623,62.5 214.523,60.76 218.335,56.865 C218.747,56.452 218.992,55.845 218.992,55.242 C218.992,53.951 217.819,52.776 216.53,52.776"
          fill={fills[fill].type}
        />
        <path
          d="M170.606,28.093 C166.474,28.093 163.027,29.582 160.483,32.433 L160.483,31.104 C160.483,29.664 159.398,28.577 157.959,28.577 C156.578,28.577 155.496,29.687 155.496,31.104 L155.496,59.488 C155.496,60.929 156.581,62.015 158.02,62.015 C159.448,62.015 160.483,60.952 160.483,59.488 L160.483,42.87 C160.483,37.06 164.562,32.843 170.182,32.843 C175.687,32.843 178.974,36.41 178.974,42.384 L178.974,59.488 C178.974,60.929 180.059,62.015 181.498,62.015 C182.925,62.015 183.961,60.952 183.961,59.488 L183.961,41.839 C183.961,33.488 178.719,28.093 170.606,28.093"
          fill={fills[fill].type}
        />
        <path
          d="M134.062,45.276 C137.977,45.276 141.124,45.868 143.519,46.449 L143.519,49.177 C143.519,54.029 138.933,57.688 132.852,57.688 C128.904,57.688 124.907,55.598 124.907,51.604 L124.907,51.482 C124.907,47.596 128.33,45.276 134.062,45.276 Z M134.485,28.334 C130.854,28.334 127.691,29.05 123.944,30.712 C123.348,30.95 122.462,31.753 122.462,32.985 C122.462,34.615 124.307,35.941 125.965,35.109 C128.774,33.77 131.423,33.146 134.304,33.146 C138.48,33.146 143.459,34.612 143.459,41.597 L143.459,41.631 C141.058,41.056 138.212,40.586 134.244,40.586 C125.432,40.586 119.738,44.934 119.738,51.664 L119.738,51.785 C119.738,57.11 124.181,62.5 132.67,62.5 C137.853,62.5 141.317,60.377 143.459,58.316 L143.459,59.488 C143.459,60.952 144.494,62.015 145.922,62.015 C147.349,62.015 148.385,60.952 148.385,59.488 L148.385,41.475 C148.385,37.496 147.227,34.317 144.943,32.028 C142.532,29.612 138.916,28.334 134.485,28.334 L134.485,28.334 Z"
          fill={fills[fill].type}
        />
        <path
          d="M117.484,28.334 C113.967,28.334 109.317,30.394 106.09,34.842 L106.09,31.104 C106.09,29.664 105.005,28.577 103.566,28.577 C102.185,28.577 101.103,29.687 101.103,31.104 L101.103,59.488 C101.103,60.929 102.188,62.015 103.627,62.015 C105.008,62.015 106.09,60.905 106.09,59.488 L106.09,48.086 C106.09,38.211 112.024,34.142 117.547,33.629 C119.007,33.539 120.067,32.426 120.067,30.983 C120.067,29.498 118.932,28.334 117.484,28.334"
          fill={fills[fill].type}
        />
        <path
          d="M90.156,45.296 L90.156,45.417 C90.156,52.721 85.699,57.628 79.064,57.628 C71.915,57.628 67.49,51.258 67.49,45.357 L67.49,45.235 C67.49,37.656 73.497,32.964 79.064,32.964 C85.387,32.964 90.156,38.266 90.156,45.296 Z M79.367,28.093 C74.811,28.093 70.887,29.98 67.67,33.713 L67.67,17.762 C67.67,16.321 66.585,15.235 65.147,15.235 C63.72,15.235 62.684,16.298 62.684,17.762 L62.684,59.488 C62.684,60.905 63.793,62.015 65.207,62.015 C66.588,62.015 67.67,60.905 67.67,59.488 L67.67,57.099 C70.821,60.685 74.744,62.5 79.367,62.5 C87.24,62.5 95.383,56.064 95.383,45.296 L95.383,45.175 C95.383,34.482 87.24,28.093 79.367,28.093 L79.367,28.093 Z"
          fill={fills[fill].type}
        />
        <path
          d="M25.338,65.731 L25.338,44.959 L37.914,34.922 C39.548,38.62 40.568,41.624 40.568,43.331 C40.568,50.799 38.341,53.961 25.338,65.731 Z M5.054,43.331 C5.054,38.525 13.131,23.446 20.284,11.465 L20.284,65.731 C7.282,53.961 5.054,50.799 5.054,43.331 Z M25.338,11.465 C28.942,17.502 32.779,24.322 35.701,30.222 L25.338,38.493 L25.338,11.465 Z M24.945,1.172 C24.481,0.442 23.676,0 22.811,0 C21.946,0 21.141,0.442 20.677,1.172 C18.561,4.507 0,34.02 0,43.331 C0,53.964 4.38,58.318 21.127,73.283 C21.607,73.712 22.209,73.926 22.811,73.926 C23.413,73.926 24.015,73.712 24.495,73.283 C41.242,58.318 45.623,53.964 45.623,43.331 C45.623,34.02 27.061,4.507 24.945,1.172 L24.945,1.172 Z"
          fill={fills[fill].logo}
        />
      </g>
    </svg>
  );
};

ComboMark.defaultProps = {
  fill: "primary",
};

export default ComboMark;
