import React, { useState, useCallback } from "react";
import { FormikHelpers } from "formik";
import { TOpenShiftFormValues } from "../shift_form/types";
import OpenShiftForm from "../shift_form/OpenShiftForm";
import { errorIsViolation } from "src/lib/violations";
import { useStoreActions } from "src/store";
import { prepareDraftOpenShift } from "src/lib/drafts";
import { AxiosError } from "axios";
import { TShiftViolationErrorData } from "src/api/api.types";
import { Moment } from "moment";

interface IProps {
  role?: string;
  notifyGroups?: number[];
  reason?: string;
  nearbyLocations?: string[];
  start: Moment;
  end: Moment;
  eventId: string;
  handleClose: () => void;
}

const EditDraftOpenShiftForm: React.FC<IProps> = (props) => {
  const {
    role,
    notifyGroups,
    reason,
    nearbyLocations,
    start,
    end,
    eventId,
    handleClose,
  } = props;
  const [showViolations, setShowViolations] = useState(false);
  const [violationMessage, setViolationMessage] = useState("");
  const editDraftOpenShift = useStoreActions(
    (state) => state.draft.editDraftOpenShift
  );

  const submit = useCallback(
    async (
      values: TOpenShiftFormValues,
      helpers: FormikHelpers<TOpenShiftFormValues>
    ) => {
      helpers.setSubmitting(true);
      try {
        const params = prepareDraftOpenShift(
          {
            role: values.role,
            start: values.start,
            end: values.end,
            groups: values.notifyGroups,
            nearbyLocations: values.nearbyLocations,
            reason: values.reason,
          },
          eventId
        );
        editDraftOpenShift(params);

        handleClose();
      } catch (err) {
        if (errorIsViolation(err)) {
          setShowViolations(true);
          const error = err as AxiosError<TShiftViolationErrorData>;
          setViolationMessage(error.response?.data.meta.detail || "");
        }
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [editDraftOpenShift, eventId, handleClose]
  );

  return (
    <OpenShiftForm
      role={role}
      start={start}
      end={end}
      notifyGroups={notifyGroups}
      reason={reason}
      nearbyLocations={nearbyLocations}
      handleSubmit={submit}
      violationMessage={violationMessage}
      setViolationMessage={setViolationMessage}
      setShowViolations={setShowViolations}
      showViolations={showViolations}
    />
  );
};

export default EditDraftOpenShiftForm;
