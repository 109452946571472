import React, { useMemo } from "react";
import { Moment } from "moment";
import { getPrettyTimeRange } from "src/lib/rangeHandler";

interface IProps {
  start: Moment;
  end: Moment;
}

const PrettyTimeRange: React.FC<IProps> = (props) => {
  const { start, end } = props;
  const [startTime, endTime] = useMemo(() => getPrettyTimeRange(start, end), [
    start,
    end,
  ]);

  return (
    <>
      {startTime} &ndash; {endTime}
    </>
  );
};

export default PrettyTimeRange;
