import React, { useEffect } from "react";
import { useMeasure } from "react-use";
import { useStoreActions, useStoreState } from "src/store";

const FluidWrapper: React.FC<{ children: React.ReactNode }> = (props) => {
  const asideWidth = useStoreState(
    (state) => state.scheduler.layout.asideWidth
  );
  const minColWidth = useStoreState(
    (state) => state.scheduler.layout.minColWidth
  );
  const columns = useStoreState((state) => state.scheduler.columns);
  const setTableWidth = useStoreActions(
    (state) => state.scheduler.setTableWidth
  );

  const setTableMainWidth = useStoreActions(
    (state) => state.scheduler.setTableMainWidth
  );
  const [ref, { width }] = useMeasure();

  useEffect(() => {
    setTableWidth(width);
    const mainWidth = width - asideWidth;
    const isBelowMin = mainWidth / columns.length < minColWidth;
    const tablemainWidth = isBelowMin
      ? minColWidth * columns.length
      : mainWidth;
    setTableMainWidth(tablemainWidth);
  }, [
    width,
    setTableWidth,
    asideWidth,
    minColWidth,
    columns,
    setTableMainWidth,
  ]);

  return (
    <div style={{ width: "100%" }} ref={ref}>
      {props.children}
    </div>
  );
};

export default FluidWrapper;
