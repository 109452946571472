export const rowsToCSVString = (data: (string | number | boolean)[][]) => {
  let csvContent = "";
  data.forEach((arr, index) => {
    const stringified = arr.join(";");
    csvContent += index < data.length ? stringified + "\n" : stringified;
  });
  return csvContent;
};

// The download function takes a CSV string, the filename and mimeType as parameters
// Scroll/look down at the bottom of this snippet to see how download is called
export const downloadFile = function (
  content: string,
  fileName: string,
  mimeType: string
) {
  var a = document.createElement("a");
  mimeType = mimeType || "application/octet-stream";

  if (navigator.msSaveBlob) {
    // IE10
    navigator.msSaveBlob(
      new Blob([content], {
        type: mimeType,
      }),
      fileName
    );
  } else if (URL && "download" in a) {
    // html5 A[download]
    a.href = URL.createObjectURL(
      new Blob([content], {
        type: mimeType,
      })
    );
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    window.location.href =
      "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
  }
};

// download(csvContent, "dowload.csv", "text/csv;encoding:utf-8");
