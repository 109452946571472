import React, { useMemo, useState, useCallback } from "react";
import { TDecoratedDraftShift } from "../../scheduler.types";
import InteractiveShiftWrapper from "../../InteractiveShiftWrapper";
import DraftShiftPopup from "./DraftShiftPopup";
import BaseEventCard from "../../BaseEventCard";
import useRoleColor from "../../hooks/useRoleColor";
import PrettyTimeRange from "src/components/common/pretty_time_range/PrettyTimeRange";
import useDuration from "src/hooks/useDuration";
import { TMomentRange } from "src/lib/rangeHandler";
import PopupHandler, {
  IPopupHandlerBag,
} from "src/components/common/popup/PopupHandler";
import { Moment } from "moment";

interface IProps {
  compact?: boolean;
  event: TDecoratedDraftShift;
  canResize: boolean;
}

const DraftShiftEvent: React.FC<IProps> = (props) => {
  const { event, canResize } = props;
  const [popupVisible, setPopupVisible] = useState(false);
  const [persistEditedState, setPersistEditedState] = useState(false);
  const [editValues, setEditValues] = useState<TMomentRange>();
  const roleColor = useRoleColor(event.data.role);
  const duration = useDuration(event.start, event.end);

  const containerStyles = useMemo(
    () => ({
      border: `2px dashed ${roleColor}`,
      backgroundColor: "white",
      color: roleColor,
    }),
    [roleColor]
  );

  const onResize = useCallback(
    (start: Moment, end: Moment) => {
      setPersistEditedState(true);
      setEditValues({ start, end });
      setPopupVisible(true);
    },
    [setPopupVisible, setPersistEditedState, setEditValues]
  );

  const setVisible = useCallback(
    (visible: boolean) => {
      if (!visible) {
        setEditValues(undefined);
        setPersistEditedState(false);
      }
      setPopupVisible(visible);
    },
    [setEditValues, setPopupVisible]
  );

  const renderPopup = useCallback(
    ({ hide }: IPopupHandlerBag) => (
      <DraftShiftPopup
        event={event}
        editValues={editValues}
        initialView="edit"
        close={hide}
      />
    ),
    [event, editValues]
  );

  return (
    <InteractiveShiftWrapper
      event={event}
      canResize={canResize}
      onResize={onResize}
      persistEditedState={persistEditedState}
    >
      <PopupHandler
        renderPopup={renderPopup}
        placement="bottom"
        visible={popupVisible}
        setVisible={setVisible}
      >
        {({ visible, show }) => (
          <BaseEventCard
            event={event}
            isActive={visible}
            style={containerStyles}
            onClick={show}
          >
            <PrettyTimeRange start={event.start} end={event.end} />
            <div>
              {event.data.role} ({duration}hrs)
            </div>
          </BaseEventCard>
        )}
      </PopupHandler>
    </InteractiveShiftWrapper>
  );
};

export default DraftShiftEvent;
