import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

const WandIcon: React.FC<Props> = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g fill="#000" fillRule="nonzero">
      <path d="M15.176 9.736a1.082 1.082 0 00-.692-1.077l-2.1-.826a.48.48 0 01-.304-.436l-.052-2.355a1.089 1.089 0 00-.659-1.023 1.068 1.068 0 00-1.179.248L8.928 5.534a.48.48 0 01-.496.115l-2.096-.726h-.009a1.116 1.116 0 00-1.403 1.413l.725 2.098a.48.48 0 01-.115.496l-1.269 1.265a1.065 1.065 0 00-.247 1.18c.18.397.574.653 1.01.656l2.369.053a.48.48 0 01.437.303l.824 2.096c.162.42.568.697 1.019.694h.06c.48-.02.894-.35 1.018-.816l.714-2.557a.48.48 0 01.333-.333l2.558-.715c.466-.125.796-.538.816-1.02zM2.611 3.629A.72.72 0 003.63 2.61l-1.44-1.44A.72.72 0 001.17 2.19l1.44 1.44zM8.4 3.36a.72.72 0 00.72-.72V.72a.72.72 0 10-1.44 0v1.92c0 .398.322.72.72.72zM3.36 8.88a.72.72 0 00-.72-.72H.72a.72.72 0 100 1.44h1.92a.72.72 0 00.72-.72zM22.424 19.828l-8.51-7.569a.48.48 0 00-.66.02l-.976.976a.48.48 0 00-.019.657l7.57 8.512a1.838 1.838 0 102.592-2.592l.003-.004z" />
    </g>
  </svg>
);

export default WandIcon;
