import { useRef, useCallback, useEffect } from "react";

export interface IDragResult {
  origin: number;
  translation: number;
}

const useDragHandle = (
  onDrag: (translation: number) => void,
  onDragEnd: (result: IDragResult) => void
) => {
  const ref = useRef<HTMLDivElement>(null);
  const origin = useRef(0);
  const translation = useRef(0);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      const updatedTranslation = e.clientX - origin.current;
      translation.current = updatedTranslation;
      onDrag(updatedTranslation);
    },
    [origin, onDrag]
  );

  const handleMouseUp = useCallback(
    (e: MouseEvent) => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);

      onDragEnd({
        origin: origin.current,
        translation: translation.current,
      });

      translation.current = 0;
      origin.current = 0;
    },
    [onDragEnd, origin, translation, handleMouseMove]
  );

  const handleMouseDown = useCallback(
    (e: MouseEvent) => {
      origin.current = e.clientX;
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
      e.preventDefault();
    },
    [handleMouseMove, handleMouseUp]
  );

  useEffect(() => {
    let curr = ref.current;
    if (curr !== null) {
      curr.addEventListener("mousedown", handleMouseDown);
    }

    return () => {
      if (curr !== null) {
        curr.removeEventListener("mousedown", handleMouseDown);
      }
    };
  }, [ref, handleMouseDown]);

  return ref;
};

export default useDragHandle;
