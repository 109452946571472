import React, { useCallback, useMemo } from "react";
import { Input } from "reactstrap";
import FIFTEEN_MIN_INTERVALS, {
  TFifteenMinuteInterval,
} from "src/lib/fifteenMinIntervals";
import styles from "../index.module.scss";

interface IProps {
  id: string;
  value: TFifteenMinuteInterval;
  handleChange: (val: TFifteenMinuteInterval) => void;
}

const TimeField: React.FC<IProps> = (props) => {
  const { value, handleChange, id } = props;

  const options = useMemo(() => {
    return FIFTEEN_MIN_INTERVALS.map((interval) => (
      <option key={interval}>{interval}</option>
    ));
  }, []);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(e.target.value as TFifteenMinuteInterval);
    },
    [handleChange]
  );

  return (
    <Input
      className={styles.DateTimeSectionTime}
      type="select"
      name={id}
      id={id}
      value={value}
      onChange={onChange}
    >
      {options}
    </Input>
  );
};

export default TimeField;
