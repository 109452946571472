import React from "react";
import { Button, ButtonProps } from "reactstrap";
import CaretDownIcon from "../icons/CaretDownIcon";
import styles from "./index.module.scss";
import classnames from "classnames";

interface IProps extends ButtonProps {
  children: React.ReactNode;
  lightArrow?: boolean;
}

const DropdownButton: React.FC<IProps> = (props) => {
  const { children, lightArrow, ...rest } = props;

  const classes = classnames(styles.Button, {
    [styles.Button_LightArrow]: lightArrow,
  });

  return (
    <Button {...rest}>
      {children} <CaretDownIcon className={classes} />
    </Button>
  );
};

export default DropdownButton;
