import React, { useMemo } from "react";
import styles from "./index.module.scss";
import { IRow, RenderCell, RenderEvent } from "./types";
import classnames from "classnames";
import useRowHeight from "./hooks/useRowHeight";
import { useStoreState } from "src/store";

interface IProps {
  row: IRow;
  isEven: boolean;
  renderCell: RenderCell;
  renderEvent: RenderEvent;
}

const Row: React.FC<IProps> = (props) => {
  const { row, isEven, renderCell, renderEvent } = props;
  const columns = useStoreState((state) => state.scheduler.columns);
  const minColWidth = useStoreState(
    (state) => state.scheduler.layout.minColWidth
  );
  const rowHeight = useRowHeight(row);
  const colStyle = {
    flexBasis: `${minColWidth}px`,
  };

  const classes = useMemo(
    () =>
      classnames(styles.Row, {
        [styles.Row_Even]: isEven,
      }),
    [isEven]
  );

  return (
    <div className={classes} style={{ height: `${rowHeight + 2}px` }}>
      <div className={styles.RowCols}>
        {columns.map((col) => (
          <div key={col.time.format()} className={styles.Col} style={colStyle}>
            {renderCell({ col, row })}
          </div>
        ))}
      </div>
      {row.events.map((event) => (
        <React.Fragment key={event.id}>{renderEvent(event)}</React.Fragment>
      ))}
    </div>
  );
};

export default Row;
