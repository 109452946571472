import React from "react";
import { Button, ButtonProps } from "reactstrap";
import { Spinner } from "src/components/common/loader";
import classnames from "classnames";
import styles from "./styles.module.scss";

interface IProps extends ButtonProps {
  children: React.ReactNode;
  loading: boolean;
}

const StatefulButton = (props: IProps) => {
  const { loading, children, className, ...rest } = props;
  const klasses = classnames(styles.StatefulBtn, className, {
    [styles.isLoading]: loading,
  });

  return (
    <Button className={klasses} {...rest}>
      <span className={styles.Contents}>{children}</span>
      <Spinner color="light" />
    </Button>
  );
};

StatefulButton.defaultProps = {
  color: "primary",
};

export default StatefulButton;
