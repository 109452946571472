import { useMemo } from "react";

const useInitials = (name: string) =>
  useMemo(() => {
    let i = "";
    const split = name.split(" ");
    if (split.length >= 2) {
      i = `${split[0].charAt(0)}${split[1].charAt(0)}`;
    } else if (split.length === 1) {
      i = `${split[0].charAt(0)}`;
    } else {
      i = "AA";
    }
    return i;
  }, [name]);

export default useInitials;
