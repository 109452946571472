import { useEffect } from 'react';
import { useStoreActions } from 'src/store';

const useInitUserDetails = () => {
  const fetchUserDetails = useStoreActions(
    (actions) => actions.user.fetchUserDetails
  );

  const setUserDetails = useStoreActions(
    (actions) => actions.user.details.setData
  );

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails, setUserDetails]);
};

export default useInitUserDetails;
