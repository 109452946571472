import { FIFTEEN_MIN_INTERVALS_IN_ONE_DAY } from 'src/lib/fifteenMinIntervals';
import { DOMINOS_ROLES, TDominosRoleName } from 'src/lib/dominos';
import { TForecastData } from 'src/api/api.types';
import { TStateEvents } from 'src/components/scheduler/scheduler.types';
import { Moment } from 'moment';

export type TPositionsWithData = {
  [key in TDominosRoleName]: boolean;
};

export type TChartDataRoleModel = {
  [key in TDominosRoleName]: number;
};

export type TIntervalDataModel = {
  [interval: string]: TChartDataRoleModel;
};

export type TChartDataModel = {
  [key in TDominosRoleName]?: number;
} & {
  interval: string;
};

const getRoleCounts = (): TChartDataRoleModel => ({
  Driver: 0,
  Insider: 0,
  Manager: 0,
  Chef: 0,
  'Customer Experience Representative': 0,
});

export const generateChartData = (): TIntervalDataModel => {
  const data: TIntervalDataModel = {};
  const length = FIFTEEN_MIN_INTERVALS_IN_ONE_DAY;

  for (let i = 1; i <= length; i++) {
    data[i * 15] = getRoleCounts();
  }

  return data;
};

const getHoursAndMinsFromDayMinutes = (dayMins: number) => {
  const startOfPeriod = dayMins - 15;
  const hour = Math.floor(startOfPeriod / 15 / 4);
  const minute = ((startOfPeriod / 15) % 4) * 15;
  return {
    hour,
    minute,
  };
};

export const processScheduledData = (
  viewDate: Moment,
  events: TStateEvents
) => {
  const scheduledData = generateChartData();

  events.forEach((event) => {
    if (event.type === 'shift' || event.type === 'draft_shift') {
      const { start, end } = event;
      const { role } = event.data;

      Object.keys(scheduledData).forEach((interval) => {
        const time = getHoursAndMinsFromDayMinutes(+interval);
        const startBetween = start.clone().subtract(5, 'minutes');
        const endBetween = end.clone();
        const inRange = viewDate
          .clone()
          .set(time)
          .isBetween(startBetween, endBetween);
        if (inRange) {
          scheduledData[+interval][role as TDominosRoleName] += 1;
        }
      });
    }
  });
  return scheduledData;
};
//
export const processForecastData = (forecast: TForecastData[]) => {
  const forecastData = generateChartData();
  const positionsWithData: TPositionsWithData = {
    Driver: false,
    Insider: false,
    Manager: false,
    Chef: false,
    'Customer Experience Representative': false,
  };

  forecast.forEach((item) => {
    const { type, required_workers, minute_15 } = item;
    const jobKey = DOMINOS_ROLES[type];

    // flag position as having data if not already
    // set and workers are needed for position
    positionsWithData[jobKey] =
      !!positionsWithData[jobKey] || required_workers > 0;

    forecastData[minute_15][jobKey] += required_workers * -1;
  });

  return {
    forecastData,
    positionsWithData,
  };
};

export const processChartData = (
  forecast: TIntervalDataModel,
  scheduled: TIntervalDataModel,
  positionsWithData: TPositionsWithData
): TChartDataModel[] => {
  return Object.keys(forecast).map((interval) => {
    const forecastData = forecast[interval];
    const scheduledData = scheduled[interval];
    const chartData: TChartDataModel = {
      interval,
    };
    Object.keys(positionsWithData).forEach((posKey) => {
      const role = posKey as TDominosRoleName;
      if (positionsWithData[role]) {
        chartData[role] = scheduledData[role] + forecastData[role];
      }
    });

    return chartData;
  });
};
