import React, { useCallback, useState } from "react";
import { PopupCard } from "src/components/common/popup";
import { CardHeader, CardBody } from "reactstrap";
import PopupHeadIcons from "src/components/common/popup/PopupHeadIcons";
import IconButton from "src/components/common/icon-button/IconButton";
import CloseIcon from "src/components/common/icons/CloseIcon";
import { useStoreActions, useStoreState } from "src/store";
import { TDraftModel } from "src/api/api.types";
import StatefulButton from "src/components/common/stateful-button";

interface IProps {
  draft: TDraftModel;
  handleClose: () => void;
}

const styles = {
  popup: {
    width: "350px",
  },
};

const PublishedDraftManagerPopup: React.FC<IProps> = (props) => {
  const { handleClose, draft } = props;
  const [deleting, setDeleting] = useState(false);
  const deleteDraft = useStoreActions((state) => state.draft.deleteDraft);
  const currentWeekRange = useStoreState(
    (state) => state.scheduler.currentWeekRange
  );

  const handleDelete = useCallback(async () => {
    setDeleting(true);
    try {
      await deleteDraft(draft.id);
      handleClose();
    } catch (err) {
      console.error(err);
    } finally {
      setDeleting(false);
    }
  }, [draft.id, deleteDraft, handleClose]);

  return (
    <PopupCard style={styles.popup} className="pb-0">
      <CardHeader>
        <h5>
          {currentWeekRange.start.format("MM/DD")} &ndash;{" "}
          {currentWeekRange.end.format("MM/DD")}
        </h5>
        <PopupHeadIcons>
          <IconButton
            tooltip="Close"
            onClick={handleClose}
            id="DraftPopupClose"
          >
            <CloseIcon width={16} />
          </IconButton>
        </PopupHeadIcons>
      </CardHeader>
      <CardBody>
        <p>
          This week's schedule is published. If you start a new draft, published
          shifts will not be affected.
        </p>
        <StatefulButton
          outline
          className="float-right"
          loading={deleting}
          onClick={handleDelete}
        >
          New Draft
        </StatefulButton>
      </CardBody>
    </PopupCard>
  );
};

export default PublishedDraftManagerPopup;
