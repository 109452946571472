import React from "react";
import useInitUserDetails from "./hooks/useInitUserDetails";
import useInitUserLocations from "./hooks/useInitUserLocations";
import useSchedulerReactions from "./hooks/useSchedulerReactions";
import Navbar from "./components/nav/Navbar";

interface IProps {
  children: React.ReactNode;
}

const AuthenticatedContainer = (props: IProps) => {
  useInitUserDetails();
  useInitUserLocations();
  useSchedulerReactions();

  return (
    <div>
      <Navbar />
      {props.children}
    </div>
  );
};

export default AuthenticatedContainer;
