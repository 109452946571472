import { AxiosError } from "axios";

export const errorIsViolation = (err: AxiosError<any>) => {
  return (
    err.response?.status === 400 && err.response?.data.meta?.rule_violation
  );
};

export const parseViolationString = (violations: string) => {
  const items = violations
    .split("\n")
    .filter((x) => x !== "")
    .map((x) => x.replace("*", ""));
  return items.slice(1, items.length - 1);
};
