import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

const CheckmarkIcon = (props: Props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M8.657 16.203l10.43-10.43a1.25 1.25 0 111.767 1.768L9.541 18.854a1.25 1.25 0 01-1.768 0L3.53 14.612a1.25 1.25 0 011.768-1.768l3.359 3.359z"
        fill="#212121"
      />
    </g>
  </svg>
);

export default CheckmarkIcon;
