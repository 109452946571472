import React from "react";
import styles from "./index.module.scss";
import CaretDownIcon from "../common/icons/CaretDownIcon";
import { TDraftStatus } from "src/components/scheduler/scheduler.types";
import classnames from "classnames";

interface IProps {
  status: TDraftStatus;
  onClick?: () => void;
  showArrow?: boolean;
}

const STATUS_LABELS: { [key in TDraftStatus]: string } = {
  unpublished: "Unpublished",
  published: "Published",
  draft: "Draft",
};

const ScheduleStatusBadge: React.FC<IProps> = (props) => {
  const { status, onClick, showArrow } = props;

  const classes = classnames(styles.ScheduleStatus, {
    [styles.ScheduleStatus_Button]: !!onClick,
    [styles.ScheduleStatus_Published]: status === "published",
    [styles.ScheduleStatus_Unpublished]: status === "unpublished",
  });

  return (
    <div className={classes} onClick={onClick}>
      <div className={styles.ScheduleStatusContent}>
        <h5 className={styles.ScheduleStatusLabel}>{STATUS_LABELS[status]}</h5>
      </div>
      {showArrow && (
        <div className={styles.ScheduleStatusIcon}>
          <CaretDownIcon width="0.5rem" />
        </div>
      )}
    </div>
  );
};

export default ScheduleStatusBadge;
