import React, { useMemo } from 'react';
import styles from './index.module.scss';
import { SchedulerTypes } from '../schedulerTable';
import moment from 'moment';
import {
  TEmployeeRecord,
  TDecoratedEvent,
} from 'src/components/scheduler/scheduler.types';
import RoleBadge from './RoleBadge';
import { useStoreState } from 'src/store';
import EmployeeDraftViolation from './draft_violations/EmployeeDraftViolation';
import Avatar from '../common/avatar/Avatar';
import classnames from 'classnames';
interface IProps {
  row: SchedulerTypes.IRow<TEmployeeRecord, TDecoratedEvent>;
}

const EmployeeResource: React.FC<IProps> = (props) => {
  const { row } = props;

  const draftStatus = useStoreState((state) => state.draft.draftStatus);

  const totalHours = useMemo(() => {
    const total = row.events
      .filter((evt) => {
        return evt.type === 'shift' || evt.type === 'draft_shift';
      })
      .reduce((acc, curr) => {
        return acc + moment.duration(curr.end.diff(curr.start)).asHours();
      }, 0);
    return Math.round(10 * total) / 10;
  }, [row.events]);

  const nameClasses = classnames(styles.ResourceName, {
    'text-danger': !row.data.hasBranchAccount,
  });

  return (
    <div className={styles.Resource}>
      <div className={nameClasses}>
        <Avatar name={row.data.name} imgSrc={row.data.avatar} size="sm" />
        <h5>{row.data.name}</h5>
      </div>
      <div className={styles.ResourceDetails}>
        <div className={styles.ResourceHours}>{totalHours} hrs</div>
        <RoleBadge value={row.data.role} className="ml-2" />
      </div>
      {draftStatus === 'draft' && (
        <EmployeeDraftViolation employee={row.data} />
      )}
    </div>
  );
};

export default EmployeeResource;
