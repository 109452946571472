import React from 'react';
import HeadColumn from 'src/components/scheduler/HeadColumn';
import Resource from 'src/components/scheduler/Resource';
import Event from './DayEvent';
import Cell from './DayCell';
import { useStoreState } from 'src/store';
import DemandForecastContainer from '../demand_forecast/DemandForecastContainer';
import SchedulerWrapper from 'src/components/scheduler/SchedulerWrapper';
import ResourceHeadColumn from '../../ResourceHeadColumn';

const DayView: React.FC = () => {
  const resources = useStoreState((state) => state.resources.resources);
  const events = useStoreState((state) => state.events.events);
  const isDominos = useStoreState((state) => state.location.isDominos);

  return (
    <>
      <SchedulerWrapper
        resources={resources}
        events={events}
        renderResource={(row) => <Resource row={row} />}
        renderHeadCol={(col) => (
          <HeadColumn col={col} format="ha" allowClick={false} />
        )}
        renderCell={({ col, row }) => <Cell column={col} row={row} />}
        renderEvent={(event) => <Event event={event} />}
        renderResourceHeadCol={() => <ResourceHeadColumn />}
      />
      {isDominos && <DemandForecastContainer />}
    </>
  );
};

export default DayView;
