import React, { useMemo, useEffect } from "react";
import FluidWrapper from "./FluidWrapper";
import styles from "./index.module.scss";
import useSchedulerLayout from "./hooks/useSchedulerLayout";
import useScrollBind from "./hooks/useScrollBind";
import Row from "./Row";
import ResourceWrapper from "./ResourceWrapper";
import HeadColWrapper from "./HeadColWrapper";
import { useStoreState, useStoreActions } from "src/store";
import { decorateEvents } from "./utils/decorators";
import generateRows from "./utils/generateRows";
import {
  IResource,
  IEvent,
  RenderResource,
  RenderHeadCol,
  RenderResourceHeadCol,
  RenderCell,
  RenderEvent,
} from "./types";

export interface ISchedulerProps {
  resources: IResource[];
  events: IEvent[];
  renderResource: RenderResource;
  renderHeadCol: RenderHeadCol;
  renderResourceHeadCol: RenderResourceHeadCol;
  renderCell: RenderCell;
  renderEvent: RenderEvent;
}

const SchedulerContainer: React.FC<ISchedulerProps> = (props) => {
  const {
    renderResourceHeadCol,
    renderCell,
    renderEvent,
    renderHeadCol,
    renderResource,
    resources,
    events,
  } = props;
  const columns = useStoreState((state) => state.scheduler.columns);
  const setTableBodyTop = useStoreActions(
    (state) => state.scheduler.setTableBodyTop
  );
  const layout = useSchedulerLayout();
  const bodyRef = React.useRef<HTMLDivElement>(null);
  const bodyContainerRef = React.useRef<HTMLDivElement>(null);
  const headRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const top = bodyContainerRef.current?.offsetTop;
    setTableBodyTop(top || 0);
  }, [bodyContainerRef, setTableBodyTop]);

  useScrollBind(bodyRef, headRef);

  const computeEventPosition = useStoreState(
    (state) => state.scheduler.computeEventPosition
  );

  const range = useStoreState((state) => state.scheduler.range);

  const decoratedEvents = useMemo(
    () => decorateEvents(events, computeEventPosition, range),
    [events, computeEventPosition, range]
  );

  const rows = useMemo(() => generateRows(resources, decoratedEvents), [
    decoratedEvents,
    resources,
  ]);

  const renderedColumns = useMemo(
    () =>
      columns.map((col) => (
        <HeadColWrapper
          key={col.time.format()}
          col={col}
          renderHeadCol={renderHeadCol}
        />
      )),
    [columns, renderHeadCol]
  );

  const renderedResources = useMemo(
    () =>
      rows.map((row) => (
        <ResourceWrapper
          key={row.id}
          row={row}
          renderResource={renderResource}
        />
      )),
    [rows, renderResource]
  );

  const renderedRows = useMemo(
    () =>
      rows.map((row, index) => (
        <Row
          key={row.id}
          row={row}
          isEven={index % 2 === 0}
          renderCell={renderCell}
          renderEvent={renderEvent}
        />
      )),
    [rows, renderCell, renderEvent]
  );

  return (
    <FluidWrapper>
      <div className={styles.Container} style={layout.container}>
        <div className={styles.Aside} style={layout.aside}>
          <div className={styles.AsideCol} style={layout.asideHead}>
            {renderResourceHeadCol()}
          </div>
        </div>
        <div className={styles.HeadBody} ref={headRef}>
          <div style={layout.headRow}>
            <div className={styles.RowCols}>{renderedColumns}</div>
          </div>
        </div>
      </div>
      <div
        className={`${styles.Container} ${styles.Container_Body}`}
        style={layout.containerBody}
        ref={bodyContainerRef}
      >
        <div className={styles.Aside} style={layout.aside}>
          {renderedResources}
        </div>
        <div className={styles.Body} ref={bodyRef}>
          <div className={styles.Rows} style={layout.rows}>
            {renderedRows}
          </div>
        </div>
      </div>
    </FluidWrapper>
  );
};

export default SchedulerContainer;
