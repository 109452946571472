import React, { useMemo, useCallback } from "react";
import { PopupCard } from "src/components/common/popup";
import { CardHeader, CardBody } from "reactstrap";
import PopupHeadIcons from "src/components/common/popup/PopupHeadIcons";
import IconButton from "src/components/common/icon-button/IconButton";
import CloseIcon from "src/components/common/icons/CloseIcon";
import { useStoreState, useStoreActions } from "src/store";
import StatefulButton from "src/components/common/stateful-button";
import { getNodeAutoScheduler } from "src/api";

interface IProps {
  handleClose: () => void;
}

const styles = {
  popup: {
    width: "350px",
  },
};

const AutoschedulerPopup: React.FC<IProps> = (props) => {
  const { handleClose } = props;
  const currentWeekRange = useStoreState(
    (state) => state.scheduler.currentWeekRange
  );
  const locationId = useStoreState((state) => state.location.locationId);
  const draftStatus = useStoreState((state) => state.draft.draftStatus);
  const parseAutoschedulerDataToDraft = useStoreActions(
    (state) => state.draft.parseAutoschedulerDataToDraft
  );

  const { start, end } = useMemo(() => {
    return {
      start: currentWeekRange.start.format("MM/DD"),
      end: currentWeekRange.end.format("MM/DD"),
    };
  }, [currentWeekRange]);

  const runAutoscheduler = useCallback(async () => {
    try {
      const res = await getNodeAutoScheduler({
        locationId,
        start_date: currentWeekRange.start.format("YYYY-MM-DD"),
        end_date: currentWeekRange.end.format("YYYY-MM-DD"),
      });
      parseAutoschedulerDataToDraft(res.data.response["violation-engine-data"]);
    } catch (err) {
      console.error(err);
    }
  }, [locationId, currentWeekRange, parseAutoschedulerDataToDraft]);

  return (
    <PopupCard style={styles.popup} className="pb-0">
      <CardHeader>
        <h5>Autoschedule</h5>
        <PopupHeadIcons>
          <IconButton
            tooltip="Close"
            onClick={handleClose}
            id="AutoschedulerPopupClose"
          >
            <CloseIcon width={16} />
          </IconButton>
        </PopupHeadIcons>
      </CardHeader>
      <CardBody>
        <p>
          Click below to generate a schedule for {start} &ndash; {end} based on
          forecasted sales, fair workweek rules, employee availability, and
          more. Autoschedule results are a best fit and may need minor
          adjustments before publishing.
        </p>
        {draftStatus === "draft" && (
          <p className="font-italic">
            Note: running autoschedule will clear all shifts currently saved in
            this draft.
          </p>
        )}
        <StatefulButton
          className="float-right"
          loading={false}
          onClick={runAutoscheduler}
        >
          Autoschedule
        </StatefulButton>
      </CardBody>
    </PopupCard>
  );
};

export default AutoschedulerPopup;
