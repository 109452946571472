import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

const MenuIcon: React.FC<Props> = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g fill="#000" fillRule="nonzero">
      <rect x={0.5} y={2.5} width={23} height={3} rx={1} />
      <rect x={0.5} y={10.5} width={23} height={3} rx={1} />
      <rect x={0.5} y={18.5} width={23} height={3} rx={1} />
    </g>
  </svg>
);

export default MenuIcon;
