import { Action, action, Computed, computed } from "easy-peasy";

interface FiltersModel {
  showOnlyScheduled: boolean;
  roleFilters: { [key: string]: boolean };
  employeeFilters: { [key: string]: boolean };
  toggleShowOnlyScheduled: Action<FiltersModel>;
  addRoleFilter: Action<FiltersModel, string>;
  removeRoleFilter: Action<FiltersModel, string>;
  addEmployeeFilter: Action<FiltersModel, string>;
  removeEmployeeFilter: Action<FiltersModel, string>;
  hasFilters: Computed<FiltersModel, boolean>;
  roleFiltersCount: Computed<FiltersModel, number>;
  employeeFiltersCount: Computed<FiltersModel, number>;
  clearFilters: Action<FiltersModel>;
}

const filtersStore: FiltersModel = {
  showOnlyScheduled: false,
  roleFilters: {},
  employeeFilters: {},

  addRoleFilter: action((state, role) => {
    state.roleFilters[role] = true;
  }),
  removeRoleFilter: action((state, role) => {
    delete state.roleFilters[role];
  }),
  addEmployeeFilter: action((state, id) => {
    state.employeeFilters[id] = true;
  }),
  removeEmployeeFilter: action((state, id) => {
    delete state.employeeFilters[id];
  }),
  toggleShowOnlyScheduled: action((state) => {
    state.showOnlyScheduled = !state.showOnlyScheduled;
  }),
  hasFilters: computed(
    [
      (state) => state.employeeFilters,
      (state) => state.roleFilters,
      (state) => state.showOnlyScheduled,
    ],
    (employeeFilters, roleFilters, showOnlyScheduled) => {
      const hasRoleFilters = Object.entries(roleFilters).length !== 0;
      const hasEmployeeFilters = Object.entries(employeeFilters).length !== 0;
      return showOnlyScheduled || hasRoleFilters || hasEmployeeFilters;
    }
  ),
  clearFilters: action((state) => {
    state.employeeFilters = {};
    state.roleFilters = {};
    state.showOnlyScheduled = false;
  }),
  roleFiltersCount: computed(
    [(state) => state.roleFilters],
    (filters) => Object.entries(filters).length
  ),
  employeeFiltersCount: computed(
    [(state) => state.employeeFilters],
    (filters) => Object.entries(filters).length
  ),
};

export default filtersStore;
