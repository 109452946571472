import React, { useState } from "react";
import {
  TShiftPopupAction,
  TDecoratedDraftOpenShift,
} from "src/components/scheduler/scheduler.types";
import EditDraftOpenShiftForm from "./EditDraftOpenShiftForm";
import { PopupCard } from "src/components/common/popup";
import { CardHeader, CardBody } from "reactstrap";
import EventPopupHeader from "../event_popup/EventPopupHeader";
import DeleteDraftOpenShift from "./DeleteDraftOpenShift";
import { TMomentRange } from "src/lib/rangeHandler";
//
interface IProps {
  event: TDecoratedDraftOpenShift;
  initialView: TShiftPopupAction;
  editValues?: TMomentRange;
  close: () => void;
}

const styles = {
  popup: {
    width: "500px",
  },
};

const getTitle = (view: TShiftPopupAction) => {
  switch (view) {
    case "edit":
      return "Edit draft open shift";
    case "delete":
      return "Delete draft open shift";
  }
};

const actions: TShiftPopupAction[] = ["delete", "edit"];

// optimization so that this component only re-renders if
// relevant props have changed
const areEqual = (prev: IProps, next: IProps) =>
  prev.event === next.event && prev.close === next.close;

const DraftOpenShiftPopup = React.memo((props) => {
  const { event, close, initialView, editValues } = props;

  const [view, setView] = useState<TShiftPopupAction>(initialView);

  return (
    <PopupCard style={styles.popup}>
      <CardHeader>
        <EventPopupHeader
          view={view}
          setView={setView}
          handleClose={close}
          title={getTitle(view)}
          availableActions={actions}
        />
      </CardHeader>
      <CardBody>
        {view === "edit" && (
          <EditDraftOpenShiftForm
            role={event.data.role}
            start={editValues?.start || event.start}
            end={editValues?.end || event.end}
            notifyGroups={event.data.groups}
            reason={event.data.reason}
            nearbyLocations={event.data.nearby_locations}
            eventId={event.id}
            handleClose={close}
          />
        )}
        {view === "delete" && (
          <DeleteDraftOpenShift eventId={event.id} handleClose={close} />
        )}
      </CardBody>
    </PopupCard>
  );
}, areEqual);

export default DraftOpenShiftPopup;
