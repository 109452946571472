import React, { useMemo } from "react";
import ControlledPopupHandler from "src/components/common/popup/ControlledPopupHandler";
import { parseViolationString } from "src/lib/violations";
import EmployeeDraftViolationPopup from "./EmployeeDraftViolationPopup";
import styles from "./index.module.scss";
import classnames from "classnames";

interface IProps {
  isAcknowledged: boolean;
  violation: string;
  employeeId: string;
}

const EmployeeDraftViolationBadge: React.FC<IProps> = (props) => {
  const { violation, employeeId, isAcknowledged } = props;

  const violationItems = useMemo(() => parseViolationString(violation), [
    violation,
  ]);

  const classes = classnames(styles.ViolationBadge, {
    [styles.ViolationBadge_Acknowledged]: isAcknowledged,
  });

  return (
    <ControlledPopupHandler
      placement="right"
      renderPopup={({ hide }) => (
        <EmployeeDraftViolationPopup
          handleClose={hide}
          violationItems={violationItems}
          employeeId={employeeId}
          isAcknowledged={isAcknowledged}
        />
      )}
    >
      {({ show }) => (
        <div className={classes} onClick={show}>
          {violationItems.length}
        </div>
      )}
    </ControlledPopupHandler>
  );
};

export default EmployeeDraftViolationBadge;
