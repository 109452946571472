import axios from "axios";

const createAxiosToken = (id: string) => {
  let call = axios.CancelToken.source();

  return {
    run: () => {
      if (call) {
        call.cancel("Only one request allowed at a time.");
      }
      call = axios.CancelToken.source();
      return call;
    },
  };
};

export default createAxiosToken;
