import { Moment } from "moment";
import { addWorkshift, editWorkshift } from "src/api";
import { TAddWorkshiftParams, TEditWorkshiftParams } from "src/api/api.types";
import { TEmployeeRecord } from "src/components/scheduler/scheduler.types";
import { TShiftFormValues } from "./types";

export const validateAndPostWorkshift = async (
  params: {
    userId: number;
    owner: number;
    locationId: string;
    role: string;
    start: Moment;
    end: Moment;
  },
  runValidation: boolean = true
) => {
  const data: TAddWorkshiftParams = {
    user_id: params.userId,
    owner: params.owner,
    location_id: params.locationId,
    role: params.role,
    start_time: params.start.format("YYYY-MM-DD HH:mm:ss"),
    end_time: params.end.format("YYYY-MM-DD HH:mm:ss"),
    skip_violation_check: runValidation ? 0 : 1,
  };
  try {
    const res = await addWorkshift(data);
    return res;
  } catch (err) {
    throw err;
  }
};

export const validateAndEditWorkshift = (
  params: {
    id: string;
    start: Moment;
    end: Moment;
    owner: number;
    role: string;
  },
  runValidation: boolean = true
) => {
  const data: TEditWorkshiftParams = {
    owner: params.owner,
    role: params.role,
    start_time: params.start.format("YYYY-MM-DD HH:mm:ss"),
    end_time: params.end.format("YYYY-MM-DD HH:mm:ss"),
    skip_violation_check: runValidation ? 0 : 1,
  };
  return editWorkshift(params.id, data);
};

export const prepareCustomWorkshiftRequest = (
  userId: number,
  locationId: string,
  employee: TEmployeeRecord,
  formValues: TShiftFormValues
) => {
  const userName = employee.name;
  const userNamePieces = userName.split(" ");
  const firstName = userNamePieces[0];
  const lastName = userNamePieces.slice(1).join(" ");
  const startTime = formValues.start.format("YYYY-MM-DD HH:mm:ss");
  const endTime = formValues.end.format("YYYY-MM-DD HH:mm:ss");
  const role = formValues.role;

  return {
    scheduleId: 0,
    createdBy: userId,
    alias: userName,
    lastName,
    firstName,
    owner: null,
    locationId,
    role,
    type: "REG",
    startTime,
    endTime,
  };
};
