import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

const CloseIcon = (props: Props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      d="M13.728 11.692a.24.24 0 010-.34L22.62 2.46A1.44 1.44 0 0020.581.424L11.69 9.312a.24.24 0 01-.34 0L2.46.424A1.44 1.44 0 10.42 2.46l8.891 8.892a.24.24 0 010 .34l-8.89 8.892a1.44 1.44 0 102.037 2.036l8.891-8.892a.24.24 0 01.34 0l8.891 8.892a1.44 1.44 0 002.038-2.036l-8.891-8.892z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);

export default CloseIcon;
