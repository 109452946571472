import React, { useMemo } from "react";
import { TEmployeeRecord } from "src/components/scheduler/scheduler.types";
import { useStoreState } from "src/store";
import EmployeeDraftViolationBadge from "./EmployeeDraftViolationBadge";

interface IProps {
  employee: TEmployeeRecord;
}

const EmployeeDraftViolation: React.FC<IProps> = (props) => {
  const { employee } = props;
  const violations = useStoreState((state) => state.draft.draftViolations);
  const acknowledgedViolations = useStoreState(
    (state) => state.draft.acknowledgedViolations
  );

  const employeeViolations = useMemo(() => {
    return !violations[employee.uid] ? null : violations[employee.uid];
  }, [violations, employee.uid]);

  const isAcknowledged = useMemo(
    () => acknowledgedViolations.includes(employee.uid),
    [employee.uid, acknowledgedViolations]
  );

  if (!employeeViolations) return null;

  return (
    <div>
      <EmployeeDraftViolationBadge
        isAcknowledged={isAcknowledged}
        employeeId={employee.uid}
        violation={employeeViolations}
      />
    </div>
  );
};

export default EmployeeDraftViolation;
