import React from "react";
import { useRoleMetrics } from "./hooks";
import ListItem from "./ListItem";
import {
  TForecastMetricsModel,
  TScheduledMetricsModel,
} from "src/lib/scheduleMetrics";

interface IProps {
  forecast: TForecastMetricsModel;
  scheduled: TScheduledMetricsModel;
  variance: { hours: number; percent: number };
}

const TotalColumn: React.FC<IProps> = (props) => {
  const { forecast, scheduled, variance } = props;
  const roleMetrics = useRoleMetrics(forecast, scheduled);

  return (
    <>
      {roleMetrics.map((role) => (
        <ListItem
          key={role.name}
          label={`Total ${role.name}`}
          value={`${role.scheduled} / ${role.forecast}`}
        />
      ))}
      <ListItem
        label="Total"
        value={`${scheduled.totalHours} / ${forecast.totalHours}`}
      />
      <ListItem
        label="Employees"
        value={`${Object.keys(scheduled.employeesScheduled).length}`}
      />
      <ListItem
        label="Total Variance"
        value={`${variance.hours} (${variance.percent}%)`}
      />
    </>
  );
};

export default TotalColumn;
