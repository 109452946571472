import moment from "moment";
import { TStateTimeoff } from "src/components/scheduler/scheduler.types";
import { TTimeoff } from "src/api/api.types";

export const splitTimeoffEvents = (timeoffs: TTimeoff[]): TStateTimeoff[] => {
  let splitTimeoffs: TStateTimeoff[] = [];

  timeoffs.forEach((timeoff) => {
    const start = moment.unix(+timeoff.timeStart.seconds);
    const end = moment.unix(+timeoff.timeEnd.seconds);

    if (!start.isSame(end, "day")) {
      // add 2 to moment diff because it's
      // exclusive of start and end dates
      const eventCount = end.diff(start, "days") + 1;

      for (let i = 0; i <= eventCount; i++) {
        const eventStart =
          i === 0 ? start : start.clone().add(i, "days").startOf("day");
        const eventEnd =
          i === eventCount ? end : eventStart.clone().endOf("day");

        splitTimeoffs.push({
          type: "timeoff",
          id: `${timeoff.id}_part${i}`,
          start: eventStart,
          end: eventEnd,
          isNearby: false,
          resourceId: `${timeoff.requester.id}`,
          data: timeoff,
        });
      }
    } else {
      splitTimeoffs.push({
        type: "timeoff",
        id: `${timeoff.id}`,
        start,
        end,
        isNearby: false,
        resourceId: `${timeoff.requester.id}`,
        data: timeoff,
      });
    }
  });
  return splitTimeoffs;
};
