import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

const FilterIcon = (props: Props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M13.558 11.99v9.165a.81.81 0 01-1.329.623l-1.62-1.35a.81.81 0 01-.292-.623v-7.95l-7.899-7.9C1.591 3.129 2.059 2 3.23 2h17.54c1.17 0 1.64 1.128.812 1.956l-8.024 8.033z"
        fill="#212121"
      />
    </g>
  </svg>
);

export default FilterIcon;
