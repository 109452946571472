import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

const WatchLaterIcon: React.FC<Props> = (props) => (
  <svg height={24} width={24} viewBox="0 0 24 24" {...props}>
    <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm4.2 14.2L11 13V7h1.5v5.2l4.5 2.7-.8 1.3z" />
  </svg>
);

export default WatchLaterIcon;
