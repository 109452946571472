import { useCallback, useEffect } from "react";

const useEscapeClickHandler = (callback: () => void) => {
  const handleKeyup = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        callback();
      }
    },
    [callback]
  );

  useEffect(() => {
    window.addEventListener("keyup", handleKeyup);
    return () => {
      window.removeEventListener("keyup", handleKeyup);
    };
  }, [handleKeyup]);
};

export default useEscapeClickHandler;
