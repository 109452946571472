import React, { useCallback, useState } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { useStoreState, useStoreActions } from "src/store";
import FilterSection from "./FilterSection";
import FilterSectionHead from "./FilterSectionHead";
import FilterSectionContent from "./FilterSectionContent";

const EmployeeFilter: React.FC = () => {
  const employees = useStoreState((state) => state.employees.employeeRecords);
  const filters = useStoreState((state) => state.filters.employeeFilters);
  const count = useStoreState((state) => state.filters.employeeFiltersCount);
  const addFilter = useStoreActions(
    (actions) => actions.filters.addEmployeeFilter
  );
  const removeFilter = useStoreActions(
    (actions) => actions.filters.removeEmployeeFilter
  );
  const [open, setOpen] = useState(count > 0);

  const handleItemClick = useCallback(
    (id: string) => {
      // remove if it exists, otherwise add
      return !!filters[id] ? removeFilter(id) : addFilter(id);
    },
    [filters, addFilter, removeFilter]
  );

  const toggleAccordion = useCallback(() => setOpen((open) => !open), [
    setOpen,
  ]);

  return (
    <FilterSection>
      <FilterSectionHead
        handleClick={toggleAccordion}
        isOpen={open}
        count={count}
        title="Employees"
      />
      <FilterSectionContent isOpen={open}>
        {employees.map((employee) => (
          <FormGroup key={employee.uid} check>
            <Label check>
              <Input
                type="checkbox"
                checked={filters[employee.uid]}
                onChange={() => handleItemClick(employee.uid)}
              />{" "}
              {employee.name}
            </Label>
          </FormGroup>
        ))}
      </FilterSectionContent>
    </FilterSection>
  );
};

export default EmployeeFilter;
