import React from "react";
import { TDraftStatus } from "src/components/scheduler/scheduler.types";
import DraftActions from "./components/draft/DraftActions";
import ScheduleStatusBadge from "./ScheduleStatusBadge";
import PublishedDraftActions from "./components/draft/PublishedDraftActions";

interface IProps {
  status: TDraftStatus;
}

const ScheduleStatus: React.FC<IProps> = (props) => {
  const { status } = props;

  if (status === "draft") {
    return <DraftActions />;
  }

  if (status === "published") {
    return <PublishedDraftActions />;
  }

  return <ScheduleStatusBadge status={status} />;
};

export default ScheduleStatus;
