import { WEEKDAY_STRINGS, TWeekdayString } from 'src/lib/dates';
import { TAutoschedulerDemand } from 'src/api/api.types';
import { TStateEvents } from 'src/components/scheduler/scheduler.types';
import moment from 'moment';
import { cloneDeep } from 'lodash';

type TRoleTotalHoursModel = {
  [key: string]: number;
};

export type TForecastMetricsModel = {
  totalHours: number;
  roleTotalHours: TRoleTotalHoursModel;
};

type TForecastMetrics = {
  totals: TForecastMetricsModel;
  daily: {
    [key in TWeekdayString]: TForecastMetricsModel;
  };
};

export type TScheduledMetricsModel = {
  totalHours: number;
  employeesScheduled: { [id: string]: true | undefined };
  roleTotalHours: TRoleTotalHoursModel;
};

type TScheduledMetrics = {
  totals: TScheduledMetricsModel;
  daily: {
    [key in TWeekdayString]: TScheduledMetricsModel;
  };
};

export const initForecastMetricsObject = (
  roles: string[]
): TForecastMetricsModel => {
  const roleTotalsObject: TRoleTotalHoursModel = {};
  roles.forEach((role) => {
    roleTotalsObject[role] = 0;
  });
  return {
    totalHours: 0,
    roleTotalHours: { ...roleTotalsObject },
  };
};

export const generateEmptyForecastMetrics = (
  roles: string[]
): TForecastMetrics => {
  const metricsObject = initForecastMetricsObject(roles);
  return {
    totals: cloneDeep(metricsObject),
    daily: {
      sunday: cloneDeep(metricsObject),
      monday: cloneDeep(metricsObject),
      tuesday: cloneDeep(metricsObject),
      wednesday: cloneDeep(metricsObject),
      thursday: cloneDeep(metricsObject),
      friday: cloneDeep(metricsObject),
      saturday: cloneDeep(metricsObject),
    },
  };
};

export const addToForecastMetricsObject = (
  metrics: TForecastMetricsModel,
  forecast: TRoleTotalHoursModel
) => {
  Object.keys(forecast).forEach((roleKey) => {
    const forecastHours = forecast[roleKey];
    metrics.roleTotalHours[roleKey] += forecastHours;
    metrics.totalHours += forecastHours;
  });
  return metrics;
};

export const getForecastedMetrics = (
  roles: string[],
  demand: TAutoschedulerDemand | null
): TForecastMetrics => {
  const metrics = generateEmptyForecastMetrics(roles);

  if (demand) {
    WEEKDAY_STRINGS.forEach((weekday) => {
      addToForecastMetricsObject(metrics.totals, demand[weekday]);
      addToForecastMetricsObject(metrics.daily[weekday], demand[weekday]);
    });
  }

  return metrics;
};

export const getForecastedMetricsForDay = (
  roles: string[],
  demand?: {
    [key: string]: number;
  } | null
): TForecastMetricsModel => {
  const metricsObject = initForecastMetricsObject(roles);

  if (demand) {
    addToForecastMetricsObject(metricsObject, demand);
  }

  return metricsObject;
};

export const addToScheduledMetricsObject = (
  metrics: TScheduledMetricsModel,
  eventData: { hours: number; role: string; id: string }
) => {
  const { hours, role, id } = eventData;

  // always add hours to totalHours
  metrics.totalHours += hours;

  // add shift hours to role totals for
  // each metric object
  if (typeof metrics.roleTotalHours[role] === 'number') {
    metrics.roleTotalHours[role] += hours;
  }
  // add only if the employee hasn't already been added
  // to the total metrics employee dictionary
  if (!metrics.employeesScheduled[id]) {
    metrics.employeesScheduled[id] = true;
  }
};

export const initScheduledMetricsObject = (
  roles: string[]
): TScheduledMetricsModel => {
  const roleTotalsObject: TRoleTotalHoursModel = {};
  roles.forEach((role) => {
    roleTotalsObject[role] = 0;
  });
  return {
    totalHours: 0,
    employeesScheduled: {},
    roleTotalHours: { ...roleTotalsObject },
  };
};

export const generateEmptyScheduledMetrics = (
  roles: string[]
): TScheduledMetrics => {
  const metricsObject = initScheduledMetricsObject(roles);
  return {
    totals: cloneDeep(metricsObject),
    daily: {
      sunday: cloneDeep(metricsObject),
      monday: cloneDeep(metricsObject),
      tuesday: cloneDeep(metricsObject),
      wednesday: cloneDeep(metricsObject),
      thursday: cloneDeep(metricsObject),
      friday: cloneDeep(metricsObject),
      saturday: cloneDeep(metricsObject),
    },
  };
};

export const getScheduledMetrics = (roles: string[], events: TStateEvents) => {
  const metrics = generateEmptyScheduledMetrics(roles);
  events.forEach((event) => {
    if (
      event.resourceId !== 'none' &&
      !event.isNearby &&
      (event.type === 'shift' || event.type === 'draft_shift')
    ) {
      const { start, end } = event;
      const { role } = event.data;

      const eventStartDay = start
        .format('dddd')
        .toLowerCase() as TWeekdayString;

      const duration = moment.duration(end.diff(start));
      const hours = duration.asHours();

      const userData = {
        hours,
        role,
        id: event.resourceId,
      };

      addToScheduledMetricsObject(metrics.totals, userData);
      addToScheduledMetricsObject(metrics.daily[eventStartDay], userData);
    }
  });
  return metrics;
};

export const getScheduledMetricsForDay = (
  roles: string[],
  events: TStateEvents,
  weekday: TWeekdayString
) => {
  const metrics = initScheduledMetricsObject(roles);
  events.forEach((event) => {
    if (event.type === 'shift' || event.type === 'draft_shift') {
      const { start, end } = event;
      const { role } = event.data;

      const eventStartDay = start
        .format('dddd')
        .toLowerCase() as TWeekdayString;

      if (eventStartDay === weekday) {
        const duration = moment.duration(end.diff(start));
        const hours = duration.asHours();

        const userData = {
          hours,
          role,
          id: event.resourceId,
        };

        addToScheduledMetricsObject(metrics, userData);
      }
    }
  });
  return metrics;
};

export const calculateVariance = (scheduled: number, forecasted: number) => {
  const diff = scheduled - forecasted;
  const percent = (scheduled / forecasted - 1) * 100;

  return {
    hours: diff,
    percent: Math.round(10 * percent) / 10,
  };
};

export const getVarianceMetrics = (
  scheduled: TScheduledMetrics,
  forecasted: TForecastMetrics
) => {
  return {
    totals: calculateVariance(
      scheduled.totals.totalHours,
      forecasted.totals.totalHours
    ),
    daily: {
      sunday: calculateVariance(
        scheduled.daily.sunday.totalHours,
        forecasted.daily.sunday.totalHours
      ),
      monday: calculateVariance(
        scheduled.daily.monday.totalHours,
        forecasted.daily.monday.totalHours
      ),
      tuesday: calculateVariance(
        scheduled.daily.tuesday.totalHours,
        forecasted.daily.tuesday.totalHours
      ),
      wednesday: calculateVariance(
        scheduled.daily.wednesday.totalHours,
        forecasted.daily.wednesday.totalHours
      ),
      thursday: calculateVariance(
        scheduled.daily.thursday.totalHours,
        forecasted.daily.thursday.totalHours
      ),
      friday: calculateVariance(
        scheduled.daily.friday.totalHours,
        forecasted.daily.friday.totalHours
      ),
      saturday: calculateVariance(
        scheduled.daily.saturday.totalHours,
        forecasted.daily.saturday.totalHours
      ),
    },
  };
};

export const getVarianceMetricsForDay = (
  scheduled: TScheduledMetricsModel,
  forecasted: TForecastMetricsModel
) => {
  return calculateVariance(scheduled.totalHours, forecasted.totalHours);
};
