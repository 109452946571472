import React, { useCallback } from "react";
import styles from "./index.module.scss";
import classnames from "classnames";
import IconButton from "../common/icon-button/IconButton";
import AccountCircleIcon from "../common/icons/material/AccountCircleIcon";
import ControlledPopupHandler from "../common/popup/ControlledPopupHandler";
import AccountDropdownPopup from "./AccountDropdownPopup";

const AccountDropdown: React.FC = () => {
  const getButtonClasses = useCallback(
    (open: boolean) =>
      classnames(styles.MenuIcon, {
        [styles.MenuIcon_Disabled]: open,
      }),
    []
  );

  return (
    <ControlledPopupHandler renderPopup={() => <AccountDropdownPopup />}>
      {({ show, visible }) => (
        <IconButton
          onClick={show}
          id="account-dropdown"
          className={getButtonClasses(visible)}
        >
          <AccountCircleIcon width="2rem" />
        </IconButton>
      )}
    </ControlledPopupHandler>
  );
};

export default AccountDropdown;
