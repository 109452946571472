import { Moment } from 'moment';

const generateColumns = (
  startTime: Moment,
  endTime: Moment,
  incrementer: (prev: Moment) => Moment
) => {
  let lastTime = startTime;
  let continueLooping = true;
  const newColumns = [{ time: startTime }];

  while (continueLooping) {
    const newTime = incrementer(lastTime);

    if (newTime.valueOf() < endTime.valueOf()) {
      newColumns.push({ time: newTime });
      lastTime = newTime;
    } else {
      continueLooping = false;
    }
  }

  return newColumns;
};

export default generateColumns;
