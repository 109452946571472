import React from "react";
import { SchedulerTypes } from "src/components/schedulerTable";
import DailyAvailability from "../availability/DailyAvailability";
import CellHandler from "../cell/CellHandler";

interface IProps {
  column: SchedulerTypes.IColumn;
  row: SchedulerTypes.IRow;
}

const WeekCell: React.FC<IProps> = (props) => {
  const { column, row } = props;

  return (
    <CellHandler column={column} row={row}>
      <DailyAvailability time={column.time} userId={+row.id} />
    </CellHandler>
  );
};

export default WeekCell;
