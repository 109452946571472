import { Thunk, thunk, Computed, computed } from 'easy-peasy';
import { TLocationEmployee, TBridgeEmployee } from 'src/api/api.types';
import createCancelToken from 'src/api/cancelToken';
import axios from 'axios';
import { DataModel, dataModel } from 'src/lib/dataModel';
import {
  TEmployeeRecords,
  TEmployeeRecord,
} from 'src/components/scheduler/scheduler.types';
import { getLocationDirectory, getBridgeDirectory } from 'src/api';
import { DOMINOS_ROLES } from 'src/lib/dominos';
import { StoreModel } from '.';

const bridgeEmployeesCancelToken = createCancelToken('bridge employees');
const locationsEmployeesCancelToken = createCancelToken('locations employees');

interface EmployeesModel {
  locationEmployees: DataModel<TLocationEmployee[]>;
  fetchLocationEmployees: Thunk<EmployeesModel, string>;

  bridgeEmployees: DataModel<TBridgeEmployee[]>;
  fetchBridgeEmployees: Thunk<EmployeesModel, string>;

  employeeRecordsById: Computed<EmployeesModel, TEmployeeRecords, StoreModel>;
  employeeRecords: Computed<EmployeesModel, TEmployeeRecord[]>;
}

const employeesStore: EmployeesModel = {
  ///////////////////////
  // LOCATION EMPLOYEES
  ///////////////////////
  locationEmployees: dataModel([]),

  fetchLocationEmployees: thunk(async (actions, locationId) => {
    const call = locationsEmployeesCancelToken.run();
    const { setLoading, setError, setData } = actions.locationEmployees;

    setLoading(true);
    setError(null);

    try {
      const res = await getLocationDirectory(locationId, {
        cancelToken: call.token,
      });
      setData(res.data.response.location.staff);
      setLoading(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        setError(err);
        setLoading(false);
      }
    }
  }),

  ///////////////////////
  // BRIDGE EMPLOYEES
  ///////////////////////

  bridgeEmployees: dataModel([]),

  fetchBridgeEmployees: thunk(async (actions, locationId) => {
    const call = bridgeEmployeesCancelToken.run();
    const { setLoading, setError, setData } = actions.bridgeEmployees;

    setLoading(true);
    setError(null);

    try {
      const res = await getBridgeDirectory(locationId, {
        cancelToken: call.token,
      });
      if (typeof res.data !== 'string') {
        setData(res.data);
      }

      setLoading(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        setError(err);
        setLoading(false);
      }
    }
  }),

  employeeRecordsById: computed(
    [
      (state) => state.locationEmployees.data,
      (state) => state.bridgeEmployees.data,
      (_, storeState) => storeState.nearby.nearbyScheduledEmployees,
      (_, storeState) => storeState.shifts.workshifts.data,
      (_, storeState) => storeState.location.isDominos,
    ],
    (
      locationEmployees,
      bridgeEmployees,
      nearbyScheduledEmployees,
      workshifts,
      isDominos
    ) => {
      const employees: TEmployeeRecords = {};

      workshifts.forEach((shift) => {
        if (!shift.user.id) {
          const userName = shift.user.name;
          employees[userName] = {
            hasBranchAccount: false,
            hasBridgeRecord: false,
            name: shift.user.name,
            userId: null,
            employeeId: null,
            uid: userName,
            locationId: `${shift.user.location?.id}`,
            locationName: `${shift.user.location?.name}`,
            avatar: null,
            created: null,
            accountType: '',
            hireDate: null,
            minor: null,
            accessProfile: null,
            role: null,
            matches: ['workshift_user_name'],
          };
        } else {
          const userId = `${shift.user.id}`;
          employees[userId] = {
            hasBranchAccount: false,
            hasBridgeRecord: false,
            name: shift.user.name,
            userId: null,
            employeeId: null,
            uid: userId,
            locationId: `${shift.user.location?.id}`,
            locationName: `${shift.user.location?.name}`,
            avatar: null,
            created: null,
            accountType: '',
            hireDate: null,
            minor: null,
            accessProfile: null,
            role: null,
            matches: ['workshift_user_id'],
          };
        }
      });
      nearbyScheduledEmployees.forEach((emp) => {
        const userId = `${emp.user_id}`;
        employees[userId] = {
          hasBranchAccount: true,
          hasBridgeRecord: false,
          name: emp.name,
          userId: userId,
          uid: userId,
          employeeId: null,
          locationId: emp.location_id,
          locationName: emp.location_name,
          avatar: null,
          created: null,
          accountType: emp.position_type,
          role: emp.position,
          hireDate: null,
          minor: null,
          accessProfile: null,
          matches: employees[userId]
            ? [...employees[userId].matches, 'nearby']
            : ['nearby'],
        };
      });

      locationEmployees.forEach((emp) => {
        const userId = `${emp.user_id}`;
        employees[userId] = {
          hasBranchAccount: true,
          hasBridgeRecord: false,
          name: emp.name,
          userId: userId,
          uid: userId,
          employeeId: null,
          locationId: emp.location_id,
          locationName: emp.location_name,
          avatar: emp.image.url.length > 0 ? emp.image.url : null,
          created: emp.created_date,
          accountType: emp.position_type,
          role: emp.position,
          hireDate: null,
          minor: null,
          accessProfile: null,
          matches: employees[userId]
            ? [...employees[userId].matches, 'location']
            : ['location'],
        };
      });

      bridgeEmployees.forEach((emp) => {
        const userId = `${emp.user_id}`;
        if (employees[userId]) {
          employees[userId] = {
            ...employees[userId],
            hasBridgeRecord: true,
            employeeId: emp.employee_id,
            name: emp.name,
            hireDate: emp.hire_date,
            minor: emp.minor === 'true',
            accessProfile: emp.access_profile,
            role: isDominos
              ? DOMINOS_ROLES[emp.access_profile]
              : employees[userId].role,
            avatar:
              emp.avatar_url.length > 0
                ? emp.avatar_url
                : employees[userId].avatar,
            matches: [...employees[userId].matches, 'bridge_user_id'],
          };
        } else {
          const userName = emp.name;
          if (employees[userName]) {
            employees[userName] = {
              ...employees[userName],
              hasBranchAccount: false,
              hasBridgeRecord: true,
              employeeId: emp.employee_id,
              name: emp.name,
              hireDate: emp.hire_date,
              minor: emp.minor === 'true',
              accessProfile: emp.access_profile,
              role: isDominos
                ? DOMINOS_ROLES[emp.access_profile]
                : employees[userName].role,
              avatar:
                emp.avatar_url.length > 0
                  ? emp.avatar_url
                  : employees[userName].avatar,
              matches: [...employees[userName].matches, 'bridge_user_name'],
            };
          } else {
            employees[emp.employee_id] = {
              hasBranchAccount: false,
              hasBridgeRecord: true,
              name: emp.name,
              userId: null,
              employeeId: emp.employee_id,
              uid: emp.employee_id,
              locationId: emp.location_id,
              locationName: null,
              avatar: emp.avatar_url,
              created: null,
              accountType: emp.account_type,
              hireDate: emp.hire_date,
              minor: emp.minor === 'true',
              accessProfile: emp.access_profile,
              role: isDominos ? DOMINOS_ROLES[emp.access_profile] : '',
              matches: ['bridge_employee_id'],
            };
          }
        }
      });

      return employees;
    }
  ),

  employeeRecords: computed(
    [(state) => state.employeeRecordsById],
    (employeeRecordsById) =>
      Object.keys(employeeRecordsById)
        .map((id) => ({
          ...employeeRecordsById[id],
        }))
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
  ),
};

export default employeesStore;
