import React, { useMemo } from "react";
import classnames from "classnames";
import styles from "./index.module.scss";
import { IDecoratedEvent } from "../schedulerTable/types";

interface IProps extends React.HTMLProps<HTMLDivElement> {
  isActive?: boolean;
  event: IDecoratedEvent<any, any>;
}

const BaseEventCard: React.FC<IProps> = (props) => {
  const { event, className, children, isActive, ...rest } = props;

  const classes = useMemo(
    () =>
      classnames(className, styles.EventContainer, {
        shadow: isActive,
        [styles.EventContainer_ClipStart]: event.clipStart,
        [styles.EventContainer_ClipEnd]: event.clipEnd,
      }),
    [className, isActive, event]
  );

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

export default BaseEventCard;
