import { IDecoratedEvent } from "../types";

export interface IGroupEventsResponse {
  events: IDecoratedEvent[];
  groupCount: number;
}

const groupEvents = (events: IDecoratedEvent[]): IGroupEventsResponse => {
  let groups: IDecoratedEvent[][] = [];

  events.forEach((e, index) => {
    // for the first event, no group
    // will have been established,
    // so we add the first one manually.
    if (index === 0) {
      groups.push([e]);
      return;
    }

    groups.every((group, index) => {
      const hasGroupConflict = group.some(
        (groupEvent) =>
          e.start.valueOf() >= groupEvent.posStart.valueOf() &&
          e.start.valueOf() <= groupEvent.posEnd.valueOf()
      );

      // if there is a conflict in this group
      // and its the last group, create a new
      // last group.
      if (hasGroupConflict) {
        const isLastGroup = groups.length - 1 === index;
        if (isLastGroup) {
          groups.push([e]);
        } else {
          // don't do anything
        }
      } else {
        group.push(e);
      }

      // The loop should continue only
      // until we find a group we can place
      // the event in without conflict.
      //
      // We use the `.every` loop because it only
      // short-circuits when a `false` is found,
      // so as soon as we find a group where
      // `hasGroupConfict === false`, the loop ends
      return hasGroupConflict;
    });
  });

  // now we flat map the groups back down so
  // that we just return the events with a new
  // attribute indicating which group they're in
  const groupedEvents = groups.flatMap((group, index) =>
    group.map((event) => ({ ...event, group: index }))
  );

  return {
    events: groupedEvents,
    groupCount: groups.length,
  };
};

export default groupEvents;
