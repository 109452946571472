import { Thunk, thunk, action, Action } from 'easy-peasy';
import { TWorkshift, TOpenShift } from 'src/api/api.types';
import createCancelToken from 'src/api/cancelToken';
import axios from 'axios';
import { DataModel, dataModel } from 'src/lib/dataModel';
import { getWorkshifts, getOpenShifts } from 'src/api';
import { StoreModel } from '.';

const workshiftsCancelToken = createCancelToken('workshifts');
const openShiftsCancelToken = createCancelToken('available shifts');

interface ShiftsModel {
  workshifts: DataModel<TWorkshift[]>;
  fetchWorkshifts: Thunk<ShiftsModel, undefined, any, StoreModel>;
  addWorkshift: Action<ShiftsModel, TWorkshift>;
  updateWorkshift: Action<ShiftsModel, TWorkshift>;
  removeWorkshift: Action<ShiftsModel, string>;

  openShifts: DataModel<TOpenShift[]>;
  fetchOpenShifts: Thunk<ShiftsModel, string>;
  addOpenShift: Action<ShiftsModel, TOpenShift>;
  updateOpenShift: Action<ShiftsModel, TOpenShift>;
  removeOpenShift: Action<ShiftsModel, string>;
}

const shiftsStore: ShiftsModel = {
  ///////////////////////
  // WORKSHIFTS
  ///////////////////////

  workshifts: dataModel([]),

  fetchWorkshifts: thunk(async (actions, _, { getStoreState }) => {
    const {
      location: { locationId },
      scheduler: { start, end },
    } = getStoreState();
    const call = workshiftsCancelToken.run();
    const { setLoading, setError, setData } = actions.workshifts;

    setLoading(true);
    setError(null);

    const params = {
      locationId,
      startDate: start.clone().subtract(2, 'day').format('YYYY-MM-DD'),
      endDate: end.clone().add(2, 'day').format('YYYY-MM-DD'),
    };

    try {
      const res = await getWorkshifts(params, {
        cancelToken: call.token,
      });
      setData(res.data.data.workshifts);
      setLoading(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        setError(err);
        setLoading(false);
      }
    }
  }),

  addWorkshift: action((state, payload) => {
    state.workshifts.data.push(payload);
  }),

  updateWorkshift: action((state, payload) => {
    state.workshifts.data.forEach((workshift, index) => {
      if (workshift.id === payload.id) {
        state.workshifts.data[index] = payload;
      }
    });
  }),

  removeWorkshift: action((state, payload) => {
    state.workshifts.data = state.workshifts.data.filter(
      (workshift) => `${workshift.id}` !== payload
    );
  }),

  ///////////////////////
  // AVAILABLE SHIFTS
  ///////////////////////

  openShifts: dataModel([]),

  fetchOpenShifts: thunk(async (actions, locationId) => {
    const call = openShiftsCancelToken.run();
    const { setLoading, setError, setData } = actions.openShifts;

    setLoading(true);
    setError(null);

    try {
      const res = await getOpenShifts(locationId, {
        cancelToken: call.token,
      });
      setData(res.data.data);
      setLoading(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        setError(err);
        setLoading(false);
      }
    }
  }),

  addOpenShift: action((state, payload) => {
    state.openShifts.data.push(payload);
  }),

  updateOpenShift: action((state, payload) => {
    state.openShifts.data.forEach((shift, index) => {
      if (shift.id === payload.id) {
        state.openShifts.data[index] = payload;
      }
    });
  }),

  removeOpenShift: action((state, payload) => {
    state.openShifts.data = state.openShifts.data.filter(
      (shift) => `${shift.id}` !== payload
    );
  }),
};

export default shiftsStore;
