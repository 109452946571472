import { TAvailabilityItem } from "src/api/api.types";
import { ISO_WEEKDAYS } from "./dates";

export interface IAvailabilityRecord {
  approved: boolean;
  available: boolean;
  permanent: boolean;
  startTime: string;
  endTime: string;
  allDay: boolean;
  isoWeekday: number;
}

export const createAvailabilityRecord = (
  record: TAvailabilityItem
): IAvailabilityRecord => {
  const day = record.DayOfWeek.toLowerCase() as keyof typeof ISO_WEEKDAYS;
  return {
    approved: record.Approval === "Y",
    available: record.Availability === "Y",
    permanent: record.Permanent === "Y",
    startTime: record.StartTime,
    endTime: record.EndTime,
    allDay: record.StartTime === "00:00:00" && record.EndTime === "23:59:00",
    isoWeekday: ISO_WEEKDAYS[day],
  };
};
