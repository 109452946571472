export const ROLE_COLORS = [
  "#0091ea",
  "#aa00ff",
  "#388e3c",
  "#6200ea",
  "#304ffe",
  "#2962ff",
  "#006064",
  "#00695c",
  "#d50000",
  "#c51162",
];

export const UNIDENTIFIED_ROLE_COLOR = "#455a64";
