import React, { useMemo } from "react";
import { getRangeText } from "src/lib/rangeHandler";
import { Moment } from "moment";
import RoleBadge from "src/components/scheduler/RoleBadge";
import useDuration from "src/hooks/useDuration";
import { TLocationDetail } from "src/api/api.types";

interface IProps {
  start: Moment;
  end: Moment;
  assignee: string;
  role: string;
  location?: TLocationDetail;
}

const ShiftDetails: React.FC<IProps> = (props) => {
  const { start, end, assignee, role, location } = props;

  const duration = useDuration(start, end);
  const rangeText = useMemo(() => getRangeText({ start, end }), [start, end]);

  return (
    <>
      <RoleBadge value={role} />

      <h4 className="mt-2 mb-0">
        {start.format("h:mmA")} &ndash; {end.format("h:mmA")}
      </h4>
      <p className="mb-1">
        {rangeText} ({duration}hrs)
      </p>
      <p className="mb-1">
        <strong>Assignee:</strong> {assignee}
      </p>
      {!!location && (
        <p className="mb-1">
          <strong>Location</strong>:{" "}
          {`${location.name}, ${location.address_line_1} ${location.address_line_2}`}
        </p>
      )}
    </>
  );
};

export default ShiftDetails;
