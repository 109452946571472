import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

const AddAlarmIcon: React.FC<Props> = (props) => (
  <svg height={24} width={24} viewBox="0 0 24 24" {...props}>
    <path d="M7.88 3.39L6.6 1.86 2 5.71l1.29 1.53 4.59-3.85zM22 5.72l-4.6-3.86-1.29 1.53 4.6 3.86L22 5.72zM12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9a9 9 0 000-18zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7zm1-11h-2v3H8v2h3v3h2v-3h3v-2h-3V9z" />
  </svg>
);

export default AddAlarmIcon;
