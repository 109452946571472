import React, { useState, useCallback } from "react";
import StatefulButton from "src/components/common/stateful-button";
import { useStoreActions } from "src/store";

interface IProps {
  eventId: string;
  handleClose: () => void;
}

const DeleteDraftOpenShift: React.FC<IProps> = (props) => {
  const { eventId, handleClose } = props;
  const [loading, setLoading] = useState(false);

  const deleteDraftOpenShift = useStoreActions(
    (actions) => actions.draft.deleteDraftOpenShift
  );

  const handleDelete = useCallback(async () => {
    setLoading(true);
    try {
      await deleteDraftOpenShift(eventId);
      handleClose();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [eventId, handleClose, deleteDraftOpenShift]);

  return (
    <>
      <p>Are you sure you want to delete this draft workshift?</p>

      <div className="d-flex align-items-center justify-content-between mt-2">
        <StatefulButton
          color="danger"
          onClick={handleDelete}
          className="ml-auto"
          loading={loading}
        >
          Delete
        </StatefulButton>
      </div>
    </>
  );
};

export default DeleteDraftOpenShift;
