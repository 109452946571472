import React from "react";
import { FormikProps } from "formik";
import { TOpenShiftFormValues } from "./types";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import RoleField from "./fields/RoleField";
import DateTime from "./fields/DateTime";
import NotifyGroupsField from "./fields/NotifyGroupsField";
import NearbyLocationsField from "./fields/NearbyLocationsField";
import styles from "./index.module.scss";

interface IProps {
  formik: FormikProps<TOpenShiftFormValues>;
}

const OpenShiftFormFields: React.FC<IProps> = ({ formik }) => {
  return (
    <>
      <Label>When</Label>
      <div className="d-flex align-items-center">
        <DateTime
          date={formik.values.start}
          id="start"
          setFieldValue={formik.setFieldValue}
        />
        <div className={styles.DateTimeTo}>to</div>
        <DateTime
          date={formik.values.end}
          id="end"
          setFieldValue={formik.setFieldValue}
        />
      </div>
      <Row>
        <Col md={8}>
          <FormGroup>
            <Label htmlFor="reason">Reason</Label>
            <Input
              id="reason"
              name="reason"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.reason}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <RoleField
            value={formik.values.role}
            setFieldValue={formik.setFieldValue}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={6}>
          <NotifyGroupsField
            value={formik.values.notifyGroups}
            setFieldValue={formik.setFieldValue}
          />
        </Col>
        <Col xs={6}>
          <NearbyLocationsField
            value={formik.values.nearbyLocations}
            setFieldValue={formik.setFieldValue}
          />
        </Col>
      </Row>
    </>
  );
};

export default OpenShiftFormFields;
