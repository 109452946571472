import { useMemo } from "react";
import { useStoreState } from "src/store";

const useSchedulerLoading = () => {
  const locationEmployeesLoading = useStoreState(
    (state) => state.employees.locationEmployees.loading
  );
  const bridgeEmployeesLoading = useStoreState(
    (state) => state.employees.bridgeEmployees.loading
  );
  const openShiftsLoading = useStoreState(
    (state) => state.shifts.openShifts.loading
  );
  const availabilityLoading = useStoreState(
    (state) => state.availability.availability.loading
  );
  const workshiftsLoading = useStoreState(
    (state) => state.shifts.workshifts.loading
  );
  const templatesLoading = useStoreState(
    (state) => state.templates.templates.loading
  );
  const historicalTimeoffsLoading = useStoreState(
    (state) => state.timeoffs.historicalTimeoffs.loading
  );
  const timeoffsLoading = useStoreState(
    (state) => state.timeoffs.timeoffs.loading
  );
  const demandLoading = useStoreState(
    (state) => state.demand.schedulingDemand.loading
  );
  const draftLoading = useStoreState((state) => state.draft.draft.loading);

  return useMemo(
    () =>
      locationEmployeesLoading ||
      bridgeEmployeesLoading ||
      openShiftsLoading ||
      availabilityLoading ||
      workshiftsLoading ||
      templatesLoading ||
      historicalTimeoffsLoading ||
      draftLoading ||
      timeoffsLoading ||
      demandLoading,
    [
      locationEmployeesLoading,
      bridgeEmployeesLoading,
      openShiftsLoading,
      availabilityLoading,
      workshiftsLoading,
      templatesLoading,
      historicalTimeoffsLoading,
      timeoffsLoading,
      draftLoading,
      demandLoading,
    ]
  );
};

export default useSchedulerLoading;
