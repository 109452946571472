import React, { useMemo } from "react";
import { useStoreState } from "src/store";
import ControlledPopupHandler from "src/components/common/popup/ControlledPopupHandler";
import SortPopup from "./SortPopup";
import styles from "./index.module.scss";
import { CaretDownIcon } from "src/components/common/icons";

const SortAction: React.FC = (props) => {
  const sortBy = useStoreState((state) => state.sort.sortBy);

  const sortLabel = useMemo(() => {
    switch (sortBy) {
      case "name":
        return "Name";
      case "role":
        return "Role";
      default:
        return "Name";
    }
  }, [sortBy]);

  const iconStyle = {
    width: "0.5rem",
    marginLeft: "8px",
    transform: "translateY(-1px)",
  };

  return (
    <ControlledPopupHandler
      placement="bottom-start"
      fillHeight={true}
      renderPopup={({ hide }) => <SortPopup handleClose={hide} />}
    >
      {({ show }) => (
        <div className={styles.SortAction} onClick={show}>
          Sort by {sortLabel}
          <CaretDownIcon style={iconStyle} />
        </div>
      )}
    </ControlledPopupHandler>
  );
};

export default SortAction;
