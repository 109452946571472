import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useEventPosition, SchedulerTypes } from '../schedulerTable';
import EventDragHandle from './EventDragHandle';
import { IDragResult } from './hooks/useDragHandle';
import { useStoreState } from 'src/store';
import { Moment } from 'moment';
import { nearestMinutes } from 'src/lib/dates';

interface IProps {
  event: SchedulerTypes.IDecoratedEvent;
  persistEditedState?: boolean;
  onResize: (start: Moment, end: Moment) => void;
  children: React.ReactNode;
}

const ResizableEvent: React.FC<IProps> = (props) => {
  const { event, children, onResize, persistEditedState } = props;

  const eventPosition = useEventPosition(event);
  const computeStartDragHandlePosition = useStoreState(
    (state) => state.scheduler.computeStartDragHandlePosition
  );
  const computeEndDragHandlePosition = useStoreState(
    (state) => state.scheduler.computeEndDragHandlePosition
  );
  const [leftTranslate, setLeftTranslate] = useState(0);
  const [rightTranslate, setRightTranslate] = useState(0);

  useEffect(() => {
    if (typeof persistEditedState === 'boolean' && !persistEditedState) {
      setLeftTranslate(0);
      setRightTranslate(0);
    }
  }, [persistEditedState, setLeftTranslate, setRightTranslate]);

  const handleLeftDragEnd = useCallback(
    (e: IDragResult) => {
      const start = computeStartDragHandlePosition(event, e.translation);
      const rounded = nearestMinutes(15, start);
      onResize(rounded, event.end);
    },
    [event, onResize, computeStartDragHandlePosition]
  );

  const handleRightDragEnd = useCallback(
    (e: IDragResult) => {
      const end = computeEndDragHandlePosition(event, e.translation);
      const rounded = nearestMinutes(15, end);
      onResize(event.start, rounded);
    },
    [event, onResize, computeEndDragHandlePosition]
  );

  const s = useMemo(
    () => ({
      ...eventPosition,
      left: eventPosition.left + leftTranslate,
      width: eventPosition.width + rightTranslate - leftTranslate,
    }),
    [eventPosition, leftTranslate, rightTranslate]
  );

  return (
    <div style={s}>
      <EventDragHandle
        position="left"
        handleDrag={setLeftTranslate}
        handleDragEnd={handleLeftDragEnd}
      />
      <EventDragHandle
        position="right"
        handleDrag={setRightTranslate}
        handleDragEnd={handleRightDragEnd}
      />
      {children}
    </div>
  );
};

export default ResizableEvent;
