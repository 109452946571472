import React, { useMemo, useState, useCallback } from "react";
import styles from "./index.module.scss";
import { IPartialAvailability } from "./types";
import { getPrettyTimeRange } from "src/lib/rangeHandler";
import classnames from "classnames";

interface IProps {
  data: IPartialAvailability[];
}

const AVAIL = "Avail.";
const UNAVAIL = "Unavail.";

const createPartialAvailabilityText = (d: IPartialAvailability) => {
  const text = d.available ? AVAIL : UNAVAIL;
  const [start, end] = getPrettyTimeRange(d.start, d.end);
  return `${text} ${start} – ${end}`;
};

const PartialAvailability: React.FC<IProps> = ({ data }) => {
  const [open, setOpen] = useState(false);

  const rows = useMemo(
    () => data.map((d) => createPartialAvailabilityText(d)),
    [data]
  );

  const moreRowsText = useMemo(
    () => (data.length > 1 ? ` (+${data.length - 1})` : ""),
    [data]
  );

  const toggleOpen = useCallback(
    (e: React.MouseEvent) => {
      setOpen((o) => !o);
      e.stopPropagation();
    },
    [setOpen]
  );

  const classes = useMemo(
    () =>
      classnames(
        styles.DailyAvailability,
        styles.DailyAvailability_PartiallyAvailable,
        {
          [styles.DailyAvailability_Expandable]: data.length > 1,
          [styles.DailyAvailability_ExpandableOpen]: open,
        }
      ),
    [data, open]
  );

  const style = useMemo(
    () => ({
      height: !open ? "18px" : "100%",
    }),
    [open]
  );

  return (
    <div className={classes} onClick={toggleOpen} style={style}>
      <div>
        {rows[0]}
        {!open && moreRowsText}
      </div>

      {open && (
        <>
          {rows.slice(1).map((r) => (
            <div key={r}>{r}</div>
          ))}
        </>
      )}
    </div>
  );
};

export default PartialAvailability;
