import { Thunk, thunk, Computed, computed } from "easy-peasy";
import { getUserDetails } from "src/api";
import { TUserDetails } from "src/api/api.types";
import { DataModel, dataModel } from "src/lib/dataModel";

interface UserModel {
  details: DataModel<TUserDetails | null>;
  fetchUserDetails: Thunk<UserModel>;
  userId: Computed<UserModel, number>;
}

const userStore: UserModel = {
  ///////////////////////
  // USER DETAILS
  ///////////////////////
  details: dataModel(null),

  fetchUserDetails: thunk(async (actions) => {
    const { setLoading, setError, setData } = actions.details;
    setLoading(true);
    setError(null);
    try {
      const res = await getUserDetails();
      setData(res.data.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }),

  userId: computed([(store) => store.details.data?.user_id], (id) => id || 0),
};

export default userStore;
