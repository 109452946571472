import React, { useState, useMemo, useRef, useCallback } from "react";
import styles from "./index.module.scss";
import classnames from "classnames";
import IconButton from "../common/icon-button/IconButton";
import { MenuIcon } from "../common/icons";
import SideMenuItem from "./SideMenuItem";
import {
  ExploreIcon,
  ChatIcon,
  ContactCalendarIcon,
  WatchLaterIcon,
  EventAvailableIcon,
  NetworkCheckIcon,
  AddAlarmIcon,
  PeopleIcon,
  DateRangeIcon,
  EventBusyIcon,
  OpenWithIcon,
} from "../common/icons/material";
import { useStoreState } from "src/store";
import useOutsideClickHander from "src/components/scheduler/hooks/useOutsideClickHandler";

const v1App = process.env.REACT_APP_NODE_API_URL;

const SideMenu: React.FC = () => {
  const [open, setOpen] = useState(false);
  const settings = useStoreState((state) => state.location.settingsMap);
  const isManager = useStoreState((state) => state.location.isManager);
  const contentsRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (e: React.MouseEvent | MouseEvent) => {
      setOpen(false);
      e.preventDefault();
    },
    [setOpen]
  );

  useOutsideClickHander(contentsRef, handleClickOutside, open);

  const visibility = useMemo(() => {
    const s = settings;
    const isNational = s.NATIONAL_LOCATION === "1";
    const showAvailability = s.USER_AVAILABILITY === "1" && !isNational;
    const showFlexibility = s.FLEXIBILITY === "1" && !isNational;
    const showActivity = s.FEATURE_COMM_FEED === "1";
    const showCalendar = s.WEB_SCHEDULE_SHOW_CALENDAR === "1";
    const showScheduleTab = s.SCHEDULE_TAB_HIDDEN !== "1";
    const parseClassEmpty = s.EXCEL_PARSE_CLASS === "";
    const showAbsences = s.ABSENCES === "1";
    const showTimeoffs = s.REQUEST_TIMEOFF === "1";
    const showVto = s.VOLUNTARY_TIMEOFF === "1";
    const showEmployeeGroupsForAll = s.EMPLOYEE_GROUPS === "1";
    const showEmployeeGroupsForManagers =
      s.EMPLOYEE_GROUPS === "2" && isManager;

    return {
      dashboard: isManager && !isNational,
      feed: showActivity,
      schedules: !isNational,
      schedulesMenu: {
        uploadNew: isManager && showScheduleTab && !parseClassEmpty,
        workshifts: !showCalendar,
        calendar: showCalendar,
      },
      flexibility: showFlexibility,
      shifts: !isNational,
      shiftsMenu: {
        openShifts: true,
        coveredShifts: isManager,
        expiredShifts: isManager,
      },
      timeOffRequests: showTimeoffs && !isNational,
      timeOffRequestsMenu: {
        openTimeOffRequests: true,
        approvedTimeoffRequests: true,
        expiredTimeoffRequests: true,
      },
      vto: showVto && !isNational,
      vtoMenu: {
        coveredVto: true,
        openVto: true,
        expiredVto: true,
      },
      callInRequests: showAbsences,
      availability: showAvailability,
      employeeGroups: showEmployeeGroupsForAll || showEmployeeGroupsForManagers,
      directory: isNational || isManager,
    };
  }, [settings, isManager]);

  const classes = useMemo(
    () =>
      classnames(styles.MenuContainer, {
        [styles.MenuContainer_Open]: open,
      }),
    [open]
  );

  const buttonClasses = useMemo(
    () =>
      classnames(styles.MenuIcon, {
        [styles.MenuIcon_Disabled]: open,
      }),
    [open]
  );

  return (
    <>
      <IconButton
        onClick={() => setOpen(!open)}
        id="nav-menu"
        className={buttonClasses}
      >
        <MenuIcon width="1rem" />
      </IconButton>
      <div className={classes} ref={contentsRef}>
        <div className={styles.Menu}>
          <SideMenuItem
            visible={visibility.dashboard}
            icon={<ExploreIcon />}
            label="Dashboard"
            onClick={() => window.location.assign(`${v1App}`)}
          />
          <SideMenuItem
            visible={visibility.feed}
            icon={<ChatIcon />}
            label="Feed"
            onClick={() => window.location.assign(`${v1App}/feed`)}
          />
          <SideMenuItem
            visible={visibility.schedules}
            icon={<DateRangeIcon />}
            label="Schedules"
          >
            <SideMenuItem
              visible={visibility.schedulesMenu.uploadNew}
              label="Upload New"
              onClick={() => window.location.assign(`${v1App}/schedules/view`)}
            />
            <SideMenuItem
              visible={visibility.schedulesMenu.workshifts}
              label="Workshifts"
              onClick={() =>
                window.location.assign(`${v1App}/schedules/workshifts`)
              }
            />
            <SideMenuItem
              visible={visibility.schedulesMenu.calendar}
              label="Team Calendar"
              onClick={() =>
                window.location.assign(`${v1App}/schedules/team-calendar`)
              }
            />
          </SideMenuItem>
          <SideMenuItem
            visible={visibility.shifts}
            icon={<WatchLaterIcon />}
            label="Shifts"
          >
            <SideMenuItem
              visible={visibility.shiftsMenu.openShifts}
              label="Open"
              onClick={() => window.location.assign(`${v1App}/shifts/open`)}
            />
            <SideMenuItem
              visible={visibility.shiftsMenu.coveredShifts}
              label="Covered"
              onClick={() => window.location.assign(`${v1App}/shifts/covered`)}
            />
            <SideMenuItem
              visible={visibility.shiftsMenu.expiredShifts}
              label="Expired"
              onClick={() => window.location.assign(`${v1App}/shifts/expired`)}
            />
          </SideMenuItem>
          <SideMenuItem
            visible={visibility.vto}
            icon={<EventBusyIcon />}
            label="Voluntary Time Offs"
          >
            <SideMenuItem
              visible={visibility.vtoMenu.openVto}
              label="Open"
              onClick={() => window.location.assign(`${v1App}/vto/open`)}
            />
            <SideMenuItem
              visible={visibility.vtoMenu.coveredVto}
              label="Covered"
              onClick={() => window.location.assign(`${v1App}/vto/covered`)}
            />
            <SideMenuItem
              visible={visibility.vtoMenu.expiredVto}
              label="Expired"
              onClick={() => window.location.assign(`${v1App}/vto/expired`)}
            />
          </SideMenuItem>
          <SideMenuItem
            visible={visibility.timeOffRequests}
            icon={<EventAvailableIcon />}
            label="Time Off Requests"
          >
            <SideMenuItem
              visible={visibility.timeOffRequestsMenu.openTimeOffRequests}
              label="Open"
              onClick={() => window.location.assign(`${v1App}/rto/open`)}
            />
            <SideMenuItem
              visible={visibility.timeOffRequestsMenu.approvedTimeoffRequests}
              label="Approved"
              onClick={() => window.location.assign(`${v1App}/rto/approved`)}
            />
            <SideMenuItem
              visible={visibility.timeOffRequestsMenu.expiredTimeoffRequests}
              label="Expired"
              onClick={() => window.location.assign(`${v1App}/rto/expired`)}
            />
          </SideMenuItem>
          <SideMenuItem
            visible={visibility.callInRequests}
            icon={<AddAlarmIcon />}
            label="Call In Requests"
            onClick={() => window.location.assign(`${v1App}/call-in`)}
          />
          <SideMenuItem
            visible={visibility.availability}
            icon={<NetworkCheckIcon />}
            label="Availability"
            onClick={() => window.location.assign(`${v1App}/availability`)}
          />
          <SideMenuItem
            visible={visibility.flexibility}
            icon={<OpenWithIcon />}
            label="Flexibility"
            onClick={() => window.location.assign(`${v1App}/flexibility`)}
          />
          <SideMenuItem
            visible={visibility.employeeGroups}
            icon={<PeopleIcon />}
            label="Employee Groups"
            onClick={() => window.location.assign(`${v1App}/groups`)}
          />
          <SideMenuItem
            visible={visibility.directory}
            icon={<ContactCalendarIcon />}
            label="Directory"
            onClick={() => window.location.assign(`${v1App}/directory`)}
          />
        </div>
      </div>
    </>
  );
};

export default SideMenu;
