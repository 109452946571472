import React from "react";
import Drawer from "src/components/scheduler/components/drawer/Drawer";
import WeeklyForecast from "./WeeklyForecast";

const WeeklyForecastContainer: React.FC = () => {
  return (
    <Drawer buttonText="Weekly Demand">
      {({ open }) => (open ? <WeeklyForecast /> : null)}
    </Drawer>
  );
};

export default WeeklyForecastContainer;
