import React, { useState, useCallback } from "react";
import { FormikHelpers } from "formik";
import { Moment } from "moment";
import { TShiftFormValues } from "../shift_form/types";
import { useStoreState, useStoreActions } from "src/store";
import { errorIsViolation } from "src/lib/violations";
import { AxiosError } from "axios";
import { TShiftViolationErrorData } from "src/api/api.types";
import { evaluateDraftWorkshift } from "src/api";
import ShiftForm from "../shift_form/ShiftForm";
import { prepareDraftShift } from "src/lib/drafts";

interface IProps {
  role: string;
  employeeId: string;
  start: Moment;
  end: Moment;
  handleClose: () => void;
}

const AddDraftShiftForm: React.FC<IProps> = (props) => {
  const { role, employeeId, start, end, handleClose } = props;
  const locationId = useStoreState((state) => state.location.locationId);
  const events = useStoreState((state) => state.events.events);
  const addDraftShift = useStoreActions((state) => state.draft.addDraftShift);
  const view = useStoreState((state) => state.scheduler.view);
  const employeeRecordsById = useStoreState(
    (state) => state.employees.employeeRecordsById
  );
  const [showViolations, setShowViolations] = useState(false);
  const [violationMessage, setViolationMessage] = useState("");

  const submit = useCallback(
    async (
      values: TShiftFormValues,
      helpers: FormikHelpers<TShiftFormValues>
    ) => {
      helpers.setSubmitting(true);
      const employee = employeeRecordsById[values.employeeId];
      try {
        // if we are currently showing violations,
        // it means that the submit button should
        // bypass validation
        const runValidation = !showViolations;

        if (runValidation) {
          const currentShift = {
            start_time: values.start.format("YYYY-MM-DD HH:mm:ss"),
            end_time: values.end.format("YYYY-MM-DD HH:mm:ss"),
          };
          const shifts = events
            .filter((e) => e.type === "draft_shift" || e.type === "shift")
            .filter((shift) => shift.resourceId === values.employeeId)
            .map((shift) => ({
              start_time: shift.start.format("YYYY-MM-DD HH:mm:ss"),
              end_time: shift.end.format("YYYY-MM-DD HH:mm:ss"),
            }));
          await evaluateDraftWorkshift({
            user_id: employee.userId ? +employee.userId : -1,
            employee_id: values.employeeId,
            location_id: locationId,
            current_shift: currentShift,
            time_interval: view,
            shifts: [currentShift, ...shifts],
          });
          setViolationMessage("");
        }
        const shift = prepareDraftShift({
          role: values.role,
          userId: employee.userId ? +employee.userId : 0,
          employeeId: employee.employeeId || null,
          userName: employee.name,
          start: values.start,
          end: values.end,
        });
        await addDraftShift({
          shift,
          employeeUid: employee.uid,
          violation: violationMessage,
        });

        handleClose();
      } catch (err) {
        if (errorIsViolation(err)) {
          setShowViolations(true);
          const error = err as AxiosError<TShiftViolationErrorData>;
          setViolationMessage(error.response?.data.meta.detail || "");
        }
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [
      showViolations,
      locationId,
      employeeRecordsById,
      view,
      events,
      handleClose,
      addDraftShift,
      violationMessage,
    ]
  );

  return (
    <ShiftForm
      role={role}
      employeeId={employeeId}
      start={start}
      end={end}
      handleSubmit={submit}
      violationMessage={violationMessage}
      setViolationMessage={setViolationMessage}
      setShowViolations={setShowViolations}
      showViolations={showViolations}
    />
  );
};

AddDraftShiftForm.defaultProps = {
  role: "",
  employeeId: "",
};

export default AddDraftShiftForm;
