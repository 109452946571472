import React, { useMemo } from "react";
import { useStoreState } from "src/store";
import styles from "./index.module.scss";
import classnames from "classnames";
import { Moment } from "moment";
import { getHourlyAvailabilityType } from "./lib";

export type AvailabilityType = "unavailable" | "available" | "open";

interface IProps {
  userId: number;
  time: Moment;
}

const HourlyAvailability: React.FC<IProps> = (props) => {
  const { userId, time } = props;
  const userAvailability = useStoreState(
    (state) => state.availability.userAvailability[userId]
  );

  const isoWeekday = useMemo(() => time.isoWeekday(), [time]);

  const availability = useMemo(
    () =>
      (userAvailability || []).filter(
        (record) => record.isoWeekday === isoWeekday
      ),
    [userAvailability, isoWeekday]
  );

  const type = useMemo(() => getHourlyAvailabilityType(availability, time), [
    availability,
    time,
  ]);

  if (type === "open") {
    return null;
  }

  const classes = classnames(styles.HourlyAvailability, {
    [styles.HourlyAvailability_Available]: type === "available",
    [styles.HourlyAvailability_Unavailable]: type === "unavailable",
  });

  return <div className={classes} />;
};

export default HourlyAvailability;
