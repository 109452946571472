import * as React from "react";
import { Route, Switch } from "react-router-dom";
import SchedulerView from "src/components/scheduler/SchedulerView";
import AuthenticatedContainer from "./AuthenticatedContainer";
import { BrowserRouter } from "react-router-dom";
import { hasValidToken } from "src/lib/token";
import AuthenticatedRoute from "./router/AuthenticatedRoute";

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <AuthenticatedRoute isAuthenticated={hasValidToken} path="*">
          <AuthenticatedContainer>
            <Switch>
              <Route exact path="*">
                <SchedulerView />
              </Route>
            </Switch>
          </AuthenticatedContainer>
        </AuthenticatedRoute>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
