import React, { useCallback } from "react";
import {
  TDecoratedEvents,
  TEmployeeRecord,
} from "src/components/scheduler/scheduler.types";
import { IColumn, IRow } from "../../../schedulerTable/types";
import { useStoreState } from "src/store";
import ControlledPopupHandler from "src/components/common/popup/ControlledPopupHandler";
import AddDraftShiftPopup from "../draft_shift/AddDraftShiftPopup";
import AddShiftPopup from "../event_popup/AddShiftPopup";
import AddOpenShiftPopup from "../event_popup/AddOpenShiftPopup";
import CellWrapper from "../../CellWrapper";
import AddDraftOpenShiftPopup from "../draft_shift/AddDraftOpenShiftPopup";
import { IPopupHandlerBag } from "src/components/common/popup/PopupHandler";
// import CellMenuPopup from "../../CellMenuPopup";

interface IProps {
  column: IColumn;
  row: IRow<TEmployeeRecord | null, TDecoratedEvents>;
  children: React.ReactNode;
}

const CellHandler: React.FC<IProps> = (props) => {
  const { column, row, children } = props;
  const draftStatus = useStoreState((state) => state.draft.draftStatus);

  const renderPopup = useCallback(
    ({ hide }: IPopupHandlerBag) => {
      const showDraft = draftStatus !== "published";
      const isOpenCell = row.id === "open";

      if (showDraft) {
        if (isOpenCell) {
          return (
            <AddDraftOpenShiftPopup
              time={column.time}
              role={row.data?.role || ""}
              close={hide}
            />
          );
        } else {
          return (
            <AddDraftShiftPopup
              time={column.time}
              role={row.data?.role || ""}
              id={row.id}
              close={hide}
            />
          );
        }
      } else {
        if (isOpenCell) {
          return (
            <AddOpenShiftPopup
              time={column.time}
              role={row.data?.role || ""}
              close={hide}
            />
          );
        } else {
          return (
            <AddShiftPopup
              time={column.time}
              role={row.data?.role || ""}
              id={row.id}
              close={hide}
            />
          );
        }
      }
    },
    [draftStatus, column, row]
  );

  const renderContent = useCallback(
    ({ show, visible }: IPopupHandlerBag) => {
      return (
        <CellWrapper onClick={show} active={visible}>
          {children}
        </CellWrapper>
      );
    },
    [children]
  );

  return (
    <ControlledPopupHandler
      renderPopup={renderPopup}
      fillHeight={true}
      placement="bottom"
    >
      {renderContent}
    </ControlledPopupHandler>
  );
};

export default CellHandler;
