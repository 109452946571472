import React from "react";
import styles from "./index.module.scss";

interface IProps {
  label: string;
  value: string;
}

const ListItem: React.FC<IProps> = (props) => {
  const { label, value } = props;

  return (
    <div className={styles.ListItem}>
      <div className={styles.ListItem_Label}>{label}</div>
      <div className={styles.ListItem_Value}>{value}</div>
    </div>
  );
};

export default ListItem;
