export type TDominosRoleType = "D" | "I" | "A" | "H" | "C" | "M" | "CE";
export type TDominosRoleName =
  | "Driver"
  | "Insider"
  | "Manager"
  | "Chef"
  | "Customer Experience Representative";

export type TDominosRoles = {
  [key in TDominosRoleType]: TDominosRoleName;
};

export const DOMINOS_ROLES: TDominosRoles = {
  D: "Driver",
  I: "Insider",
  A: "Manager",
  H: "Chef",
  C: "Customer Experience Representative",
  CE: "Customer Experience Representative",
  M: "Manager",
};
