import React, { useState } from "react";
import {
  TDecoratedShift,
  TShiftPopupAction,
} from "src/components/scheduler/scheduler.types";
import { PopupCard } from "src/components/common/popup";
import { CardHeader, CardBody } from "reactstrap";
import EventPopupHeader from "../event_popup/EventPopupHeader";
import AddShiftForm from "../shift_form/AddShiftForm";
import ShiftDetails from "./ShiftDetails";

interface IProps {
  event: TDecoratedShift;
  initialView: TShiftPopupAction;
  close: () => void;
}

const styles = {
  popup: {
    width: "500px",
  },
};

const getTitle = (view: TShiftPopupAction) => {
  switch (view) {
    case "read":
      return "Nearby Shift";
    case "clone":
      return "Clone Nearby Shift";
  }
};

const actions: TShiftPopupAction[] = ["clone", "read"];

// optimization so that this component only re-renders if
// relevant props have changed
const areEqual = (prev: IProps, next: IProps) =>
  prev.event === next.event && prev.close === next.close;

const ShiftPopup = React.memo((props) => {
  const { event, close, initialView } = props;
  const [view, setView] = useState<TShiftPopupAction>(initialView);

  return (
    <PopupCard style={styles.popup}>
      <CardHeader>
        <EventPopupHeader
          view={view}
          setView={setView}
          handleClose={close}
          title={getTitle(view)}
          availableActions={actions}
        />
      </CardHeader>
      <CardBody>
        {view === "read" && (
          <ShiftDetails
            start={event.start}
            end={event.end}
            role={event.data.role}
            assignee={event.data.user.name}
            location={event.data.user.location}
          />
        )}

        {view === "clone" && (
          <AddShiftForm
            role={event.data.role}
            employeeId={event.resourceId}
            start={event.start}
            end={event.end}
            handleClose={close}
          />
        )}
      </CardBody>
    </PopupCard>
  );
}, areEqual);

export default ShiftPopup;
