import { useMemo } from "react";
import { IRow } from "../types";
import { useStoreState } from "src/store";

const useRowHeight = (row: IRow) => {
  const eventHeight = useStoreState(
    (state) => state.scheduler.layout.eventHeight
  );
  const cellPaddingBottom = useStoreState(
    (state) => state.scheduler.layout.cellPaddingBottom
  );
  const cellPaddingTop = useStoreState(
    (state) => state.scheduler.layout.cellPaddingTop
  );
  const minRowHeight = useStoreState(
    (state) => state.scheduler.layout.minRowHeight
  );

  const height = useMemo(() => {
    const withPadding =
      cellPaddingBottom + cellPaddingTop + eventHeight * row.groupCount;
    return withPadding < minRowHeight ? minRowHeight : withPadding;
  }, [row, eventHeight, cellPaddingBottom, cellPaddingTop, minRowHeight]);

  return height;
};

export default useRowHeight;
