import React from 'react';
import styles from 'src/components/scheduler/index.module.scss';
import { TDecoratedTimeoff } from 'src/components/scheduler/scheduler.types';
import StaticEvent from 'src/components/scheduler/StaticEvent';
import BaseEventCard from 'src/components/scheduler/BaseEventCard';
import PrettyTimeRange from 'src/components/common/pretty_time_range/PrettyTimeRange';

interface IProps {
  compact?: boolean;
  event: TDecoratedTimeoff;
}

const TimeoffEvent: React.FC<IProps> = (props) => {
  const { event } = props;

  return (
    <StaticEvent event={event}>
      <BaseEventCard event={event} className={styles.EventContainer_Timeoff}>
        <PrettyTimeRange start={event.start} end={event.end} />
        <div>{event.data.type}</div>
      </BaseEventCard>
    </StaticEvent>
  );
};

export default TimeoffEvent;
