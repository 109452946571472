import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

const ResizeLeftIcon: React.FC<Props> = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g fill="#000" fillRule="evenodd">
      <rect x={13} y={5} width={2.25} height={14} rx={1.125} />
      <rect x={9} y={0.75} width={2.25} height={22.25} rx={1.125} />
    </g>
  </svg>
);

export default ResizeLeftIcon;
