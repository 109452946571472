import React, { useCallback, useState } from "react";
import { PopupCard } from "src/components/common/popup";
import { CardHeader, CardBody } from "reactstrap";
import PopupHeadIcons from "src/components/common/popup/PopupHeadIcons";
import IconButton from "src/components/common/icon-button/IconButton";
import CloseIcon from "src/components/common/icons/CloseIcon";
import BinIcon from "src/components/common/icons/BinIcon";
import { useStoreActions, useStoreState } from "src/store";
import { TDraftModel } from "src/api/api.types";
import StatefulButton from "src/components/common/stateful-button";
import { publishDraft } from "src/api";

interface IProps {
  draft: TDraftModel;
  handleClose: () => void;
}

const styles = {
  popup: {
    width: "350px",
  },
};

const DraftManagerPopup: React.FC<IProps> = (props) => {
  const { handleClose, draft } = props;
  const [publishing, setPublishing] = useState(false);
  const deleteDraft = useStoreActions((state) => state.draft.deleteDraft);
  const fetchWorkshifts = useStoreActions(
    (state) => state.shifts.fetchWorkshifts
  );
  const fetchAvailability = useStoreActions(
    (state) => state.availability.fetchAvailability
  );
  const getDraft = useStoreActions((state) => state.draft.getDraft);
  const currentWeekRange = useStoreState(
    (state) => state.scheduler.currentWeekRange
  );

  const handleDelete = useCallback(async () => {
    await deleteDraft(draft.id);
    handleClose();
  }, [draft.id, deleteDraft, handleClose]);

  const handlePublish = useCallback(async () => {
    setPublishing(true);
    try {
      await publishDraft(draft.id);
      handleClose();
      fetchWorkshifts();
      fetchAvailability();
      getDraft();
    } catch (err) {
      console.error(err);
    } finally {
      setPublishing(false);
    }
  }, [
    draft.id,
    handleClose,
    fetchWorkshifts,
    fetchAvailability,
    getDraft,
    setPublishing,
  ]);

  return (
    <PopupCard style={styles.popup} className="pb-0">
      <CardHeader>
        <h5>
          {currentWeekRange.start.format("MM/DD")} &ndash;{" "}
          {currentWeekRange.end.format("MM/DD")} Draft
        </h5>
        <PopupHeadIcons>
          <IconButton
            tooltip="Delete draft"
            onClick={handleDelete}
            id="DraftPopupDelete"
          >
            <BinIcon width={16} />
          </IconButton>
          <IconButton
            tooltip="Close"
            onClick={handleClose}
            id="DraftPopupClose"
          >
            <CloseIcon width={16} />
          </IconButton>
        </PopupHeadIcons>
      </CardHeader>
      <CardBody>
        <p>
          Once published, your employees will be notified of all scheduled
          shifts.
        </p>
        <StatefulButton
          className="float-right"
          loading={publishing}
          onClick={handlePublish}
        >
          Publish
        </StatefulButton>
      </CardBody>
    </PopupCard>
  );
};

export default DraftManagerPopup;
