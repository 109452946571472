import { Thunk, thunk, computed, Computed } from "easy-peasy";
import { TTemplate } from "src/api/api.types";
import { getLocationTemplates } from "src/api";
import { getSecondaryTemplateValues } from "src/lib/templates";
import { dataModel, DataModel } from "src/lib/dataModel";
import { ROLE_COLORS } from "src/lib/color";

interface TemplatesModel {
  templates: DataModel<TTemplate[]>;
  fetchTemplates: Thunk<TemplatesModel, string>;
  templateRoles: Computed<TemplatesModel, string[]>;
  roleColors: Computed<TemplatesModel, { [key: string]: string }>;
}

const templatesStore: TemplatesModel = {
  templates: dataModel([]),

  fetchTemplates: thunk(async (actions, locationId) => {
    const { setLoading, setError, setData } = actions.templates;
    setLoading(true);
    setError(null);
    try {
      const res = await getLocationTemplates(locationId);
      setData(res.data.response.templates);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }),

  templateRoles: computed([(state) => state.templates.data], (templates) =>
    getSecondaryTemplateValues(templates, "shifts", "job_role")
  ),

  roleColors: computed([(state) => state.templateRoles], (roles) => {
    let mapped: { [key: string]: string } = {};
    const colors = ROLE_COLORS;
    roles.forEach((role, i) => {
      mapped[role] = i < colors.length ? colors[i] : colors[colors.length % i];
    });
    return mapped;
  }),
};

export default templatesStore;
