import React, { useState, useMemo, useCallback } from "react";
import { ListGroup } from "reactstrap";
import { useStoreState } from "src/store";
import styles from "../index.module.scss";
import useOutsideClickHander from "src/components/scheduler/hooks/useOutsideClickHandler";
import classnames from "classnames";
import useEscapeClickHandler from "src/components/scheduler/hooks/useEscapeClickHandler";
import DropdownListItem from "src/components/common/dropdown-list/DropdownListItem";

interface IProps {
  handleSelect: (id: string) => () => void;
}

const EmployeeSelect: React.FC<IProps> = (props) => {
  const { handleSelect } = props;
  const ref = React.useRef<HTMLDivElement>(null);

  const [search, setSearch] = useState("");
  const [resultsVisible, setResultsVisible] = useState(false);

  const employees = useStoreState((state) => state.employees.employeeRecords);

  const hideResults = useCallback(() => {
    if (resultsVisible) setResultsVisible(false);
  }, [resultsVisible, setResultsVisible]);

  const showResults = useCallback(() => {
    setResultsVisible(true);
  }, [setResultsVisible]);

  useEscapeClickHandler(hideResults);
  useOutsideClickHander(ref, hideResults, resultsVisible);

  const filteredEmployees = useMemo(() => {
    if (search.length === 0) {
      return employees;
    }
    return employees.filter((emp) => {
      const searchFields = [emp.role, ...emp.name.split(/[ ,]+/)];
      const show = searchFields.some((field) => {
        // check if any of the search fields
        // begin with the search value provided
        return field && field.toLowerCase().startsWith(search.toLowerCase());
      });
      return show;
    });
  }, [employees, search]);

  const searchClasses = classnames(styles.ShiftFormInput, {
    [styles.ShiftFormInput_Focused]: resultsVisible,
  });

  return (
    <div ref={ref}>
      <input
        onFocus={showResults}
        type="text"
        className={searchClasses}
        value={search}
        placeholder="Search employees"
        onChange={(e) => setSearch(e.target.value)}
      />
      {resultsVisible && (
        <div className={styles.ShiftDropdown}>
          <ListGroup flush>
            {filteredEmployees.map((employee) => (
              <DropdownListItem
                key={employee.uid}
                onClick={handleSelect(employee.uid)}
              >
                <div className="w-100 d-flex justify-content-between">
                  <span>{employee.name}</span>
                  <span className="text-black-50">{employee.role}</span>
                </div>
              </DropdownListItem>
            ))}
          </ListGroup>
        </div>
      )}
    </div>
  );
};

export default EmployeeSelect;
