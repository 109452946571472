import React from "react";
import { ListGroup, CardBody } from "reactstrap";
import { PopupCard } from "src/components/common/popup";
import { useStoreActions, useStoreState } from "src/store";
import DropdownListItem from "src/components/common/dropdown-list/DropdownListItem";
import { TSortType } from "../../scheduler.types";

interface IProps {
  handleClose: () => void;
}

const styles = {
  popup: {
    width: "175px",
    maxHeight: "250px",
  },
};

const SortPopup: React.FC<IProps> = (props) => {
  const sortBy = useStoreState((state) => state.sort.sortBy);
  const setSortBy = useStoreActions((actions) => actions.sort.setSortBy);

  const selectSortBy = (type: TSortType) => {
    setSortBy(type);
    props.handleClose();
  };

  return (
    <PopupCard style={styles.popup}>
      <CardBody className="px-0 py-2">
        <ListGroup flush>
          <DropdownListItem
            active={sortBy === "name"}
            onClick={() => selectSortBy("name")}
          >
            Name
          </DropdownListItem>
          <DropdownListItem
            active={sortBy === "role"}
            onClick={() => selectSortBy("role")}
          >
            Role
          </DropdownListItem>
        </ListGroup>
      </CardBody>
    </PopupCard>
  );
};

export default SortPopup;
