import React, { useEffect, useCallback } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { useStoreState } from "src/store";

interface IProps {
  value: string;
  setFieldValue: (id: "role", value: string) => void;
}

const FIELD_ID = "role";

const RoleField: React.FC<IProps> = (props) => {
  const roles = useStoreState((state) => state.templates.templateRoles);
  const { value, setFieldValue } = props;

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue(FIELD_ID, e.currentTarget.value);
    },
    [setFieldValue]
  );

  useEffect(() => {
    // if the template roles array doesn't contain the value
    // we should default to the first role in the array.
    if (!roles.includes(value) && roles.length > 0) {
      setFieldValue(FIELD_ID, roles[0]);
    }
  }, [roles, setFieldValue, value]);

  return (
    <FormGroup>
      <Label for={FIELD_ID}>Shift Role</Label>
      <Input
        type="select"
        onChange={onChange}
        value={value}
        name={FIELD_ID}
        id={FIELD_ID}
      >
        {roles.map((role) => (
          <option key={role}>{role}</option>
        ))}
      </Input>
    </FormGroup>
  );
};

export default RoleField;
