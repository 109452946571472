import { Action, action } from 'easy-peasy';
import moment, { Moment } from 'moment';
import { Computed, computed } from 'easy-peasy';
import { StoreModel } from './index';
import {
  TMomentRangeHandler,
  TMomentRange,
  TViewType,
  adjustForwardOneWeek,
  adjustBackwardOneWeek,
  adjustForwardOneDay,
  adjustBackwardOneDay,
  adjustForwardThreeDays,
  adjustBackwardThreeDays,
  getOffsetWeekRange,
  VIEW_LABELS,
} from 'src/lib/rangeHandler';
import {
  TComputeEventPosition,
  IRow,
  IColumn,
  IDecoratedEvent,
} from 'src/components/schedulerTable/types';
import {
  incrementOneHour,
  incrementOneDay,
} from 'src/components/scheduler/lib/columnIncrementers';
import {
  computeTrueEventPosition,
  computeDayBlockedEventPosition,
} from 'src/components/scheduler/lib/eventPosition';
import generateColumns from 'src/components/schedulerTable/utils/generateColumns';

interface SchedulerModel {
  initialized: boolean;
  today: Moment;
  start: Moment;
  end: Moment;
  view: TViewType;
  viewLabel: Computed<SchedulerModel, string>;
  getForwardRange: TMomentRangeHandler;
  getBackwardRange: TMomentRangeHandler;
  layout: {
    asideWidth: number;
    minColWidth: number;
    minRowHeight: number;
    eventHeight: number;
    headHeight: number;
    cellPaddingBottom: number;
    cellPaddingTop: number;
  };
  columnIncrementer: (prev: Moment) => Moment;
  computeEventPosition: TComputeEventPosition;
  setRange: Action<SchedulerModel, TMomentRange>;
  range: Computed<SchedulerModel, TMomentRange>;
  setInitialized: Action<SchedulerModel, boolean>;
  setToDayView: Action<SchedulerModel, Moment>;
  setToWeekView: Action<SchedulerModel>;
  setToThreeDayView: Action<SchedulerModel>;
  weekStartOffset: Computed<SchedulerModel, number, StoreModel>;
  currentWeekRange: Computed<SchedulerModel, TMomentRange>;
  tableWidth: number;
  setTableWidth: Action<SchedulerModel, number>;
  setTableMainWidth: Action<SchedulerModel, number>;
  tableMainWidth: number;
  tableBodyTop: number;
  setTableBodyTop: Action<SchedulerModel, number>;
  columns: Computed<SchedulerModel, IColumn[]>;
  rows: IRow[];
  computeStartDragHandlePosition: Computed<
    SchedulerModel,
    (event: IDecoratedEvent, translation: number) => Moment
  >;
  computeEndDragHandlePosition: Computed<
    SchedulerModel,
    (event: IDecoratedEvent, translation: number) => Moment
  >;
}

const schedulerStore: SchedulerModel = {
  initialized: false,
  today: moment(),
  start: moment().startOf('day').subtract(7, 'days'),
  end: moment().startOf('day'),
  view: 'week',
  rows: [],
  getForwardRange: adjustForwardOneWeek,
  getBackwardRange: adjustBackwardOneWeek,
  tableWidth: 0,
  tableBodyTop: 0,
  tableMainWidth: 0,
  layout: {
    asideWidth: 200,
    minColWidth: 150,
    minRowHeight: 80,
    eventHeight: 50,
    headHeight: 40,
    cellPaddingBottom: 20,
    cellPaddingTop: 20,
  },
  columnIncrementer: incrementOneDay,
  computeEventPosition: computeDayBlockedEventPosition,
  setInitialized: action((state, isInitialized) => {
    state.initialized = isInitialized;
  }),
  setRange: action((state, { start, end }) => {
    state.start = start;
    state.end = end;
  }),
  setToDayView: action((state, day) => {
    state.view = 'day';
    state.start = day.clone().startOf('day');
    state.end = day.clone().endOf('day');
    state.getForwardRange = adjustForwardOneDay;
    state.getBackwardRange = adjustBackwardOneDay;
    state.layout = {
      asideWidth: 200,
      minColWidth: 55,
      minRowHeight: 80,
      eventHeight: 55,
      headHeight: 40,
      cellPaddingBottom: 20,
      cellPaddingTop: 20,
    };
    state.columnIncrementer = incrementOneHour;
    state.computeEventPosition = computeTrueEventPosition;
  }),
  setToWeekView: action((state) => {
    const { weekStartOffset, start } = state;
    state.view = 'week';
    const range = getOffsetWeekRange(start, weekStartOffset);
    state.start = range.start;
    state.end = range.end;
    state.getForwardRange = adjustForwardOneWeek;
    state.getBackwardRange = adjustBackwardOneWeek;
    state.layout = {
      asideWidth: 200,
      minColWidth: 150,
      minRowHeight: 80,
      eventHeight: 50,
      headHeight: 40,
      cellPaddingBottom: 20,
      cellPaddingTop: 20,
    };
    state.columnIncrementer = incrementOneDay;
    state.computeEventPosition = computeDayBlockedEventPosition;
  }),
  setToThreeDayView: action((state) => {
    const { start } = state;
    state.view = 'three_day';
    state.start = state.start.startOf('day');
    state.end = start.clone().endOf('day').add(2, 'day');
    state.getForwardRange = adjustForwardThreeDays;
    state.getBackwardRange = adjustBackwardThreeDays;
    state.layout = {
      asideWidth: 200,
      minColWidth: 200,
      minRowHeight: 80,
      eventHeight: 55,
      headHeight: 40,
      cellPaddingBottom: 20,
      cellPaddingTop: 20,
    };
    state.columnIncrementer = incrementOneDay;
    state.computeEventPosition = computeTrueEventPosition;
  }),
  range: computed(
    [(state) => state.start, (state) => state.end],
    (start, end) => ({
      start,
      end,
    })
  ),
  weekStartOffset: computed(
    [(_, store) => store.location.settings.data],
    (settings) => {
      const weekStartSetting = settings.find(
        (setting) => setting[0] === 'WEEK_START_DAY'
      );
      return weekStartSetting ? +weekStartSetting[1] : 1;
    }
  ),
  currentWeekRange: computed(
    [(state) => state.start, (state) => state.weekStartOffset],
    getOffsetWeekRange
  ),
  viewLabel: computed([(store) => store.view], (view) => VIEW_LABELS[view]),
  columns: computed(
    [
      (store) => store.start,
      (store) => store.end,
      (store) => store.columnIncrementer,
    ],
    (start, end, columnIncrementer) =>
      generateColumns(start, end, columnIncrementer)
  ),
  setTableBodyTop: action((state, top) => {
    state.tableBodyTop = top;
  }),
  setTableWidth: action((state, width) => {
    state.tableWidth = width;
  }),
  setTableMainWidth: action((state, width) => {
    state.tableMainWidth = width;
  }),
  computeStartDragHandlePosition: computed(
    [(store) => store.range, (store) => store.tableMainWidth],
    (range, mainWidth) => {
      return (event, translation) => {
        const rangeEndInMs = range.end.valueOf();
        const rangeStartInMs = range.start.valueOf();
        const eventStartInMs = event.posStart.valueOf();
        const msRange = rangeEndInMs - rangeStartInMs;
        const eventMsOffset = eventStartInMs - rangeStartInMs;
        const eventPxOffset = (eventMsOffset / msRange) * mainWidth;
        const translatedPxOffset = eventPxOffset + translation;
        const percentPxOffset = translatedPxOffset / mainWidth;
        const translatedMsOffset = Math.trunc(
          rangeStartInMs + percentPxOffset * msRange
        );
        const translatedDate = moment(translatedMsOffset);
        return translatedDate;
      };
    }
  ),
  computeEndDragHandlePosition: computed(
    [(store) => store.range, (store) => store.tableMainWidth],
    (range, mainWidth) => {
      return (event, translation) => {
        const rangeEndInMs = range.end.valueOf();
        const rangeStartInMs = range.start.valueOf();
        const eventEndInMs = event.posEnd.valueOf();
        const msRange = rangeEndInMs - rangeStartInMs;
        const eventMsOffset = eventEndInMs - rangeStartInMs;
        const eventPxOffset = (eventMsOffset / msRange) * mainWidth;
        const translatedPxOffset = eventPxOffset + translation;
        const percentPxOffset = translatedPxOffset / mainWidth;
        const translatedMsOffset = Math.trunc(
          rangeStartInMs + percentPxOffset * msRange
        );
        const translatedDate = moment(translatedMsOffset);
        return translatedDate;
      };
    }
  ),
};

export default schedulerStore;
