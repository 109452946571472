import { Action, action } from "easy-peasy";
import { TSortType } from "src/components/scheduler/scheduler.types";

interface SortModel {
  sortBy: TSortType;
  setSortBy: Action<SortModel, TSortType>;
}

const sortStore: SortModel = {
  sortBy: "name",
  setSortBy: action((state, type) => {
    state.sortBy = type;
  }),
};

export default sortStore;
