import { api, nodeApi } from "./axios";
import { AxiosPromise, AxiosRequestConfig } from "axios";
import {
  getUserIdFromTokenCookie,
  getUserIdFromTokenString,
} from "src/lib/token";
import * as Types from "./api.types";
import queryString from "query-string";

export const getOrganizations = (
  token: string
): AxiosPromise<Types.IGetOrganizationsResponse> =>
  api.get(`/p1/organizations`, {
    headers: { Authorization: `Bearer ${token}` },
  });

/**
 * Sends a text pin to the phone number provided
 */
export const postTextCode = (phone: string) =>
  api.post("/v2/login/get-code", { phone });

/**
 * Attempts to validate the text code sent from `postTextCode`
 */
export const validateTextCode = (phone: string, code: string) =>
  api.post<Types.IValidateTextCodeResponse>("/v2/login/validate-code", {
    phone,
    code,
  });

/**
 * Retrieves a user's profile data
 * @param token the JWT token. Passed here because we fetch profile data before committing the
 * token to a browser cookie and we can't rely on it being available there.
 */
export const getUserDetails = (token?: string) =>
  api.get<Types.IGetUserDetailsResponse>(
    `/v2/profiles/${
      token ? getUserIdFromTokenString(token) : getUserIdFromTokenCookie()
    }`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

export const getUserLocations = () =>
  api.get<Types.TGetUserLocationsResponse>(
    `v1/users/${getUserIdFromTokenCookie()}/locations`
  );

export const getLocationDetails = (id: string) =>
  api.get<Types.TGetLocationDetailsResponse>(`v1/locations/${id}/info`);

export const getLocationDirectory = (
  id: string,
  config: AxiosRequestConfig = {}
) => api.get<Types.TGetLocationDirectoryResponse>(`v1/locations/${id}`, config);

export const getBridgeDirectory = (
  id: string,
  config: AxiosRequestConfig = {}
) => {
  return nodeApi.get<Types.TBridgeEmployee[]>(
    `locations/${id}/bridge_directory`,
    config
  );
};

export const getUserRolesAtLocation = (id: string) =>
  nodeApi.get<Types.TGetUserRolesAtLocationResponse>(
    `schedulesData/roles/location/${id}`
  );

export const getOpenShifts = (id: string, config: AxiosRequestConfig = {}) =>
  api.get<Types.TGetOpenShiftsResponse>(
    `v2/shifts?location_id=${id}&limit=1000`,
    config
  );

export const getDefaultUserRoleAtLocation = (id: string) =>
  nodeApi.get<Types.TGetDefaultUserRoleAtLocationResponse>(
    `schedulesData/roles/location/${id}/default`
  );

export const getLocationTemplates = (id: string) =>
  api.get<Types.TGetLocationTemplates>(
    `v1/templates?location_id=${id}&user_id=${getUserIdFromTokenCookie()}&from=templates`
  );

export const getWorkshift = (id: string) =>
  api.get<Types.TGetWorkshiftResponse>(`v2/workshifts/${id}`);

export const getWorkshifts = (
  params: Types.TWorkshiftsRequest,
  config: AxiosRequestConfig = {}
) =>
  api.get<Types.TGetWorkshiftsResponse>(
    `v2/workshifts?location_id=${params.locationId}&start_date=${params.startDate}&end_date=${params.endDate}`,
    config
  );

export const validateDateRange = (
  params: Types.TValidateDateRangeRequest,
  config: AxiosRequestConfig = {}
) =>
  api.get<Types.TValidateDateRangeResponse>(
    `v2/workshift_drafts/validate_date_range?location_id=${params.locationId}&start_date=${params.startDate}&end_date=${params.endDate}`,
    config
  );

export const getDraft = (
  params: Types.TGetDraftRequest,
  config: AxiosRequestConfig = {}
) =>
  api.get<Types.TGetDraftResponse>(
    `v2/workshift_drafts?location_id=${params.locationId}&start_date=${params.startDate}&end_date=${params.endDate}`,
    config
  );

export const addDraft = (params: Types.TAddDraftRequest) => {
  const form = new FormData();
  form.append("user_id", `${params.user_id}`);
  form.append("location_id", `${params.location_id}`);
  form.append("start_date", params.start_date);
  form.append("end_date", params.end_date);
  form.append("shifts", params.shifts);

  return api.post<FormData, Types.TAddDraftResponse>(
    `v2/workshift_drafts`,
    form
  );
};

export const getGroups = (locationId: string) =>
  api.get<Types.TGetGroupsResponse>(`v1/locations/${locationId}/groups`);

export const editDraft = (draftId: number, params: Types.TEditDraftRequest) => {
  const form = new FormData();
  form.append("user_id", `${params.user_id}`);
  form.append("shifts", params.shifts);
  return api.put<FormData>(`v2/workshift_drafts/${draftId}`, form);
};

export const deleteDraft = (draftId: number) =>
  api.delete(
    `v2/workshift_drafts/${draftId}?user_id=${getUserIdFromTokenCookie()}`
  );

export const publishDraft = (draftId: number) => {
  const form = new FormData();
  form.append("user_id", getUserIdFromTokenCookie());
  return api.post(`v2/workshift_drafts/${draftId}/publish`, form);
};

export const deleteOpenShift = (shiftId: string) =>
  api.delete(`v2/shifts/${shiftId}`);

export const getShiftCounts = (id: string, config: AxiosRequestConfig = {}) =>
  nodeApi.get<Types.TGetShiftCountsResponse>(
    `/shiftsData/shift_counts/${id}`,
    config
  );

export const getNearbyLocations = (locationId: string) =>
  api.get<Types.TGetNearbyLocationsResponse>(
    `v2/locations/${locationId}/nearbylocations`
  );

export const getNearbyLocationsDirectory = (locationIds: string[]) => {
  const ids = locationIds.join("|");
  return nodeApi.get<{ response: Types.TNearbyEmployee[] }>(
    `users/employeesForLocations/locationIds/${ids}`
  );
};

export const getAvailabilityData = (
  params: Types.TAvailabilityRequest,
  config: AxiosRequestConfig = {}
) =>
  nodeApi.get<Types.IGetAvailabilityDataResponse>(
    `availabilityData/location/${params.locationId}?startDate=${params.startDate}&endDate=${params.endDate}`,
    config
  );

export const getAutoSchedulerDemand = (
  params: Types.TAutoschedulerDemandRequest
) =>
  api.post<
    Types.TAutoschedulerDemandRequest,
    Types.TAutoschedulerDemandResponse
  >("/as/get_demand", params);

export const getNodeAutoSchedulerDemand = (
  params: Types.TNodeAutoschedulerDemandRequest
) =>
  nodeApi.post<
    Types.TNodeAutoschedulerDemandRequest,
    Types.TNodeAutoschedulerDemandResponse
  >("schedulesData/auto-scheduler-demand", params);

export const getAutoScheduler = (params: Types.TAutoschedulerParams) =>
  api.post<Types.TAutoschedulerParams, Types.TAutoschedulerResponse>(
    "/as/generate_schedule",
    params
  );

export const getNodeAutoScheduler = (params: Types.TNodeAutoschedulerParams) =>
  nodeApi.post<
    Types.TNodeAutoschedulerParams,
    Types.TNodeAutoschedulerResponse
  >("schedulesData/auto-scheduler", params);

export const getForecastData = (params: Types.TGetForecastDataRequest) =>
  nodeApi.post<Types.TGetForecastDataRequest, Types.TGetForecastDataResponse>(
    "schedulesData/calendar/forecasts",
    params
  );

export const addWorkshift = (params: Types.TAddWorkshiftParams) => {
  const form = new FormData();
  form.append("user_id", `${params.user_id}`);
  form.append("owner", `${params.owner}`);
  form.append("location_id", params.location_id);
  form.append("role", params.role);
  form.append("start_time", params.start_time);
  form.append("end_time", params.end_time);
  form.append("skip_violation_check", `${params.skip_violation_check}`);

  return api.post<FormData, Types.TAddWorkshiftResponse>("v2/workshifts", form);
};

export const editWorkshift = (
  id: string,
  params: Types.TEditWorkshiftParams
) => {
  const form = new FormData();
  form.append("owner", `${params.owner}`);
  form.append("role", params.role);
  form.append("start_time", params.start_time);
  form.append("end_time", params.end_time);
  form.append("skip_violation_check", `${params.skip_violation_check}`);

  return api.put<FormData, Types.TEditWorkshiftResponse>(
    `v2/workshifts/${id}`,
    form
  );
};

export const deleteWorkshift = (id: string, runValidation: boolean = true) =>
  api.delete(
    `v2/workshifts/${id}${runValidation ? "" : "?skip_violation_check=1"}`
  );

export const evaluateDraftWorkshift = (
  params: Types.TEvaluateDraftWorkshiftRequest
) => api.post("v2/violation_engine/draft_mode/evaluate_work_shifts", params);

export const editCustomWorkshift = (
  id: string,
  params: Types.TCustomWorkshiftRequest
) => nodeApi.put(`schedulesData/workshifts-custom/${id}`, params);

export const addCustomWorkshift = (params: Types.TCustomWorkshiftRequest) =>
  nodeApi.post<
    Types.TCustomWorkshiftRequest,
    Types.TAddCustomWorkshiftResponse
  >(`schedulesData/workshifts-custom`, params);

export const validateAndPostShift = (
  params: Types.TValidateAndPostShiftParams
) => {
  const stringified = queryString.stringify(params, {
    arrayFormat: "bracket",
  });
  return api.post<any, Types.TValidateAndPostShiftResponse>(
    `v2/shifts?${stringified}`
  );
};

export const validateAndUpdateShift = (
  id: string,
  params: Types.TValidateAndUpdateShiftParams
) => {
  const stringified = queryString.stringify(params, {
    arrayFormat: "bracket",
  });
  return api.put(`v2/shifts/${id}?${stringified}`);
};
