import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

const DuplicateIcon: React.FC<Props> = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g fill="#000" fillRule="nonzero">
      <path d="M22 0H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zm-2.75 11a1 1 0 01-1 1h-2.5a.25.25 0 00-.25.25v2.5a1 1 0 01-1 1H13a1 1 0 01-1-1v-2.5a.25.25 0 00-.25-.25h-2.5a1 1 0 01-1-1V9.5a1 1 0 011-1h2.5a.25.25 0 00.25-.25v-2.5a1 1 0 011-1h1.5a1 1 0 011 1v2.5c0 .138.112.25.25.25h2.5a1 1 0 011 1V11z" />
      <path d="M19.5 22h-17a.5.5 0 01-.5-.5v-17a1 1 0 10-2 0V22a2 2 0 002 2h17.5a1 1 0 000-2z" />
    </g>
  </svg>
);

export default DuplicateIcon;
