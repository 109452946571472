import { Thunk, thunk } from "easy-peasy";
import {
  getDefaultUserRoleAtLocation,
  getUserRolesAtLocation,
  getGroups,
} from "src/api";
import { TDirectoryRole, TGroup } from "src/api/api.types";
import { DataModel, dataModel } from "src/lib/dataModel";

interface RolesModel {
  roles: DataModel<TDirectoryRole[]>;
  fetchRoles: Thunk<RolesModel, string>;

  defaultRole: DataModel<string | null>;
  fetchDefaultRole: Thunk<RolesModel, string>;

  groups: DataModel<TGroup[]>;
  fetchGroups: Thunk<RolesModel, string>;
}

const rolesStore: RolesModel = {
  ///////////////////////
  // ROLES
  ///////////////////////
  roles: dataModel([]),

  fetchRoles: thunk(async (actions, locationId) => {
    const { setLoading, setError, setData } = actions.roles;
    setLoading(true);
    setError(null);
    try {
      const res = await getUserRolesAtLocation(locationId);
      setData(res.data.response);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }),

  ///////////////////////
  // DEFAULT ROLE
  ///////////////////////
  defaultRole: dataModel(null),

  fetchDefaultRole: thunk(async (actions, locationId) => {
    const { setLoading, setError, setData } = actions.defaultRole;
    setLoading(true);
    setError(null);
    try {
      const res = await getDefaultUserRoleAtLocation(locationId);
      setData(res.data.response.role);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }),

  ///////////////////////
  // Groups
  ///////////////////////
  groups: dataModel([]),

  fetchGroups: thunk(async (actions, locationId) => {
    const { setLoading, setError, setData } = actions.groups;
    setLoading(true);
    setError(null);
    try {
      const res = await getGroups(locationId);
      setData(res.data.response.groups);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }),
};

export default rolesStore;
