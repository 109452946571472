import { Thunk, thunk, computed, Computed } from 'easy-peasy';
import { TTimeoff } from 'src/api/api.types';
import { dataModel, DataModel } from 'src/lib/dataModel';
import { getHistoricalRTO, getRTO } from 'src/api/grpc';
import { StoreModel } from '.';
import { eventIsWithinRange } from 'src/lib/rangeHandler';

interface TimeoffsModel {
  historicalTimeoffs: DataModel<TTimeoff[]>;
  fetchHistoricalTimeoffs: Thunk<TimeoffsModel, string>;

  timeoffs: DataModel<TTimeoff[]>;
  fetchTimeoffs: Thunk<TimeoffsModel, string>;

  visibleTimeoffs: Computed<TimeoffsModel, TTimeoff[], StoreModel>;
}

const timeoffsStore: TimeoffsModel = {
  historicalTimeoffs: dataModel([]),

  fetchHistoricalTimeoffs: thunk(async (actions, locationId) => {
    const { setLoading, setError, setData } = actions.historicalTimeoffs;
    setLoading(true);
    setError(null);
    try {
      const res = await getHistoricalRTO(locationId);
      let data: TTimeoff[] = [];
      res.on('data', (res) => {
        data.push(res.toObject() as any);
      });
      res.on('end', () => {
        setData(data);
      });
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }),

  timeoffs: dataModel([]),

  fetchTimeoffs: thunk(async (actions, locationId) => {
    const { setLoading, setError, setData } = actions.timeoffs;
    setLoading(true);
    setError(null);
    try {
      const res = await getRTO(locationId);
      let data: TTimeoff[] = [];
      res.on('data', (res) => {
        data.push(res.toObject() as any);
      });
      res.on('end', () => {
        setData(data);
      });
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }),

  visibleTimeoffs: computed(
    [
      (state) => state.historicalTimeoffs.data,
      (state) => state.timeoffs.data,
      (_, store) => store.scheduler.start,
      (_, store) => store.scheduler.end,
    ],
    (historicalTimeoffs, timeoffs, start, end) => {
      const visibleItems = [...historicalTimeoffs, ...timeoffs].filter(
        (t) => t.approval !== 3
      );
      const filteredToView = visibleItems.filter((t) =>
        eventIsWithinRange(
          {
            start: +t.timeStart.seconds,
            end: +t.timeEnd.seconds,
          },
          {
            start: start.unix(),
            end: end.unix(),
          }
        )
      );

      return filteredToView;
    }
  ),
};

export default timeoffsStore;
