import { Thunk, thunk } from "easy-peasy";
import { getUserLocations } from "src/api";
import { TLocation } from "src/api/api.types";
import { DataModel, dataModel } from "src/lib/dataModel";

interface UserLocationsModel {
  locations: DataModel<TLocation[]>;
  fetchUserLocations: Thunk<UserLocationsModel>;
}

const userLocationsStore: UserLocationsModel = {
  ///////////////////////
  // USER LOCATIONS
  ///////////////////////

  locations: dataModel([]),

  fetchUserLocations: thunk(async (actions) => {
    const { setLoading, setError, setData } = actions.locations;

    setLoading(true);
    setError(null);
    try {
      const res = await getUserLocations();
      setData(res.data.response.locations);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(true);
    }
  }),
};

export default userLocationsStore;
