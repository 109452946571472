import React, { useMemo } from "react";
import { TShiftPopupAction } from "src/components/scheduler/scheduler.types";
import PopupHeadIcons from "src/components/common/popup/PopupHeadIcons";
import IconButton from "src/components/common/icon-button/IconButton";
import CloseIcon from "src/components/common/icons/CloseIcon";
import BinIcon from "src/components/common/icons/BinIcon";
import EditIcon from "src/components/common/icons/EditIcon";
import { ArrowLeftIcon, DuplicateIcon } from "src/components/common/icons";

interface IProps {
  view: TShiftPopupAction;
  title?: string;
  availableActions: TShiftPopupAction[];
  setView: React.Dispatch<React.SetStateAction<TShiftPopupAction>>;
  handleClose: () => void;
}

const EventPopupHeader: React.FC<IProps> = (props) => {
  const { view, title, setView, handleClose, availableActions } = props;

  const features = useMemo(
    () => ({
      edit: availableActions.includes("edit"),
      delete: availableActions.includes("delete"),
      clone: availableActions.includes("clone"),
      read: availableActions.includes("read"),
    }),
    [availableActions]
  );

  return (
    <>
      {features.read && view !== "read" && (
        <IconButton
          tooltip="Back"
          onClick={() => setView("read")}
          id="ShiftBackPopup"
        >
          <ArrowLeftIcon width={16} />
        </IconButton>
      )}
      <h5>{title}</h5>
      <PopupHeadIcons>
        {features.edit && (
          <IconButton
            tooltip="Edit"
            onClick={() => setView("edit")}
            id="EditShiftPopup"
          >
            <EditIcon width={16} />
          </IconButton>
        )}
        {features.clone && (
          <IconButton
            tooltip="Clone"
            onClick={() => setView("clone")}
            id="CloneShiftPopup"
          >
            <DuplicateIcon width={16} />
          </IconButton>
        )}
        {features.delete && (
          <IconButton
            tooltip="Delete"
            onClick={() => setView("delete")}
            id="DeleteShiftPopup"
          >
            <BinIcon width={16} />
          </IconButton>
        )}

        <IconButton tooltip="Close" onClick={handleClose} id="ShiftPopupClose">
          <CloseIcon width={16} />
        </IconButton>
      </PopupHeadIcons>
    </>
  );
};

export default EventPopupHeader;
