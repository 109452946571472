import { TStateEmployeeResource } from "src/components/scheduler/scheduler.types";

export const sortEmployeesByName = (
  employees: TStateEmployeeResource[]
): TStateEmployeeResource[] => {
  return employees.sort((a, b) =>
    a.data.name > b.data.name ? 1 : b.data.name > a.data.name ? -1 : 0
  );
};

export const sortEmployeesByRole = (
  employees: TStateEmployeeResource[]
): TStateEmployeeResource[] => {
  let roleMap: { [key: string]: TStateEmployeeResource[] } = {};

  employees.forEach((employee) => {
    const { role } = employee.data;
    if (role) {
      roleMap[role] = roleMap[role] ? [...roleMap[role], employee] : [employee];
    } else {
      roleMap.none = roleMap.none ? [...roleMap.none, employee] : [employee];
    }
  });

  let flattenedSortedRoles: TStateEmployeeResource[] = [];

  // alphabetize roles with `none` last
  let sortedRoleKeys = Object.keys(roleMap).sort((a, b) => {
    if (a === "none") return 1;
    return a > b ? 1 : b > a ? -1 : 0;
  });

  sortedRoleKeys.forEach((role) => {
    const sortedRoles = sortEmployeesByName(roleMap[role]);
    flattenedSortedRoles.push(...sortedRoles);
  });

  return flattenedSortedRoles;
};
