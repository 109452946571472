import { AxiosResponse, AxiosRequestConfig } from "axios";
import {
  setTokenCookie,
  getTokenCookie,
  removeTokenCookie,
  removeV1TokenCookie,
} from "src/lib/token";

const requestSuccessHandler = (config: AxiosRequestConfig) => {
  const token = getTokenCookie();
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
};

const requestErrorHandler = (err: any) => {
  return Promise.reject(err);
};

const responseSuccessHandler = (res: AxiosResponse<any>) => {
  if (res.headers && res.headers.authorization) {
    setTokenCookie(res.headers.authorization);
  }
  return res;
};

const responseErrorHandler = (err: any) => {
  if (err.response && err.response.status === 401) {
    removeTokenCookie();
    removeV1TokenCookie();
    window.location.assign(`${process.env.REACT_APP_NODE_API_URL}`);
  }
  return Promise.reject(err);
};

export default {
  request: {
    successHandler: requestSuccessHandler,
    errorHandler: requestErrorHandler,
  },
  response: {
    successHandler: responseSuccessHandler,
    errorHandler: responseErrorHandler,
  },
};
