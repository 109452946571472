import React, { useMemo, useCallback } from "react";
import { PopupCard } from "src/components/common/popup";
import { CardHeader, CardBody } from "reactstrap";
import PopupHeadIcons from "src/components/common/popup/PopupHeadIcons";
import IconButton from "src/components/common/icon-button/IconButton";
import CloseIcon from "src/components/common/icons/CloseIcon";
import { useStoreState } from "src/store";
import StatefulButton from "src/components/common/stateful-button";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { autoTable as AutoTable } from "jspdf-autotable";
import moment from "moment";
import { getRangeText, getPrettyTimeRange } from "src/lib/rangeHandler";
import { getOffsetWeekArray, TWeekdayString } from "src/lib/dates";
import {
  getForecastedMetrics,
  getScheduledMetrics,
  getVarianceMetrics,
} from "src/lib/scheduleMetrics";

interface IProps {
  handleClose: () => void;
}

const styles = {
  popup: {
    width: "350px",
  },
};

interface IPdfScheduleRow {
  name: string;
  position: string;
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
  totalHours: string;
}

interface IPdfTimeoffRow {
  name: string;
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
}

interface IPdfForecastRow {
  type: string;
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
  total: string;
}

const generateDailyColumnKeys = (
  offset: number,
  hasValue?: boolean
): { [key: string]: string } => {
  let days: { [key: string]: string } = {};
  getOffsetWeekArray(offset).forEach((day) => {
    days[day] = hasValue
      ? day.replace(/^\w/, (c) => c.toUpperCase()).substr(0, 3)
      : "";
  });
  return days;
};

const PdfDownloadPopup: React.FC<IProps> = (props) => {
  const { handleClose } = props;
  const currentWeekRange = useStoreState(
    (state) => state.scheduler.currentWeekRange
  );
  const locationName = useStoreState(
    (state) => state.location.location?.location_name
  );
  const offset = useStoreState((state) => state.scheduler.weekStartOffset);
  const events = useStoreState((state) => state.events.events);
  const resources = useStoreState((state) => state.resources.resources);
  const schedulingDemand = useStoreState(
    (state) => state.demand.schedulingDemand.data
  );
  const roles = useStoreState((state) => state.templates.templateRoles);

  const { start, end } = useMemo(() => {
    return {
      start: currentWeekRange.start.format("MM/DD"),
      end: currentWeekRange.end.format("MM/DD"),
    };
  }, [currentWeekRange]);

  const download = useCallback(() => {
    const dailyColumnKeys = generateDailyColumnKeys(offset, true);
    const dailyColumnRowKeys = generateDailyColumnKeys(offset);

    const forecastMetrics = getForecastedMetrics(roles, schedulingDemand);
    const scheduledEventMetrics = getScheduledMetrics(roles, events);
    const variance = getVarianceMetrics(scheduledEventMetrics, forecastMetrics);

    const scheduleColumns = {
      name: "Name",
      position: "Position",
      ...dailyColumnKeys,
      totalHours: "Total Hrs",
    } as { [key: string]: string };

    const timeoffColumns = {
      name: "Name",
      ...dailyColumnKeys,
    } as { [key: string]: string };

    const forecastColumns = {
      type: "Type",
      ...dailyColumnKeys,
      total: "Total",
    } as { [key: string]: string };

    const doc = new jsPDF({
      orientation: "landscape",
      format: "letter",
    });

    const rangeText = getRangeText({
      start: currentWeekRange.start,
      end: currentWeekRange.end,
    });

    let pageCount = 1;

    const printedText = `Printed ${moment().format("MM/DD/YYYY")}`;

    let scheduleRows: IPdfScheduleRow[] = [];
    let timeoffRows: IPdfTimeoffRow[] = [];
    let forecastRows: IPdfForecastRow[] = [
      {
        type: "Scheduled",
        monday: `${scheduledEventMetrics.daily.monday.totalHours}`,
        tuesday: `${scheduledEventMetrics.daily.tuesday.totalHours}`,
        wednesday: `${scheduledEventMetrics.daily.wednesday.totalHours}`,
        thursday: `${scheduledEventMetrics.daily.thursday.totalHours}`,
        friday: `${scheduledEventMetrics.daily.friday.totalHours}`,
        saturday: `${scheduledEventMetrics.daily.saturday.totalHours}`,
        sunday: `${scheduledEventMetrics.daily.sunday.totalHours}`,
        total: `${scheduledEventMetrics.totals.totalHours}`,
      },
      {
        type: "Forecasted",
        monday: `${forecastMetrics.daily.monday.totalHours}`,
        tuesday: `${forecastMetrics.daily.tuesday.totalHours}`,
        wednesday: `${forecastMetrics.daily.wednesday.totalHours}`,
        thursday: `${forecastMetrics.daily.thursday.totalHours}`,
        friday: `${forecastMetrics.daily.friday.totalHours}`,
        saturday: `${forecastMetrics.daily.saturday.totalHours}`,
        sunday: `${forecastMetrics.daily.sunday.totalHours}`,
        total: `${forecastMetrics.totals.totalHours}`,
      },
      {
        type: "Variance",
        monday: `${variance.daily.monday.hours} (${variance.daily.monday.percent}%)`,
        tuesday: `${variance.daily.tuesday.hours} (${variance.daily.tuesday.percent}%)`,
        wednesday: `${variance.daily.wednesday.hours} (${variance.daily.wednesday.percent}%)`,
        thursday: `${variance.daily.thursday.hours} (${variance.daily.thursday.percent}%)`,
        friday: `${variance.daily.friday.hours} (${variance.daily.friday.percent}%)`,
        saturday: `${variance.daily.saturday.hours} (${variance.daily.saturday.percent}%)`,
        sunday: `${variance.daily.sunday.hours} (${variance.daily.sunday.percent}%)`,
        total: `${variance.totals.hours} (${variance.totals.percent}%)`,
      },
    ];

    resources.forEach((resource) => {
      const scheduleRow = {
        name: resource.data?.name || "Open",
        position: resource.data?.role || "",
        ...dailyColumnRowKeys,
        totalHours: "",
      } as IPdfScheduleRow;
      let employeeHasTimeoffs = false;

      const timeoffRow = {
        name: resource.data?.name || "Open",
        ...dailyColumnRowKeys,
      } as IPdfTimeoffRow;

      const employeeEvents = events.filter(
        (evt) => evt.resourceId === resource.id
      );

      let totalHours = 0;

      employeeEvents.forEach((evt) => {
        const day = evt.start.format("dddd").toLowerCase() as TWeekdayString;
        const [startTime, endTime] = getPrettyTimeRange(evt.start, evt.end);
        const shiftText = `${startTime}-${endTime}`;

        if (evt.type !== "timeoff") {
          totalHours += moment.duration(evt.end.diff(evt.start)).asHours();
          scheduleRow[day] =
            scheduleRow[day].length > 0
              ? `${scheduleRow[day]}\n\n${shiftText}`
              : shiftText;
        } else {
          employeeHasTimeoffs = true;
          timeoffRow[day] =
            timeoffRow[day].length > 0
              ? `${timeoffRow[day]}\n\n${shiftText}`
              : shiftText;
        }
      });
      scheduleRow.totalHours = `${Math.round(10 * totalHours) / 10}`;
      scheduleRows.push(scheduleRow);
      if (employeeHasTimeoffs) {
        timeoffRows.push(timeoffRow);
      }
    });
    const totalPages = "{total_pages_count_string}";

    ((doc as any).autoTable as AutoTable)({
      head: [scheduleColumns],
      body: scheduleRows,
      styles: {
        fontSize: 9,
      },
      theme: "grid",
      alternateRowStyles: {
        fillColor: [244, 248, 251],
      },
      headStyles: {
        fillColor: [76, 178, 88],
      },
      columnStyles: {
        name: { cellWidth: 35 },
        monday: { cellWidth: 25 },
        tuesday: { cellWidth: 25 },
        wednesday: { cellWidth: 25 },
        thursday: { cellWidth: 25 },
        friday: { cellWidth: 25 },
        saturday: { cellWidth: 25 },
        sunday: { cellWidth: 25 },
        totalHours: { cellWidth: 18, halign: "right" },
      },
      didDrawPage: (data: any) => {
        const pageSize = doc.internal.pageSize;
        const pageHeight = pageSize.getHeight();
        const pageWidth = pageSize.getWidth();

        doc.setFontSize(15);
        doc.text(rangeText, data.settings.margin.left, 15);
        doc.text(
          locationName,
          pageWidth - data.settings.margin.right,
          15,
          undefined,
          undefined,
          "right"
        );
        doc.text("Schedule", pageWidth / 2, 15, undefined, undefined, "center");
        const footerText = `${pageCount} of ${totalPages}`;
        doc.setFontSize(10);

        doc.text(footerText, data.settings.margin.left, pageHeight - 10);
        doc.text(
          printedText,
          pageWidth - data.settings.margin.right,
          pageHeight - 10,
          undefined,
          undefined,
          "right"
        );
        pageCount++;
      },
      margin: { top: 20 },
    });

    doc.addPage();

    ((doc as any).autoTable as AutoTable)({
      head: [timeoffColumns],
      body: timeoffRows,
      styles: {
        fontSize: 9,
      },
      theme: "grid",
      alternateRowStyles: {
        fillColor: [244, 248, 251],
      },
      headStyles: {
        fillColor: [52, 157, 246],
      },
      columnStyles: {
        monday: { cellWidth: 25 },
        tuesday: { cellWidth: 25 },
        wednesday: { cellWidth: 25 },
        thursday: { cellWidth: 25 },
        friday: { cellWidth: 25 },
        saturday: { cellWidth: 25 },
        sunday: { cellWidth: 25 },
      },
      didDrawPage: (data: any) => {
        const pageSize = doc.internal.pageSize;
        const pageHeight = pageSize.getHeight();
        const pageWidth = pageSize.getWidth();

        doc.setFontSize(15);
        doc.text(rangeText, data.settings.margin.left, 15);
        doc.text(
          locationName,
          pageWidth - data.settings.margin.right,
          15,
          undefined,
          undefined,
          "right"
        );
        doc.text(
          "Requested Timeoffs",
          pageWidth / 2,
          15,
          undefined,
          undefined,
          "center"
        );
        const footerText = `${pageCount} of ${totalPages}`;
        doc.setFontSize(10);

        doc.text(footerText, data.settings.margin.left, pageHeight - 10);
        doc.text(
          printedText,
          pageWidth - data.settings.margin.right,
          pageHeight - 10,
          undefined,
          undefined,
          "right"
        );
        pageCount++;
      },
      margin: { top: 20 },
    });

    doc.addPage();

    ((doc as any).autoTable as AutoTable)({
      head: [forecastColumns],
      body: forecastRows,
      styles: {
        fontSize: 9,
      },
      theme: "grid",
      alternateRowStyles: {
        fillColor: [244, 248, 251],
      },
      headStyles: {
        fillColor: [170, 0, 255],
      },
      columnStyles: {
        monday: { cellWidth: 25 },
        tuesday: { cellWidth: 25 },
        wednesday: { cellWidth: 25 },
        thursday: { cellWidth: 25 },
        friday: { cellWidth: 25 },
        saturday: { cellWidth: 25 },
        sunday: { cellWidth: 25 },
      },
      didDrawPage: (data: any) => {
        const pageSize = doc.internal.pageSize;
        const pageHeight = pageSize.getHeight();
        const pageWidth = pageSize.getWidth();

        doc.setFontSize(15);
        doc.text(rangeText, data.settings.margin.left, 15);
        doc.text(
          locationName,
          pageWidth - data.settings.margin.right,
          15,
          undefined,
          undefined,
          "right"
        );
        doc.text("Demand", pageWidth / 2, 15, undefined, undefined, "center");
        const footerText = `${pageCount} of ${totalPages}`;
        doc.setFontSize(10);

        doc.text(footerText, data.settings.margin.left, pageHeight - 10);
        doc.text(
          printedText,
          pageWidth - data.settings.margin.right,
          pageHeight - 10,
          undefined,
          undefined,
          "right"
        );
        pageCount++;
      },
      margin: { top: 20 },
    });

    doc.putTotalPages(totalPages);

    doc.save(`Branch Schedule ${rangeText}.pdf`);
  }, [
    locationName,
    currentWeekRange,
    offset,
    events,
    resources,
    roles,
    schedulingDemand,
  ]);

  return (
    <PopupCard style={styles.popup} className="pb-0">
      <CardHeader>
        <h5>Download PDF</h5>
        <PopupHeadIcons>
          <IconButton
            tooltip="Close"
            onClick={handleClose}
            id="PdfDownloadPopupClose"
          >
            <CloseIcon width={16} />
          </IconButton>
        </PopupHeadIcons>
      </CardHeader>
      <CardBody>
        <p>
          Generate a PDF schedule for {start} &ndash; {end}
        </p>
        <p className="font-italic">
          Note: PDF will be affected by sorting and filtering.
        </p>

        <StatefulButton
          className="float-right"
          loading={false}
          onClick={download}
        >
          Generate
        </StatefulButton>
      </CardBody>
    </PopupCard>
  );
};

export default PdfDownloadPopup;
