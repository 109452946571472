import React, { useState } from 'react';
import {
  TDecoratedShift,
  TShiftPopupAction,
} from 'src/components/scheduler/scheduler.types';
import EditShiftForm from '../shift_form/EditShiftForm';
import DeleteShift from './DeleteShift';
import { PopupCard } from 'src/components/common/popup';
import ShiftDetails from './ShiftDetails';
import { CardHeader, CardBody } from 'reactstrap';
import EventPopupHeader from '../event_popup/EventPopupHeader';
import { TMomentRange } from 'src/lib/rangeHandler';
import AddShiftForm from '../shift_form/AddShiftForm';

interface IProps {
  event: TDecoratedShift;
  editValues?: TMomentRange;
  initialView: TShiftPopupAction;
  close: () => void;
}

const styles = {
  popup: {
    width: '500px',
  },
};

const getTitle = (view: TShiftPopupAction) => {
  switch (view) {
    case 'edit':
      return 'Edit shift';
    case 'delete':
      return 'Delete shift';
    case 'read':
      return 'Shift';
    case 'clone':
      return 'Clone Shift';
  }
};

const actions: TShiftPopupAction[] = ['delete', 'clone', 'read', 'edit'];

// optimization so that this component only re-renders if
// relevant props have changed
const areEqual = (prev: IProps, next: IProps) =>
  prev.event === next.event && prev.close === next.close;

const ShiftPopup = React.memo((props) => {
  const { event, close, initialView, editValues } = props;
  const [view, setView] = useState<TShiftPopupAction>(initialView);

  return (
    <PopupCard style={styles.popup}>
      <CardHeader>
        <EventPopupHeader
          view={view}
          setView={setView}
          handleClose={close}
          title={getTitle(view)}
          availableActions={actions}
        />
      </CardHeader>
      <CardBody>
        {view === 'read' && (
          <ShiftDetails
            start={event.start}
            end={event.end}
            role={event.data.role}
            assignee={event.data.user.name}
          />
        )}

        {view === 'edit' && (
          <EditShiftForm
            role={event.data.role}
            employeeId={event.resourceId}
            start={editValues?.start || event.start}
            end={editValues?.end || event.end}
            eventId={event.id}
            handleClose={close}
          />
        )}

        {view === 'clone' && (
          <AddShiftForm
            role={event.data.role}
            employeeId={event.resourceId}
            start={editValues?.start || event.start}
            end={editValues?.end || event.end}
            handleClose={close}
          />
        )}

        {view === 'delete' && (
          <DeleteShift eventId={event.id} handleClose={close} />
        )}
      </CardBody>
    </PopupCard>
  );
}, areEqual);

export default ShiftPopup;
