import React, { useState, useMemo, useCallback } from "react";
import { Label } from "reactstrap";
import { useStoreState } from "src/store";
import styles from "../index.module.scss";
import useOutsideClickHander from "src/components/scheduler/hooks/useOutsideClickHandler";
import useEscapeClickHandler from "src/components/scheduler/hooks/useEscapeClickHandler";
import SelectedEmployee from "./SelectedEmployee";
import EmployeeSelect from "./EmployeeSelect";
import classnames from "classnames";

interface IProps {
  value: string;
  setFieldValue: (id: "employeeId", value: string) => void;
}

const FIELD_ID = "employeeId";

const EmployeeField: React.FC<IProps> = (props) => {
  const { value, setFieldValue } = props;
  const ref = React.useRef<HTMLDivElement>(null);

  const [resultsVisible, setResultsVisible] = useState(false);

  const employees = useStoreState((state) => state.employees.employeeRecords);

  const hideResults = useCallback(() => {
    if (resultsVisible) setResultsVisible(false);
  }, [resultsVisible, setResultsVisible]);

  useEscapeClickHandler(hideResults);
  useOutsideClickHander(ref, hideResults, resultsVisible);

  const selectedEmployee = useMemo(
    () => employees.find((emp) => emp.uid === value) || null,
    [employees, value]
  );

  const selectEmployee = useCallback(
    (value: string) => () => setFieldValue(FIELD_ID, value),
    [setFieldValue]
  );

  const classes = classnames("form-group", styles.ShiftDropdownContainer);

  return (
    <div className={classes} ref={ref}>
      <Label for={FIELD_ID}>Employee</Label>
      {selectedEmployee ? (
        <SelectedEmployee
          cancel={selectEmployee("")}
          employee={selectedEmployee}
        />
      ) : (
        <EmployeeSelect handleSelect={selectEmployee} />
      )}
    </div>
  );
};

export default EmployeeField;
