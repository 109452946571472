import React, { useState, useCallback, useMemo } from "react";
import { Portal } from "react-portal";
import PopperJS from "popper.js";
import useOutsideClickHander from "src/components/scheduler/hooks/useOutsideClickHandler";
import { usePopper } from "react-popper";

export interface IPopupHandlerBag {
  show: () => void;
  hide: () => void;
  visible: boolean;
}

interface IProps {
  renderPopup: (data: IPopupHandlerBag) => React.ReactNode;
  children: (data: IPopupHandlerBag) => React.ReactNode;
  fillHeight?: boolean;
  visible: boolean;
  setVisible: (arg: boolean) => void;
  placement?: PopperJS.Placement;
}

const PopupHandler: React.FC<IProps> = (props) => {
  const {
    children,
    renderPopup,
    visible,
    setVisible,
    placement,
    fillHeight,
  } = props;
  const [triggerRef, setTriggerRef] = useState<Element | null>(null);
  const [popperRef, setPopperRef] = useState<HTMLElement | null>(null);

  const popperConfig = useMemo(
    () => ({
      placement,
      modifiers: [
        {
          name: "preventOverflow",
          enabled: true,
          options: {
            padding: 10,
            altBoundary: true,
            mainAxis: true,
            tether: false,
            altAxis: true,
          },
        },
        {
          name: "offset",
          enabled: true,
          options: {
            offset: [0, 10],
          },
        },
      ],
    }),
    [placement]
  );
  const { styles, attributes } = usePopper(triggerRef, popperRef, popperConfig);

  const handleClickOutside = useCallback(
    (e: React.MouseEvent | MouseEvent) => {
      setVisible(false);
      e.preventDefault();
    },
    [setVisible]
  );

  useOutsideClickHander(popperRef, handleClickOutside, visible);

  const bag: IPopupHandlerBag = useMemo(
    () => ({
      show: () => setVisible(true),
      hide: () => setVisible(false),
      visible,
    }),
    [visible, setVisible]
  );

  const storeTriggerRef = useCallback(
    (instance: HTMLDivElement | null) => {
      setTriggerRef(instance);
    },
    [setTriggerRef]
  );

  const storePopperRef = useCallback(
    (instance: HTMLDivElement | null) => {
      setPopperRef(instance);
    },
    [setPopperRef]
  );

  const triggerStyle = {
    height: fillHeight ? "100%" : "auto",
  };

  return (
    <>
      <div ref={storeTriggerRef} style={triggerStyle}>
        {children(bag)}
      </div>

      {visible && (
        <Portal>
          <div
            ref={storePopperRef}
            style={styles.popper}
            {...attributes.popper}
            className="popup-container"
          >
            {renderPopup(bag)}
          </div>
        </Portal>
      )}
    </>
  );
};

PopupHandler.defaultProps = {
  placement: "bottom",
};

export default PopupHandler;
