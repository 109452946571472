import * as React from "react";
import { Route, RouteProps } from "react-router-dom";
import { removeTokenCookie } from "src/lib/token";

export type TProps = RouteProps & {
  children: React.ReactNode;
  isAuthenticated: () => boolean;
};

const AuthenticatedRoute = ({ children, isAuthenticated, ...rest }: TProps) => {
  const authed = isAuthenticated();

  if (!authed) {
    removeTokenCookie();
    window.location.href = process.env.REACT_APP_NODE_API_URL;
  }

  return <Route {...rest} render={(props) => children} />;
};

export default AuthenticatedRoute;
