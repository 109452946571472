import React from "react";
import SortAction from "./components/sort/SortAction";
import styles from "./index.module.scss";

const ResourceHeadColumn: React.FC = () => {
  return (
    <div className={styles.ResourceHeadColumn}>
      <SortAction />
    </div>
  );
};

export default ResourceHeadColumn;
