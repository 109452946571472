import { useCallback, useMemo } from "react";
import { useStoreState } from "src/store";
import { rowsToCSVString, downloadFile } from "src/lib/downloadFile";
import { getRangeText } from "src/lib/rangeHandler";

const FIRST_ROW = [
  "Team Member",
  "Event",
  "Start",
  "End",
  "Job Role",
  "Published?",
];

const useDownloadSchedulerCSV = () => {
  const resources = useStoreState((state) => state.resources.resources);
  const events = useStoreState((state) => state.events.events);
  const start = useStoreState((state) => state.scheduler.start);
  const end = useStoreState((state) => state.scheduler.end);
  const rangeText = useMemo(() => getRangeText({ start, end }), [start, end]);

  const downloadCSV = useCallback(() => {
    let rowData: string[][] = [FIRST_ROW];
    resources.forEach((resource) => {
      const eventRows = events
        .filter((event) => event.resourceId === resource.id)
        .map((event) => {
          if (event.type === "shift") {
            return [
              event.data.user.name,
              "Shift",
              event.start.format("llll"),
              event.end.format("llll"),
              event.data.role,
              "Yes",
            ];
          }
          if (event.type === "draft_open_shift") {
            return [
              "Unassigned",
              "Shift",
              event.start.format("llll"),
              event.end.format("llll"),
              event.data.role,
              "No",
            ];
          }
          if (event.type === "draft_shift") {
            return [
              "Unassigned",
              "Shift",
              event.start.format("llll"),
              event.end.format("llll"),
              event.data.role,
              "No",
            ];
          }
          if (event.type === "open_shift") {
            return [
              "Unassigned",
              "Shift",
              event.start.format("llll"),
              event.end.format("llll"),
              event.data.role,
              "Yes",
            ];
          }
          if (event.type === "timeoff") {
            return [
              event.data.requester.name,
              "Timeoff",
              event.start.format("llll"),
              event.end.format("llll"),
              "N/A",
              "Yes",
            ];
          }
          return [];
        });

      rowData = rowData.concat(eventRows);
    });
    const stringified = rowsToCSVString(rowData);
    downloadFile(
      stringified,
      `Branch Schedule (${rangeText}).csv`,
      "text/csv;encoding:utf-8"
    );
  }, [resources, events, rangeText]);

  return downloadCSV;
};

export default useDownloadSchedulerCSV;
