import React from "react";
import IconButton from "../common/icon-button/IconButton";
import ArrowRightIcon from "../common/icons/ArrowRightIcon";
import ArrowLeftIcon from "../common/icons/ArrowLeftIcon";

interface IProps {
  direction: "left" | "right";
  tooltip: string;
  handleClick: () => void;
}

const RangeButton: React.FC<IProps> = (props) => {
  const { direction, handleClick, tooltip } = props;
  const Icon = direction === "right" ? ArrowRightIcon : ArrowLeftIcon;

  return (
    <IconButton
      tooltip={tooltip}
      onClick={handleClick}
      id={`RangeButton_${direction}`}
    >
      <Icon width="1rem" />
    </IconButton>
  );
};

export default RangeButton;
