import React, { useMemo } from "react";
import { parseViolationString } from "src/lib/violations";

interface IProps {
  title?: string;
  details: string | string[];
  description?: string;
  subtext?: string;
}

const ViolationMessage: React.FC<IProps> = (props) => {
  const { title, details, description, subtext } = props;

  const violations = useMemo(
    () =>
      typeof details === "object" ? details : parseViolationString(details),
    [details]
  );

  return (
    <>
      {title && <h5 className="text-danger">{title}</h5>}
      {description && <p>{description}</p>}
      <ol>
        {violations.map((violation) => (
          <li key={violation}>{violation}</li>
        ))}
      </ol>
      {subtext && <p>{subtext}</p>}
    </>
  );
};

export default ViolationMessage;
