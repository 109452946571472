import { client as LocationClient, LocationApi } from "./grpc/location";
import { client as TimeoffClient, TimeoffApi } from "./grpc/timeoff";
import { getUserIdFromTokenCookie } from "src/lib/token";

export const getLocationSettings = (id: string) => {
  const req = new LocationApi.GetAllSettingsRequest();
  req.setLocationId(id);
  return LocationClient.getAllSettings(req);
};

export const getHistoricalRTO = (locationId: string) => {
  const req = new TimeoffApi.HistoricalRequest();
  req.setUserId(getUserIdFromTokenCookie());
  req.setLocationId(locationId);
  return TimeoffClient.getHistorical(req);
};

export const getRTO = (locationId: string) => {
  const req = new TimeoffApi.GetRequest();
  req.setUserId(getUserIdFromTokenCookie());
  req.setLocationId(locationId);
  return TimeoffClient.get(req);
};
