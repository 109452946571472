import { Moment } from "moment";

export const computeTrueEventPosition = (start: Moment, end: Moment) => ({
  start: start.clone(),
  end: end.clone(),
});

export const computeDayBlockedEventPosition = (start: Moment) => ({
  start: start.clone().startOf("day"),
  end: start.clone().endOf("day"),
});
