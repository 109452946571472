import { useEffect } from "react";

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys: { [key: number]: number } = { 37: 1, 38: 1, 39: 1, 40: 1 };

function preventDefault(e: any) {
  e = e || window.event;
  if (e.preventDefault) e.preventDefault();
  e.returnValue = false;
}

function preventDefaultForScrollKeys(e: KeyboardEvent) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

const useDisableScroll = (ref: any) => {
  useEffect(() => {
    let curr = ref.current;
    if (curr !== null) {
      curr.addEventListener("DOMMouseScroll", preventDefault, false);
      curr.addEventListener("wheel", preventDefault, { passive: false });
      curr.onwheel = preventDefault;
      curr.ontouchmove = preventDefault;
      curr.onkeydown = preventDefaultForScrollKeys;
    }

    return () => {
      curr.removeEventListener("DOMMouseScroll", preventDefault);
      curr.removeEventListener("wheel", preventDefault);
    };
  }, [ref]);
};

export default useDisableScroll;
