import React from "react";
import { useStoreState } from "src/store";
import ControlledPopupHandler from "src/components/common/popup/ControlledPopupHandler";
import ViewPickerPopup from "./ViewPickerPopup";
import DropdownButton from "src/components/common/dropdown-button/DropdownButton";

const ViewPicker: React.FC = (props) => {
  const viewLabel = useStoreState((state) => state.scheduler.viewLabel);

  return (
    <ControlledPopupHandler
      renderPopup={({ hide }) => <ViewPickerPopup handleClose={hide} />}
    >
      {({ show }) => (
        <DropdownButton className="mr-2" onClick={show}>
          {viewLabel}
        </DropdownButton>
      )}
    </ControlledPopupHandler>
  );
};

export default ViewPicker;
