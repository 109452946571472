import React, { useState } from "react";
import CloseIcon from "src/components/common/icons/CloseIcon";
import { Tooltip } from "reactstrap";
import classnames from "classnames";
import styles from "./index.module.scss";

interface IProps {
  tooltip?: string;
  className?: string;
  id: string;
  onClick: (e: React.MouseEvent) => void;
}

const CloseButton: React.FC<IProps> = (props) => {
  const { tooltip, className, onClick, id } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const classes = classnames(styles.CloseButton, className);

  return (
    <>
      <CloseIcon onClick={onClick} id={id} className={classes} width={16} />
      <Tooltip
        hideArrow={true}
        placement="bottom"
        offset="0,0"
        isOpen={tooltipOpen}
        target={id}
        toggle={toggle}
      >
        {tooltip}
      </Tooltip>
    </>
  );
};

CloseButton.defaultProps = {
  tooltip: "Close",
};

export default CloseButton;
