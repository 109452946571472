import React, { useState, useCallback } from "react";
import { FormikHelpers } from "formik";
import { Moment } from "moment";
import { TShiftFormValues } from "./types";
import { useStoreState, useStoreActions } from "src/store";
import { validateAndPostWorkshift, prepareCustomWorkshiftRequest } from "./lib";
import { errorIsViolation } from "src/lib/violations";
import { AxiosError } from "axios";
import { TShiftViolationErrorData } from "src/api/api.types";
import {
  evaluateDraftWorkshift,
  addCustomWorkshift,
  getWorkshift,
} from "src/api";
import ShiftForm from "./ShiftForm";

interface IProps {
  role?: string;
  employeeId?: string;
  start: Moment;
  end: Moment;
  handleClose: () => void;
}

const AddShiftForm: React.FC<IProps> = (props) => {
  const { role, employeeId, start, end, handleClose } = props;
  const userId = useStoreState((state) => state.user.userId);
  const locationId = useStoreState((state) => state.location.locationId);
  const events = useStoreState((state) => state.events.events);
  const view = useStoreState((state) => state.scheduler.view);
  const employeeRecordsById = useStoreState(
    (state) => state.employees.employeeRecordsById
  );
  const addWorkshift = useStoreActions(
    (actions) => actions.shifts.addWorkshift
  );
  const [showViolations, setShowViolations] = useState(false);
  const [violationMessage, setViolationMessage] = useState("");

  const submit = useCallback(
    async (
      values: TShiftFormValues,
      helpers: FormikHelpers<TShiftFormValues>
    ) => {
      helpers.setSubmitting(true);
      const formattedStart = values.start.format("YYYY-MM-DD HH:mm:ss");
      const formattedEnd = values.end.format("YYYY-MM-DD HH:mm:ss");

      try {
        // if we are currently showing violations,
        // it means that the submit button should
        // bypass validation
        const runValidation = !showViolations;

        const employee = employeeRecordsById[values.employeeId];

        if (employee.hasBranchAccount) {
          const res = await validateAndPostWorkshift(
            {
              userId,
              owner: +values.employeeId,
              locationId,
              role: values.role,
              start: values.start,
              end: values.end,
            },
            runValidation
          );
          addWorkshift(res.data.data);
        } else {
          if (runValidation) {
            const shift = {
              start_time: formattedStart,
              end_time: formattedEnd,
            };
            const shifts = events
              .filter((e) => e.type === "draft_shift" || e.type === "shift")
              .filter((shift) => shift.resourceId === values.employeeId)
              .map((shift) => ({
                start_time: shift.start.format("YYYY-MM-DD HH:mm:ss"),
                end_time: shift.end.format("YYYY-MM-DD HH:mm:ss"),
              }));
            await evaluateDraftWorkshift({
              user_id: -1,
              employee_id: values.employeeId,
              location_id: locationId,
              current_shift: shift,
              time_interval: view,
              shifts,
            });
          }
          const request = prepareCustomWorkshiftRequest(
            userId,
            locationId,
            employee,
            values
          );
          const addRes = await addCustomWorkshift(request);
          const customShiftRes = await getWorkshift(
            `${addRes.data.response.insertId}`
          );
          addWorkshift(customShiftRes.data.data);
        }
        handleClose();
      } catch (err) {
        if (errorIsViolation(err)) {
          setShowViolations(true);
          const error = err as AxiosError<TShiftViolationErrorData>;
          setViolationMessage(error.response?.data.meta.detail || "");
        }
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [
      showViolations,
      userId,
      locationId,
      addWorkshift,
      employeeRecordsById,
      view,
      events,
      handleClose,
    ]
  );

  return (
    <ShiftForm
      role={role}
      employeeId={employeeId}
      start={start}
      end={end}
      handleSubmit={submit}
      violationMessage={violationMessage}
      setViolationMessage={setViolationMessage}
      setShowViolations={setShowViolations}
      showViolations={showViolations}
    />
  );
};

export default AddShiftForm;
