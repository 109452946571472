import React, { useMemo, useCallback, useState } from "react";
import {
  TDecoratedOpenShift,
  TShiftPopupAction,
} from "src/components/scheduler/scheduler.types";
import InteractiveShiftWrapper from "../../InteractiveShiftWrapper";
import PopupHandler, {
  IPopupHandlerBag,
} from "src/components/common/popup/PopupHandler";
import useRoleColor from "../../hooks/useRoleColor";
import BaseEventCard from "../../BaseEventCard";
import PrettyTimeRange from "src/components/common/pretty_time_range/PrettyTimeRange";
import OpenShiftPopup from "./OpenShiftPopup";
import useDuration from "src/hooks/useDuration";
import { Moment } from "moment";
import { TMomentRange } from "src/lib/rangeHandler";

interface IProps {
  compact?: boolean;
  event: TDecoratedOpenShift;
  canResize: boolean;
}

const OpenShiftEvent: React.FC<IProps> = (props) => {
  const { event, canResize } = props;
  const [popupVisible, setPopupVisible] = useState(false);
  const [persistEditedState, setPersistEditedState] = useState(false);
  const [initialPopupView, setInitialPopupView] = useState<TShiftPopupAction>(
    "read"
  );
  const [editValues, setEditValues] = useState<TMomentRange>();
  const roleColor = useRoleColor(event.data.role);
  const duration = useDuration(event.start, event.end);

  const containerStyles = useMemo(
    () => ({
      border: `2px solid ${roleColor}`,
      backgroundColor: "white",
      color: roleColor,
    }),
    [roleColor]
  );

  const onResize = useCallback((start: Moment, end: Moment) => {
    setPersistEditedState(true);
    setEditValues({ start, end });
    setInitialPopupView("edit");
    setPopupVisible(true);
  }, []);

  const setVisible = useCallback((visible: boolean) => {
    if (!visible) {
      setEditValues(undefined);
      setPersistEditedState(false);
      setInitialPopupView("read");
    }
    setPopupVisible(visible);
  }, []);

  const renderPopup = useCallback(
    ({ hide }: IPopupHandlerBag) => (
      <OpenShiftPopup
        event={event}
        editValues={editValues}
        initialView={initialPopupView}
        close={hide}
      />
    ),
    [event, editValues, initialPopupView]
  );

  return (
    <InteractiveShiftWrapper
      event={event}
      canResize={canResize}
      onResize={onResize}
      persistEditedState={persistEditedState}
    >
      <PopupHandler
        renderPopup={renderPopup}
        placement="bottom"
        visible={popupVisible}
        setVisible={setVisible}
      >
        {({ visible, show }) => (
          <BaseEventCard
            event={event}
            isActive={visible}
            style={containerStyles}
            onClick={show}
          >
            <PrettyTimeRange start={event.start} end={event.end} />
            <div>
              {event.data.role} ({duration}hrs)
            </div>
          </BaseEventCard>
        )}
      </PopupHandler>
    </InteractiveShiftWrapper>
  );
};

export default OpenShiftEvent;
