import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

const VerticalEllipsisIcon: React.FC<Props> = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g fill="#000" fillRule="nonzero">
      <circle cx={12} cy={3.25} r={3.25} />
      <circle cx={12} cy={12} r={3.25} />
      <circle cx={12} cy={20.75} r={3.25} />
    </g>
  </svg>
);

export default VerticalEllipsisIcon;
