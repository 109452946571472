import { useMemo } from "react";
import { useStoreState } from "src/store";
import { UNIDENTIFIED_ROLE_COLOR } from "src/lib/color";

const useRoleColor = (role?: string | null) => {
  const roleColors = useStoreState((state) => state.templates.roleColors);

  return useMemo(() => roleColors[role || ""] || UNIDENTIFIED_ROLE_COLOR, [
    role,
    roleColors,
  ]);
};

export default useRoleColor;
