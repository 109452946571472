import React, { useMemo, useCallback } from "react";
import { useStoreState, useStoreActions } from "src/store";
import RangeButton from "./RangeButton";
import { Row, Col, Container } from "reactstrap";
import { getRangeText } from "src/lib/rangeHandler";
import ViewPicker from "./components/filter/ViewPicker";
import Filters from "./components/filter/Filters";
import GeneralMenu from "./components/general_menu/GeneralMenu";
import Autoscheduler from "./components/autoscheduler/Autoscheduler";
import AddAction from "./components/add_action/AddAction";
import PdfDownload from "./PdfDownload/PdfDownload";
import ScheduleStatus from "./ScheduleStatus";

const SchedulerActions = () => {
  const start = useStoreState((state) => state.scheduler.start);
  const end = useStoreState((state) => state.scheduler.end);
  const view = useStoreState((state) => state.scheduler.view);
  const viewLabel = useStoreState((state) => state.scheduler.viewLabel);
  const setRange = useStoreActions((actions) => actions.scheduler.setRange);
  const getBackwardRange = useStoreState(
    (state) => state.scheduler.getBackwardRange
  );
  const draftStatus = useStoreState((state) => state.draft.draftStatus);
  const getForwardRange = useStoreState(
    (state) => state.scheduler.getForwardRange
  );
  const scheduleStatusLoading = useStoreState(
    (state) => state.draft.draft.loading
  );

  const rangeText = useMemo(() => getRangeText({ start, end }), [start, end]);

  const setBack = useCallback(() => {
    setRange(
      getBackwardRange({
        start,
        end,
      })
    );
  }, [start, end, getBackwardRange, setRange]);

  const setForward = useCallback(() => {
    setRange(
      getForwardRange({
        start,
        end,
      })
    );
  }, [start, end, getForwardRange, setRange]);

  return (
    <Container fluid>
      <Row className="my-2">
        <Col xs={6} lg={5} className="d-flex align-items-center">
          <RangeButton
            direction="left"
            handleClick={setBack}
            tooltip={`Previous ${viewLabel}`}
          />
          <RangeButton
            direction="right"
            handleClick={setForward}
            tooltip={`Next ${viewLabel}`}
          />
          <h5 className="mr-3 ml-1 mt-1 mb-0">{rangeText}</h5>
        </Col>
        <Col
          lg={2}
          xs={6}
          className="d-flex align-items-center justify-content-lg-center justify-content-end"
        >
          {!scheduleStatusLoading && <ScheduleStatus status={draftStatus} />}
        </Col>
        <Col
          lg={5}
          xs={7}
          className="d-flex align-items-center justify-content-lg-end justify-content-start"
        >
          <ViewPicker />
          <Filters />
          {draftStatus !== "published" && <Autoscheduler />}
          {view === "week" && <PdfDownload />}
          <AddAction draftPublished={draftStatus === "published"} />
          <GeneralMenu />
        </Col>
      </Row>
    </Container>
  );
};

export default SchedulerActions;
