import React from 'react';
import HeadColumn from 'src/components/scheduler/HeadColumn';
import Resource from 'src/components/scheduler/Resource';
import ResourceHeadColumn from 'src/components/scheduler/ResourceHeadColumn';
import Event from './WeekEvent';
import Cell from './WeekCell';
import { useStoreState } from 'src/store';
import WeeklyForecastContainer from '../weekly_forecast/WeeklyForecastContainer';
import SchedulerWrapper from 'src/components/scheduler/SchedulerWrapper';

const WeekView: React.FC = () => {
  const resources = useStoreState((state) => state.resources.resources);
  const events = useStoreState((state) => state.events.events);
  const isDominos = useStoreState((state) => state.location.isDominos);

  return (
    <>
      <SchedulerWrapper
        resources={resources}
        events={events}
        renderResource={(row) => <Resource row={row} />}
        renderHeadCol={(col) => (
          <HeadColumn col={col} format="ddd DD" allowClick={true} />
        )}
        renderCell={({ col, row }) => <Cell column={col} row={row} />}
        renderEvent={(event) => <Event event={event} />}
        renderResourceHeadCol={() => <ResourceHeadColumn />}
      />
      {isDominos && <WeeklyForecastContainer />}
    </>
  );
};

export default WeekView;
