import React from "react";
import ResizableEvent from "./ResizableEvent";
import StaticEvent from "./StaticEvent";
import { useStoreState } from "src/store";
import { TDecoratedEvent } from "src/components/scheduler/scheduler.types";
import { Moment } from "moment";

interface IProps {
  event: TDecoratedEvent;
  canResize?: boolean;
  persistEditedState?: boolean;
  onResize?: (start: Moment, end: Moment) => void;
  children: React.ReactNode;
}

const InteractiveShiftWrapper: React.FC<IProps> = (props) => {
  const { event, canResize, onResize, children, persistEditedState } = props;
  const isManager = useStoreState((state) => state.location.isManager);

  if (canResize && isManager && onResize) {
    return (
      <ResizableEvent
        event={event}
        onResize={onResize}
        persistEditedState={persistEditedState}
      >
        {children}
      </ResizableEvent>
    );
  }

  return <StaticEvent event={event}>{children}</StaticEvent>;
};

export default InteractiveShiftWrapper;
