import axios from "axios";
import auth from "./interceptors/auth";

axios.defaults.timeout = 45000;

// Branch-Auth Gateway API

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

api.interceptors.request.use(
  auth.request.successHandler,
  auth.request.errorHandler
);

api.interceptors.response.use(
  auth.response.successHandler,
  auth.response.errorHandler
);

// Node API

export const nodeApi = axios.create({
  baseURL: process.env.REACT_APP_NODE_API_URL,
  headers: { "Content-Type": "application/json" },
});

nodeApi.interceptors.request.use(
  auth.request.successHandler,
  auth.request.errorHandler
);

// Bridge API

export const bridgeApi = axios.create({
  baseURL: process.env.REACT_APP_BRIDGE_API_URL,
});
