import { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'src/store';
import moment from 'moment';
import { getOffsetWeekRange } from 'src/lib/rangeHandler';

const useSchedulerReactions = () => {
  const fetchLocationEmployees = useStoreActions(
    (actions) => actions.employees.fetchLocationEmployees
  );
  const fetchBridgeEmployees = useStoreActions(
    (actions) => actions.employees.fetchBridgeEmployees
  );
  const fetchOpenShifts = useStoreActions(
    (actions) => actions.shifts.fetchOpenShifts
  );
  const fetchNearbyLocations = useStoreActions(
    (actions) => actions.nearby.fetchNearbyLocations
  );
  const fetchGroups = useStoreActions((actions) => actions.roles.fetchGroups);
  const fetchRoles = useStoreActions((actions) => actions.roles.fetchRoles);
  const fetchAvailability = useStoreActions(
    (actions) => actions.availability.fetchAvailability
  );
  const fetchWorkshifts = useStoreActions(
    (actions) => actions.shifts.fetchWorkshifts
  );
  const fetchTemplates = useStoreActions(
    (actions) => actions.templates.fetchTemplates
  );
  const fetchHistoricalTimeoffs = useStoreActions(
    (actions) => actions.timeoffs.fetchHistoricalTimeoffs
  );
  const fetchTimeoffs = useStoreActions(
    (actions) => actions.timeoffs.fetchTimeoffs
  );
  const setRange = useStoreActions((actions) => actions.scheduler.setRange);
  const getDraft = useStoreActions((actions) => actions.draft.getDraft);
  const setInitialized = useStoreActions(
    (actions) => actions.scheduler.setInitialized
  );
  const fetchSchedulingDemand = useStoreActions(
    (actions) => actions.demand.fetchSchedulingDemand
  );

  const currentWeekRange = useStoreState(
    (state) => state.scheduler.currentWeekRange
  );
  const location = useStoreState((state) => state.location.location);
  const start = useStoreState((state) => state.scheduler.start);
  const end = useStoreState((state) => state.scheduler.end);
  const weekStartOffset = useStoreState(
    (state) => state.scheduler.weekStartOffset
  );
  const view = useStoreState((state) => state.scheduler.view);
  const settings = useStoreState((state) => state.location.settings.data);
  const initialized = useStoreState((state) => state.scheduler.initialized);

  useEffect(() => {
    if (settings.length > 0) {
      const range = getOffsetWeekRange(moment(), weekStartOffset);
      setRange(range);
      setInitialized(true);
    }
  }, [settings, weekStartOffset, setRange, setInitialized]);

  // just watch the location for changes
  useEffect(() => {
    if (location) {
      const { location_id } = location;
      fetchLocationEmployees(location_id);
      fetchBridgeEmployees(location_id);
      fetchNearbyLocations(location_id);
      fetchOpenShifts(location_id);
      fetchTemplates(location_id);
      fetchHistoricalTimeoffs(location_id);
      fetchTimeoffs(location_id);
      fetchGroups(location_id);
      fetchRoles(location_id);
    }
  }, [
    location,
    fetchLocationEmployees,
    fetchBridgeEmployees,
    fetchOpenShifts,
    fetchTemplates,
    fetchHistoricalTimeoffs,
    fetchTimeoffs,
    fetchGroups,
    fetchNearbyLocations,
    fetchRoles,
  ]);

  // watch location and date selected
  useEffect(() => {
    if (location && initialized) {
      fetchAvailability();
      fetchWorkshifts();
      fetchSchedulingDemand();
    }
  }, [
    initialized,
    location,
    start,
    end,
    view,
    fetchAvailability,
    fetchWorkshifts,
    fetchSchedulingDemand,
  ]);

  useEffect(() => {
    if (location && initialized) {
      getDraft();
    }
  }, [initialized, location, currentWeekRange, getDraft]);
};

export default useSchedulerReactions;
