import React, { useMemo } from "react";
import { IResource, IEvent } from "../schedulerTable/types";
import useSchedulerLoading from "src/hooks/useSchedulerLoading";
import styles from "./index.module.scss";
import SchedulerContainer, {
  ISchedulerProps,
} from "../schedulerTable/SchedulerContainer";

const SkeletonResource = () => (
  <div className={styles.SkeletonResource}>
    <div className="skeleton-lines" />
    <div className="skeleton-lines" />
  </div>
);

const skeletonResources: IResource[] = [
  { id: "1", data: null },
  { id: "2", data: null },
  { id: "3", data: null },
  { id: "4", data: null },
  { id: "5", data: null },
  { id: "6", data: null },
  { id: "7", data: null },
  { id: "8", data: null },
  { id: "9", data: null },
];

const skeletonEvents: IEvent[] = [];

const SchedulerWrapper: React.FC<ISchedulerProps> = (props) => {
  const loading = useSchedulerLoading();

  const renderResource = useMemo(
    () => (loading ? () => <SkeletonResource /> : props.renderResource),
    [loading, props.renderResource]
  );

  const renderCell = useMemo(() => (loading ? () => null : props.renderCell), [
    loading,
    props.renderCell,
  ]);

  const resources = useMemo(
    () => (loading ? skeletonResources : props.resources),
    [loading, props.resources]
  );

  const events = useMemo(() => (loading ? skeletonEvents : props.events), [
    loading,
    props.events,
  ]);

  return (
    <SchedulerContainer
      {...props}
      resources={resources}
      events={events}
      renderResource={renderResource}
      renderCell={renderCell}
    />
  );
};

export default SchedulerWrapper;
