import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

const ArrowRightIcon = (props: Props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      d="M19.5 12a2.3 2.3 0 01-.78 1.729L7.568 23.54a1.847 1.847 0 01-2.439-2.773l9.752-8.579a.25.25 0 000-.376L5.129 3.233A1.847 1.847 0 017.568.46l11.148 9.808A2.31 2.31 0 0119.5 12z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);

export default ArrowRightIcon;
