import React, { useMemo } from "react";
import useDragHandle, { IDragResult } from "./hooks/useDragHandle";
import styles from "./index.module.scss";
import classnames from "classnames";
import { ResizeRightIcon, ResizeLeftIcon } from "../common/icons";

interface IProps {
  position: "right" | "left";
  handleDrag: (translation: number) => void;
  handleDragEnd: (result: IDragResult) => void;
}

const EventDragHandle: React.FC<IProps> = (props) => {
  const { position, handleDrag, handleDragEnd } = props;

  const classes = useMemo(
    () =>
      classnames(styles.EventDragHandle, {
        [styles.EventDragHandle_Right]: position === "right",
        [styles.EventDragHandle_Left]: position === "left",
      }),
    [position]
  );

  const icon = position === "right" ? <ResizeRightIcon /> : <ResizeLeftIcon />;

  const ref = useDragHandle(handleDrag, handleDragEnd);

  return (
    <div className={classes} ref={ref}>
      {icon}
    </div>
  );
};

export default EventDragHandle;
