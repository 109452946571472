import React from "react";
import HeadColumn from "src/components/scheduler/HeadColumn";
import Resource from "src/components/scheduler/Resource";
import Event from "./ThreeDayEvent";
import Cell from "../week/WeekCell";
import { useStoreState } from "src/store";
import SchedulerWrapper from "src/components/scheduler/SchedulerWrapper";
import ResourceHeadColumn from "../../ResourceHeadColumn";

const ThreeDayView: React.FC = () => {
  const resources = useStoreState((state) => state.resources.resources);
  const events = useStoreState((state) => state.events.events);

  return (
    <SchedulerWrapper
      resources={resources}
      events={events}
      renderResource={(row) => <Resource row={row} />}
      renderHeadCol={(col) => (
        <HeadColumn col={col} format="ddd DD" allowClick={true} />
      )}
      renderCell={({ col, row }) => <Cell column={col} row={row} />}
      renderEvent={(event) => <Event event={event} />}
      renderResourceHeadCol={() => <ResourceHeadColumn />}
    />
  );
};

export default ThreeDayView;
