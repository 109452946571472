import React, { useCallback } from "react";
import { Form, Button } from "reactstrap";
import { Formik, FormikProps, FormikHelpers } from "formik";
import { Moment } from "moment";
import StatefulButton from "src/components/common/stateful-button";
import { TOpenShiftFormValues } from "./types";
import OpenShiftFormFields from "./OpenShiftFormFields";
import ViolationMessage from "../violation/ViolationMessage";

interface IProps {
  role?: string;
  notifyGroups?: number[];
  reason?: string;
  nearbyLocations?: string[];
  start: Moment;
  end: Moment;
  handleSubmit: (
    values: TOpenShiftFormValues,
    helpers: FormikHelpers<TOpenShiftFormValues>
  ) => Promise<void>;
  setShowViolations: React.Dispatch<React.SetStateAction<boolean>>;
  setViolationMessage: React.Dispatch<React.SetStateAction<string>>;
  showViolations: boolean;
  violationMessage: string;
}

const OpenShiftForm: React.FC<IProps> = (props) => {
  const {
    role = "",
    notifyGroups = [],
    reason = "",
    nearbyLocations = [],
    start,
    end,
    handleSubmit,
    setShowViolations,
    showViolations,
    violationMessage,
    setViolationMessage,
  } = props;

  const handleBack = useCallback(() => {
    setShowViolations(false);
    setViolationMessage("");
  }, [setShowViolations, setViolationMessage]);

  return (
    <Formik
      initialValues={{
        role,
        start,
        end,
        notifyGroups,
        reason,
        nearbyLocations,
      }}
      onSubmit={handleSubmit}
    >
      {(formik: FormikProps<TOpenShiftFormValues>) => (
        <Form onSubmit={formik.handleSubmit}>
          {showViolations ? (
            <ViolationMessage
              title="Encountered violations"
              details={violationMessage}
              description="The following violations occurred for this user when trying to modify this shift:"
              subtext="You can either proceed to ignore this warning or go back and edit the form."
            />
          ) : (
            <OpenShiftFormFields formik={formik} />
          )}

          <div className="d-flex align-items-center justify-content-between mt-4">
            {showViolations && (
              <Button color="secondary" outline onClick={handleBack}>
                Go Back
              </Button>
            )}

            <StatefulButton
              color="primary"
              type="submit"
              className="ml-auto"
              loading={formik.isSubmitting}
            >
              {showViolations ? "Proceed" : "Save"}
            </StatefulButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default OpenShiftForm;
