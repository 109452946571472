import { Moment } from 'moment';

export type TMomentRangeHandler = (range: TMomentRange) => TMomentRange;

export type TMomentRange = {
  start: Moment;
  end: Moment;
};

export type TViewType = 'week' | 'day' | 'month' | 'three_day';

export const VIEW_LABELS = {
  week: 'Week',
  day: 'Day',
  month: 'Month',
  three_day: '3 days',
};

/**
 *
 * @param date any moment date, but this function will return the week start
 * preceding this date.
 * @param offset indicates number of days past sunday that the location's week
 * starts on
 */
export const getOffsetWeekRange = (
  date: Moment,
  offset: number
): TMomentRange => {
  const start = date
    .clone()
    .subtract(offset, 'days')
    .startOf('week')
    .add(offset, 'days');
  const end = start.clone().add(6, 'days').endOf('day');
  return { start, end };
};

export const adjustForwardOneWeek: TMomentRangeHandler = ({ start, end }) => ({
  start: start.clone().add(7, 'days'),
  end: end.clone().add(7, 'days'),
});

export const adjustBackwardOneWeek: TMomentRangeHandler = ({ start, end }) => ({
  start: start.clone().subtract(7, 'days'),
  end: end.clone().subtract(7, 'days'),
});

export const adjustForwardOneDay: TMomentRangeHandler = ({ start, end }) => ({
  start: start.clone().add(1, 'day'),
  end: end.clone().add(1, 'day'),
});

export const adjustBackwardOneDay: TMomentRangeHandler = ({ start, end }) => ({
  start: start.clone().subtract(1, 'day'),
  end: end.clone().subtract(1, 'day'),
});

export const adjustBackwardThreeDays: TMomentRangeHandler = ({
  start,
  end,
}) => ({
  start: start.clone().subtract(1, 'days'),
  end: end.clone().subtract(1, 'days'),
});

export const adjustForwardThreeDays: TMomentRangeHandler = ({
  start,
  end,
}) => ({
  start: start.clone().add(1, 'days'),
  end: end.clone().add(1, 'days'),
});

/**
 *
 * Given a start date and an end date,
 * return a formatted output
 *
 * _Examples_:
 *
 * **Same day**: Apr 1, 2019
 *
 * **Same month**: Apr 1 - 15, 2019
 *
 * **Same year**: Apr 1 - May 15, 2019
 *
 * **Different year**: Apr 1, 2019 - Jan 1, 2020
 */
export const getRangeText = ({ start, end }: TMomentRange) => {
  // if its the same day, just return early.
  // we don't need to perform other logic.
  const isSameDay = start.isSame(end, 'day');
  if (isSameDay) return start.format('ddd, MMM D, YYYY');

  const isSameMonth = start.isSame(end, 'month');
  const isSameYear = start.isSame(end, 'year');
  const startFormat = `MMM D${!isSameYear ? ', YYYY' : ''}`;
  const endFormat = `${!isSameMonth ? 'MMM ' : ''}D, YYYY`;
  const startText = start.format(startFormat);
  const endText = end.format(endFormat);
  return `${startText} - ${endText}`;
};

const createPrettyTime = (
  hour: string,
  mins: string,
  meridiem: string,
  hasMeridiem: boolean
) => {
  // strip leading 0s
  const hours = hour.replace(/^0+/, '');
  const minutes = mins !== '00' ? `:${mins}` : '';
  const amPm = hasMeridiem ? meridiem : '';

  return `${hours}${minutes}${amPm}`;
};

export const getPrettyTimeRange = (start: Moment, end: Moment) => {
  // comparators return the minutes and AM/PM value to compare
  // Instead of doing one for each of minutes and AM/PM,
  // this helps to limit the Moment formatting processing going on.
  const startComparator = start.format('hhmma');
  const endComparator = end.format('hhmma');

  // the `h` of `hmma`
  const startHour = startComparator.substring(0, 2);
  const endHour = endComparator.substring(0, 2);
  // the `mm` of `hmma`
  const startMinutes = startComparator.substring(2, 4);
  const endMinutes = endComparator.substring(2, 4);
  // the `a` of `hmma`
  const startMeridiem = startComparator.substring(4);
  const endMeridiem = endComparator.substring(4);

  const startHasMeridiem = startMeridiem !== endMeridiem;

  const prettyStart = createPrettyTime(
    startHour,
    startMinutes,
    startMeridiem,
    startHasMeridiem
  );
  const prettyEnd = createPrettyTime(endHour, endMinutes, endMeridiem, true);

  return [prettyStart, prettyEnd];
};

export const eventIsBetweenMoments = (
  event: { start: Moment; end: Moment },
  between: { start: Moment; end: Moment },
  disallowEventsStartingBefore: boolean
) => {
  // from the moment documentation of the `isBetween` method:
  // A [ indicates inclusion of a value. A ( indicates exclusion.
  // If the inclusivity parameter is used,
  // both indicators must be passed.
  //
  if (disallowEventsStartingBefore) {
    return event.start.isBetween(between.start, between.end, undefined, '[]');
  } else {
    return (
      event.start.isBetween(between.start, between.end, undefined, '[]') ||
      event.end.isBetween(between.start, between.end, undefined, '[]')
    );
  }
};

// an optimized function that does not require you
// to create moment objects for the event times.
//
// For events that are never even displayed, we should minimize
// computational resources as much as possible, and Moment objects are
// expensive to make.
export const eventIsWithinRange = (
  event: { start: number; end: number },
  range: { start: number; end: number }
) => {
  //    |---------|
  //       <--->
  const startAndEndWithinRange =
    range.start <= event.start && range.end >= event.end;

  //    |---------|
  //  <--->
  const eventEndsWithinRange =
    event.start <= range.start &&
    event.end <= range.end &&
    event.end >= range.start;

  //    |---------|
  //            <--->
  const eventStartsWithinRange =
    event.start >= range.start &&
    event.start <= range.end &&
    event.end >= range.end;

  //    |---------|
  //  <------------->
  const eventEncompassesRange =
    event.start <= range.start && event.end >= range.end;

  const isWithinRange =
    startAndEndWithinRange ||
    eventEndsWithinRange ||
    eventStartsWithinRange ||
    eventEncompassesRange;

  return isWithinRange;
};
