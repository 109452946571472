import React from "react";
import ListItem from "./ListItem";
import {
  TScheduledMetricsModel,
  TForecastMetricsModel,
} from "src/lib/scheduleMetrics";

interface IProps {
  scheduled: TScheduledMetricsModel;
  forecasted: TForecastMetricsModel;
  variance: {
    hours: number;
    percent: number;
  };
}

const TotalColumn: React.FC<IProps> = (props) => {
  const { scheduled, forecasted, variance } = props;

  return (
    <>
      {Object.keys(scheduled.roleTotalHours).map((role) => (
        <ListItem
          key={role}
          role={role}
          label={`${role} Hrs`}
          value={`${scheduled.roleTotalHours[role]} / ${forecasted.roleTotalHours[role]}`}
        />
      ))}
      <ListItem
        label="Total Hrs"
        value={`${scheduled.totalHours} / ${forecasted.totalHours}`}
      />
      <ListItem
        label="Employees"
        value={`${Object.keys(scheduled.employeesScheduled).length}`}
      />
      <ListItem
        label="Total Variance"
        value={`${variance.hours} (${variance.percent}%)`}
      />
    </>
  );
};

export default TotalColumn;
