import React, { useState } from "react";
import {
  TShiftPopupAction,
  TDecoratedDraftShift,
} from "src/components/scheduler/scheduler.types";
import EditDraftShiftForm from "./EditDraftShiftForm";
import { PopupCard } from "src/components/common/popup";
import { CardHeader, CardBody } from "reactstrap";
import EventPopupHeader from "../event_popup/EventPopupHeader";
import DeleteDraftShift from "./DeleteDraftShift";
import { TMomentRange } from "src/lib/rangeHandler";
import AddDraftShiftForm from "./AddDraftShiftForm";

interface IProps {
  event: TDecoratedDraftShift;
  initialView: TShiftPopupAction;
  editValues?: TMomentRange;
  close: () => void;
}

const styles = {
  popup: {
    width: "500px",
  },
};

const actions: TShiftPopupAction[] = ["edit", "delete", "clone"];

const getTitle = (view: TShiftPopupAction) => {
  switch (view) {
    case "edit":
      return "Edit draft shift";
    case "delete":
      return "Delete draft shift";
    case "clone":
      return "Clone draft shift";
  }
};

// optimization so that this component only re-renders if
// relevant props have changed
const areEqual = (prev: IProps, next: IProps) =>
  prev.event === next.event && prev.close === next.close;

const ShiftPopup = React.memo((props) => {
  const { event, close, editValues, initialView } = props;

  const [view, setView] = useState<TShiftPopupAction>(initialView);

  return (
    <PopupCard style={styles.popup}>
      <CardHeader>
        <EventPopupHeader
          view={view}
          setView={setView}
          handleClose={close}
          title={getTitle(view)}
          availableActions={actions}
        />
      </CardHeader>
      <CardBody>
        {view === "edit" && (
          <EditDraftShiftForm
            role={event.data.role}
            employeeId={event.resourceId}
            start={editValues?.start || event.start}
            end={editValues?.end || event.end}
            eventId={event.id}
            handleClose={close}
          />
        )}
        {view === "clone" && (
          <AddDraftShiftForm
            role={event.data.role}
            employeeId={event.resourceId}
            start={editValues?.start || event.start}
            end={editValues?.end || event.end}
            handleClose={close}
          />
        )}
        {view === "delete" && (
          <DeleteDraftShift event={event} handleClose={close} />
        )}
      </CardBody>
    </PopupCard>
  );
}, areEqual);

export default ShiftPopup;
