import React, { useState } from "react";
import styles from "./index.module.scss";
import classnames from "classnames";
import { Collapse } from "reactstrap";
import ArrowToggle from "../common/arrow-toggle/ArrowToggle";

interface IProps {
  children?: React.ReactNode;
  label: string;
  onClick?: () => void;
  visible: boolean;
  icon?: React.ReactNode;
}

const SideMenuItem: React.FC<IProps> = (props) => {
  const { children, label, icon, onClick, visible } = props;
  const [open, setOpen] = useState(false);

  if (!visible) return null;

  const classes = classnames(styles.ItemContainer, {
    [styles.ItemContainer_Open]: open,
  });

  const handleClick = children ? () => setOpen(!open) : onClick;

  return (
    <div className={classes}>
      <button className={styles.Item} type="button" onClick={handleClick}>
        <div className={styles.ItemIcon}>{icon}</div>
        <div className={styles.ItemLabel}>{label}</div>
        {children && (
          <div className={styles.ItemDropdown}>
            <ArrowToggle width="0.75rem" active={open} />
          </div>
        )}
      </button>
      <Collapse isOpen={open}>{children}</Collapse>
    </div>
  );
};

export default SideMenuItem;
