import React, { useState, useCallback } from "react";
import { FormikHelpers } from "formik";
import { Moment } from "moment";
import { TOpenShiftFormValues } from "./types";
import { useStoreState, useStoreActions } from "src/store";
import { errorIsViolation } from "src/lib/violations";
import { AxiosError } from "axios";
import { TShiftViolationErrorData } from "src/api/api.types";
import { validateAndPostShift } from "src/api";
import OpenShiftForm from "./OpenShiftForm";

interface IProps {
  role?: string;
  notifyGroups?: number[];
  reason?: string;
  nearbyLocations?: string[];
  start: Moment;
  end: Moment;
  handleClose: () => void;
}

const AddOpenShiftForm: React.FC<IProps> = (props) => {
  const {
    role,
    start,
    end,
    handleClose,
    notifyGroups,
    reason,
    nearbyLocations,
  } = props;
  const userId = useStoreState((state) => state.user.userId);
  const locationId = useStoreState((state) => state.location.locationId);
  const addOpenShift = useStoreActions(
    (actions) => actions.shifts.addOpenShift
  );
  const [showViolations, setShowViolations] = useState(false);
  const [violationMessage, setViolationMessage] = useState("");

  const submit = useCallback(
    async (
      values: TOpenShiftFormValues,
      helpers: FormikHelpers<TOpenShiftFormValues>
    ) => {
      helpers.setSubmitting(true);
      const formattedStart = values.start.format("YYYY-MM-DD HH:mm:ss");
      const formattedEnd = values.end.format("YYYY-MM-DD HH:mm:ss");

      try {
        // if we are currently showing violations,
        // it means that the submit button should
        // bypass validation
        const runValidation = !showViolations;

        const res = await validateAndPostShift({
          open_shift: "Y",
          user_id: userId,
          location_id: locationId,
          start_time: formattedStart,
          end_time: formattedEnd,
          nearby_locations: values.nearbyLocations,
          groups: values.notifyGroups,
          role: values.role,
          reason: values.reason,
          skip_violation_check: runValidation ? 0 : 1,
        });
        addOpenShift(res.data.data);
        handleClose();
      } catch (err) {
        if (errorIsViolation(err)) {
          setShowViolations(true);
          const error = err as AxiosError<TShiftViolationErrorData>;
          setViolationMessage(error.response?.data.meta.detail || "");
        }
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [showViolations, userId, locationId, handleClose, addOpenShift]
  );

  return (
    <OpenShiftForm
      role={role}
      start={start}
      end={end}
      notifyGroups={notifyGroups}
      reason={reason}
      nearbyLocations={nearbyLocations}
      handleSubmit={submit}
      violationMessage={violationMessage}
      setViolationMessage={setViolationMessage}
      setShowViolations={setShowViolations}
      showViolations={showViolations}
    />
  );
};

export default AddOpenShiftForm;
