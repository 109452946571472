import TagManager from "react-gtm-module";
import mixpanel from "mixpanel-browser";

if (process.env.NODE_ENV === "production") {
  mixpanel.init("16284aed131183bbcc3636aa37385997");

  TagManager.initialize({
    gtmId: "GTM-NV658B4",
  });
}
