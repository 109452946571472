import React from "react";
import { Badge } from "reactstrap";
import styles from "./index.module.scss";
import ArrowToggle from "src/components/common/arrow-toggle/ArrowToggle";
import classnames from "classnames";

interface IProps {
  handleClick: () => void;
  isOpen: boolean;
  count?: number;
  title: string;
  className?: string;
}

const FilterSectionHead: React.FC<IProps> = (props) => {
  const { isOpen, count, title, handleClick, className } = props;
  const classes = classnames(styles.FilterSectionHead, className);

  return (
    <div className={classes} onClick={handleClick}>
      <span className="font-weight-bold">{title}</span>
      <div>
        {count !== 0 && (
          <Badge size="sm" className="mr-2" color="primary" pill>
            {count}
          </Badge>
        )}
        <ArrowToggle width="1rem" active={isOpen} />
      </div>
    </div>
  );
};

export default FilterSectionHead;
