import React, { useState, useCallback } from "react";
import { FormikHelpers } from "formik";
import { Moment } from "moment";
import { TOpenShiftFormValues } from "../shift_form/types";
import { useStoreActions } from "src/store";
import { errorIsViolation } from "src/lib/violations";
import { AxiosError } from "axios";
import { TShiftViolationErrorData } from "src/api/api.types";
import OpenShiftForm from "../shift_form/OpenShiftForm";
import { prepareDraftOpenShift } from "src/lib/drafts";

interface IProps {
  role: string;
  start: Moment;
  end: Moment;
  handleClose: () => void;
}

const AddDraftOpenShiftForm: React.FC<IProps> = (props) => {
  const { role, start, end, handleClose } = props;
  const addDraftOpenShift = useStoreActions(
    (state) => state.draft.addDraftOpenShift
  );
  const [showViolations, setShowViolations] = useState(false);
  const [violationMessage, setViolationMessage] = useState("");

  const submit = useCallback(
    async (
      values: TOpenShiftFormValues,
      helpers: FormikHelpers<TOpenShiftFormValues>
    ) => {
      helpers.setSubmitting(true);
      try {
        const params = prepareDraftOpenShift({
          role: values.role,
          start: values.start,
          end: values.end,
          groups: values.notifyGroups,
          nearbyLocations: values.nearbyLocations,
          reason: values.reason,
        });
        addDraftOpenShift(params);

        handleClose();
      } catch (err) {
        if (errorIsViolation(err)) {
          setShowViolations(true);
          const error = err as AxiosError<TShiftViolationErrorData>;
          setViolationMessage(error.response?.data.meta.detail || "");
        }
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [handleClose, addDraftOpenShift]
  );

  return (
    <OpenShiftForm
      role={role}
      start={start}
      end={end}
      handleSubmit={submit}
      violationMessage={violationMessage}
      setViolationMessage={setViolationMessage}
      setShowViolations={setShowViolations}
      showViolations={showViolations}
    />
  );
};

AddDraftOpenShiftForm.defaultProps = {
  role: "",
};

export default AddDraftOpenShiftForm;
