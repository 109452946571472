import { useMemo } from "react";
import moment, { Moment } from "moment";

const useDuration = (start: Moment, end: Moment) => {
  return useMemo(() => {
    const duration = moment.duration(end.diff(start));
    return duration.asHours();
  }, [start, end]);
};

export default useDuration;
