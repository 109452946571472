import React, { useMemo, useState, useCallback } from 'react';
import { TDecoratedShift, TShiftPopupAction } from '../../scheduler.types';
import InteractiveShiftWrapper from '../../InteractiveShiftWrapper';
import ShiftPopup from './ShiftPopup';
import useRoleColor from '../../hooks/useRoleColor';
import BaseEventCard from '../../BaseEventCard';
import PrettyTimeRange from 'src/components/common/pretty_time_range/PrettyTimeRange';
import useDuration from 'src/hooks/useDuration';
import PopupHandler, {
  IPopupHandlerBag,
} from 'src/components/common/popup/PopupHandler';
import { Moment } from 'moment';
import { TMomentRange } from 'src/lib/rangeHandler';

interface IProps {
  compact?: boolean;
  event: TDecoratedShift;
  canResize: boolean;
}

const ShiftEvent: React.FC<IProps> = (props) => {
  const { event, canResize } = props;
  const [popupVisible, setPopupVisible] = useState(false);
  const [persistEditedState, setPersistEditedState] = useState(false);
  const [initialPopupView, setInitialPopupView] = useState<TShiftPopupAction>(
    'read'
  );
  const [editValues, setEditValues] = useState<TMomentRange>();
  const roleColor = useRoleColor(event.data.role);
  const duration = useDuration(event.start, event.end);

  const containerStyles = useMemo(
    () => ({
      backgroundColor: roleColor,
    }),
    [roleColor]
  );

  const onResize = useCallback(
    (start: Moment, end: Moment) => {
      setInitialPopupView('edit');
      setPersistEditedState(true);
      setEditValues({ start, end });
      setPopupVisible(true);
    },
    [setPopupVisible, setPersistEditedState, setEditValues, setInitialPopupView]
  );

  const setVisible = useCallback(
    (visible: boolean) => {
      if (!visible) {
        setInitialPopupView('read');
        setEditValues(undefined);
        setPersistEditedState(false);
      }
      setPopupVisible(visible);
    },
    [setInitialPopupView, setEditValues, setPopupVisible]
  );

  const renderPopup = useCallback(
    ({ hide }: IPopupHandlerBag) => (
      <ShiftPopup
        event={event}
        editValues={editValues}
        initialView={initialPopupView}
        close={hide}
      />
    ),
    [event, editValues, initialPopupView]
  );

  return (
    <InteractiveShiftWrapper
      event={event}
      canResize={canResize}
      onResize={onResize}
      persistEditedState={persistEditedState}
    >
      <PopupHandler
        renderPopup={renderPopup}
        placement="bottom"
        visible={popupVisible}
        setVisible={setVisible}
        fillHeight={true}
      >
        {({ visible, show }) => (
          <BaseEventCard
            event={event}
            isActive={visible}
            style={containerStyles}
            onClick={show}
          >
            <PrettyTimeRange start={event.start} end={event.end} />
            <div>
              {event.data.role} ({duration}hrs)
            </div>
          </BaseEventCard>
        )}
      </PopupHandler>
    </InteractiveShiftWrapper>
  );
};

export default ShiftEvent;
