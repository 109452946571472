import React from "react";
import ControlledPopupHandler from "src/components/common/popup/ControlledPopupHandler";
import IconButton from "src/components/common/icon-button/IconButton";
import WandIcon from "src/components/common/icons/WandIcon";
import AutoschedulerPopup from "./AutoschedulerPopup";

const Autoscheduler: React.FC = () => {
  return (
    <ControlledPopupHandler
      placement="bottom-end"
      renderPopup={({ hide }) => <AutoschedulerPopup handleClose={hide} />}
    >
      {({ show }) => (
        <IconButton
          tooltip="Autoschedule"
          id="AutoscheduleAction"
          onClick={show}
        >
          <WandIcon width="1rem" />
        </IconButton>
      )}
    </ControlledPopupHandler>
  );
};

export default Autoscheduler;
