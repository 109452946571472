import React, { useMemo } from "react";
import useRoleColor from "./hooks/useRoleColor";
import { Badge, BadgeProps } from "reactstrap";
import styles from "./index.module.scss";
import classnames from "classnames";

interface IProps extends BadgeProps {
  value: string | null;
}

const RoleBadge: React.FC<IProps> = (props) => {
  const { value, className, ...rest } = props;
  const roleColor = useRoleColor(value);

  const classes = classnames(className, styles.RoleBadge);
  const roleStyles = useMemo(
    () => ({
      backgroundColor: roleColor,
    }),
    [roleColor]
  );

  return (
    <Badge size="sm" className={classes} style={roleStyles} pill {...rest}>
      {value}
    </Badge>
  );
};

export default RoleBadge;
