import React from "react";
import { SchedulerTypes } from "src/components/schedulerTable";
import CellHandler from "../cell/CellHandler";
import HourlyAvailability from "../availability/HourlyAvailability";

interface IProps {
  column: SchedulerTypes.IColumn;
  row: SchedulerTypes.IRow;
}

const DayCell: React.FC<IProps> = (props) => {
  const { column, row } = props;

  return (
    <CellHandler column={column} row={row}>
      <HourlyAvailability time={column.time} userId={+row.id} />
    </CellHandler>
  );
};

export default DayCell;
