import React from "react";
import classnames from "classnames";

interface IProps {
  className?: string;
  children: React.ReactNode;
}

const PopupHeadIcons = (props: IProps) => {
  const { children, className } = props;

  const classes = classnames(
    className,
    "d-flex flex-grow-1 align-items-center justify-content-end"
  );

  return <div className={classes}>{children}</div>;
};

export default PopupHeadIcons;
