import React from "react";
import { Card, CardProps } from "reactstrap";
import classnames from "classnames";

const PopupCard: React.FC<CardProps> = (props) => {
  const { children, className, ...rest } = props;

  const classes = classnames("popup", className);

  return (
    <Card className={classes} {...rest}>
      {children}
    </Card>
  );
};

export default PopupCard;
