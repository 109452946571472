import React from "react";
import ControlledPopupHandler from "src/components/common/popup/ControlledPopupHandler";
import IconButton from "src/components/common/icon-button/IconButton";
import VerticalEllipsisIcon from "src/components/common/icons/VerticalEllipsisIcon";
import GeneralMenuPopup from "./GeneralMenuPopup";

const GeneralMenu: React.FC = () => {
  return (
    <ControlledPopupHandler renderPopup={() => <GeneralMenuPopup />}>
      {({ show }) => (
        <IconButton id="GeneralMenu" onClick={show}>
          <VerticalEllipsisIcon width="1rem" />
        </IconButton>
      )}
    </ControlledPopupHandler>
  );
};

export default GeneralMenu;
