import React, { useMemo } from "react";
import { useStoreState } from "src/store";
import styles from "./index.module.scss";
import moment, { Moment } from "moment";
import { IPartialAvailability, AvailabilityType } from "./types";
import PartialAvailability from "./PartialAvailability";

interface IProps {
  userId: number;
  time: Moment;
}

const DailyAvailability: React.FC<IProps> = (props) => {
  const { userId, time } = props;
  const userAvailability = useStoreState(
    (state) => state.availability.userAvailability[userId]
  );

  const isoWeekday = useMemo(() => time.isoWeekday(), [time]);

  const availability = useMemo(() => {
    const dayRecords = (userAvailability || []).filter(
      (record) => record.isoWeekday === isoWeekday
    );
    return dayRecords;
  }, [userAvailability, isoWeekday]);

  const type = useMemo(() => {
    let avail: AvailabilityType = "available";
    const hasRecords = availability.length > 0;
    if (hasRecords) {
      const hasAllDayUnavailableRecord = availability.some(
        (record) => record.allDay && !record.available
      );
      avail = hasAllDayUnavailableRecord
        ? "unavailable"
        : "partially_available";
    }
    return avail;
  }, [availability]);

  const partialAvailabilityData = useMemo<IPartialAvailability[]>(() => {
    if (type !== "partially_available") return [];
    return availability.map((a) => ({
      available: a.available,
      start: moment(a.startTime, "hh:mm:ss"),
      end: moment(a.endTime, "hh:mm:ss"),
    }));
  }, [type, availability]);

  if (type === "available") {
    return null;
  }

  if (type === "unavailable") {
    return (
      <div
        className={`${styles.DailyAvailability} ${styles.DailyAvailability_Unavailable}`}
      >
        Unavailable
      </div>
    );
  }

  return <PartialAvailability data={partialAvailabilityData} />;
};

export default DailyAvailability;
