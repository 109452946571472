import groupEvents from "./groupEvents";
import { IDecoratedEvent, IResource } from "../types";

const generateRows = (resources: IResource[], events: IDecoratedEvent[]) =>
  resources.map((resource) => {
    const resourceEvents = events
      .filter((e) => e.resourceId === resource.id)
      .sort((a, b) => a.start.valueOf() - b.start.valueOf());

    const grouped = groupEvents(resourceEvents);

    return {
      ...resource,
      events: grouped.events,
      groupCount: grouped.groupCount,
    };
  });

export default generateRows;
