import React from "react";
import ControlledPopupHandler from "src/components/common/popup/ControlledPopupHandler";
import IconButton from "src/components/common/icon-button/IconButton";
import AddIcon from "src/components/common/icons/AddIcon";
import { useStoreState } from "src/store";
import AddDraftShiftPopup from "../draft_shift/AddDraftShiftPopup";
import AddShiftPopup from "../event_popup/AddShiftPopup";

interface IProps {
  draftPublished: boolean;
}

const AddAction: React.FC<IProps> = (props) => {
  const { draftPublished } = props;
  const start = useStoreState((state) => state.scheduler.start);

  return (
    <ControlledPopupHandler
      placement="bottom-end"
      renderPopup={({ hide }) =>
        draftPublished ? (
          <AddShiftPopup close={hide} time={start} />
        ) : (
          <AddDraftShiftPopup close={hide} time={start} />
        )
      }
    >
      {({ show }) => (
        <IconButton tooltip="Add Shift" id="AddActionMenu" onClick={show}>
          <AddIcon width="1rem" />
        </IconButton>
      )}
    </ControlledPopupHandler>
  );
};

export default AddAction;
