import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

const CaretDownIcon: React.FC<Props> = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      d="M23.468 2.984a2 2 0 00-1.742-1.018H2.274A2 2 0 00.563 5l9.726 16.07a2 2 0 003.422 0L23.437 5a2 2 0 00.031-2.016z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);

export default CaretDownIcon;
