import { Computed, computed } from 'easy-peasy';
import { StoreModel } from './index';
import {
  TStateResources,
  TStateEmployeeResource,
  TEventTypeWithRole,
} from 'src/components/scheduler/scheduler.types';
import {
  sortEmployeesByName,
  sortEmployeesByRole,
} from 'src/lib/sortEmployees';

interface ResourcesModel {
  resources: Computed<ResourcesModel, TStateResources, StoreModel>;
  employeeResources: Computed<
    ResourcesModel,
    TStateEmployeeResource[],
    StoreModel
  >;
  sortedEmployeeResources: Computed<
    ResourcesModel,
    TStateEmployeeResource[],
    StoreModel
  >;
  filteredEmployeeResources: Computed<
    ResourcesModel,
    TStateEmployeeResource[],
    StoreModel
  >;
}

const resourcesStore: ResourcesModel = {
  resources: computed(
    [(state) => state.sortedEmployeeResources],
    (employees) => {
      return [
        {
          id: 'open',
          data: null,
        },
        ...employees,
      ];
    }
  ),

  filteredEmployeeResources: computed(
    [
      (state) => state.employeeResources,
      (_, store) => store.events.scheduledEmployees,
      (_, store) => store.filters.roleFiltersCount,
      (_, store) => store.filters.employeeFiltersCount,
      (_, store) => store.filters.employeeFilters,
      (_, store) => store.filters.roleFilters,
      (_, store) => store.filters.showOnlyScheduled,
      (_, store) => store.events.events,
    ],
    (
      employeeResources,
      scheduledEmployees,
      roleFiltersCount,
      employeeFiltersCount,
      employeeFilters,
      roleFilters,
      showOnlyScheduled,
      events
    ) => {
      let filtered = employeeResources;

      // should display all employees in bridge
      // and any employees in the location with
      // a shift but no branch account
      filtered = filtered.filter((emp) =>
        emp.data.hasBranchAccount && !emp.data.hasBridgeRecord
          ? scheduledEmployees[emp.id]
          : true
      );

      const hasCountFilters =
        roleFiltersCount !== 0 || employeeFiltersCount !== 0;
      const skipFilter = !hasCountFilters && !showOnlyScheduled;

      if (skipFilter) return filtered;

      // if we are only showing scheduled employees,
      // run an initial filter to return only employees
      // scheduled
      if (showOnlyScheduled) {
        filtered = filtered.filter((e) => scheduledEmployees[e.data.uid]);
      }

      // if there are filters for roles or employees,
      // apply that second layer of filtering next.
      if (hasCountFilters) {
        filtered = filtered.filter((e) => {
          const employeeMatchesRole = !!(
            employeeFilters[e.data.uid] ||
            (e.data.role && roleFilters[e.data.role])
          );

          if (employeeMatchesRole) return true;

          const filterableEmployeeEvents = events.filter(
            (event) => event.resourceId === e.id && event.type !== 'timeoff'
          );

          return filterableEmployeeEvents.some((event) => {
            const eventData = event.data as TEventTypeWithRole;
            return roleFilters[eventData.role];
          });
        });
      }

      return filtered;
    }
  ),

  sortedEmployeeResources: computed(
    [
      (state) => state.filteredEmployeeResources,
      (_, store) => store.sort.sortBy,
    ],
    (employees, sortBy) => {
      if (sortBy === 'name') {
        return sortEmployeesByName(employees);
      } else {
        return sortEmployeesByRole(employees);
      }
    }
  ),

  employeeResources: computed(
    [(_, store) => store.employees.employeeRecords],
    (employees) =>
      employees.map((emp) => ({
        id: emp.uid,
        data: { ...emp },
      }))
  ),
};

export default resourcesStore;
