import React from "react";
import EventHandler from "src/components/scheduler/EventHandler";
import { IDecoratedEvent } from "src/components/schedulerTable/types";

interface IProps {
  event: IDecoratedEvent;
}

const WeekEvent: React.FC<IProps> = (props) => {
  const { event } = props;

  return <EventHandler event={event} canResize={false} compact={false} />;
};

export default WeekEvent;
