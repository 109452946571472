import React from "react";
import styles from "../index.module.scss";
import { TEmployeeRecord } from "src/components/scheduler/scheduler.types";
import { CloseButton } from "src/components/common/close-button";

interface IProps {
  employee: TEmployeeRecord;
  cancel: (e: React.MouseEvent) => void;
}

const SelectedEmployee: React.FC<IProps> = (props) => {
  const { employee, cancel } = props;

  return (
    <div className={styles.ShiftDropdownValue}>
      {employee.name}
      <CloseButton
        tooltip="Clear selection"
        onClick={cancel}
        id="SelectedEmployeeTooltip"
      />
    </div>
  );
};

export default SelectedEmployee;
