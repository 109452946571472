import { useEffect, useMemo } from 'react';
import { useStoreState, useStoreActions } from 'src/store';
import {
  processScheduledData,
  processForecastData,
  processChartData,
} from './lib';

export const useForecastQuery = () => {
  const fetchForecastData = useStoreActions(
    (actions) => actions.demand.fetchForecastData
  );
  const location = useStoreState((state) => state.location.location);
  const start = useStoreState((state) => state.scheduler.start);
  const end = useStoreState((state) => state.scheduler.end);

  // watch location and date selected
  useEffect(() => {
    if (location) {
      fetchForecastData({
        locationId: location.location_id,
        startDate: start.format('YYYY-MM-DD'),
        endDate: start.format('YYYY-MM-DD'),
      });
    }
  }, [location, start, end, fetchForecastData]);
};

export const useForecastChartData = () => {
  const start = useStoreState((state) => state.scheduler.start);
  const events = useStoreState((state) => state.events.events);
  const forecast = useStoreState((state) => state.demand.forecastData.data);

  return useMemo(() => {
    const scheduledData = processScheduledData(start, events);
    const { forecastData, positionsWithData } = processForecastData(forecast);
    const chartData = processChartData(
      forecastData,
      scheduledData,
      positionsWithData
    );
    return {
      chartData,
      positionsWithData,
    };
  }, [start, events, forecast]);
};
