import Cookies from "js-cookie";
import moment from "moment";

const TOKEN_KEY = "auth_token";

export const setTokenCookie = (val: string): string => {
  // replace Bearer if it exists in the string
  const token = val.replace("Bearer ", "");
  Cookies.set(TOKEN_KEY, token);
  return val;
};

function jwtDecode(t: string) {
  const token: any = {};
  token.raw = t;
  token.header = JSON.parse(window.atob(t.split(".")[0]));
  token.payload = JSON.parse(window.atob(t.split(".")[1]));
  return token;
}

export const getTokenCookie = (): string | undefined => {
  return Cookies.get(TOKEN_KEY);
};

export const removeTokenCookie = (): void => {
  return Cookies.remove(TOKEN_KEY);
};

export const removeV1TokenCookie = (): void => {
  return Cookies.remove("token");
};

export const parseToken = (token?: string) => {
  return !!token ? jwtDecode(token) : null;
};

export const getPayloadFromTokenCookie = () => {
  const token = parseToken(getTokenCookie());
  return !!token ? token.payload : null;
};

export const getUserIdFromTokenCookie = () => {
  const token = parseToken(getTokenCookie());
  return !!token ? token.payload.sub : null;
};

export const getUserIdFromTokenString = (token: string) => {
  const parsed = parseToken(token);
  return !!parsed ? parsed.payload.sub : null;
};

export const hasValidToken = (): boolean => {
  const payload = getPayloadFromTokenCookie();

  if (!payload) {
    return false;
  }

  const isExpired = payload.exp < moment().unix();
  return !isExpired;
};
