import { computed, Computed, Thunk, thunk } from 'easy-peasy';
import { getNearbyLocations, getNearbyLocationsDirectory } from 'src/api';
import { TNearbyEmployee, TNearbyLocation } from 'src/api/api.types';
import { DataModel, dataModel } from 'src/lib/dataModel';
import { StoreModel } from '.';

interface NearbyModel {
  employees: DataModel<TNearbyEmployee[]>;
  fetchNearbyEmployees: Thunk<NearbyModel, string[]>;

  locations: DataModel<TNearbyLocation[]>;
  fetchNearbyLocations: Thunk<NearbyModel, string>;

  nearbyScheduledEmployees: Computed<
    NearbyModel,
    TNearbyEmployee[],
    StoreModel
  >;
}

const nearbyStore: NearbyModel = {
  ///////////////////////
  // NEARBY EMPLOYEES
  ///////////////////////

  employees: dataModel([]),

  fetchNearbyEmployees: thunk(async (actions, ids) => {
    const { setLoading, setError, setData } = actions.employees;
    setLoading(true);
    setError(null);
    try {
      const res = await getNearbyLocationsDirectory(ids);
      setData(res.data.response);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }),

  ///////////////////////
  // NEARBY LOCATIONS
  ///////////////////////

  locations: dataModel([]),

  fetchNearbyLocations: thunk(async (actions, locationId) => {
    const { fetchNearbyEmployees } = actions;
    const { setLoading, setError, setData } = actions.locations;
    setLoading(true);
    setError(null);
    try {
      const res = await getNearbyLocations(locationId);
      if (res.data.data.length > 0) {
        const ids = res.data.data.map((x) => x.id);
        fetchNearbyEmployees(ids);
      }

      setData(res.data.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }),

  nearbyScheduledEmployees: computed(
    [
      (state) => state.employees.data,
      (_, store) => store.shifts.workshifts.data,
    ],
    (employees, shifts) => {
      // nearby employees have a "location" object on the "user" object
      const shiftsBelongingToNearbyEmployees = shifts.filter(
        (shift) => !!shift.user.location
      );

      return employees.filter((employee) => {
        const employeeIsAssignedShift = shiftsBelongingToNearbyEmployees.some(
          (shift) =>
            shift.user.id === employee.user_id ||
            shift.user.name === employee.name
        );
        return employeeIsAssignedShift;
      });
    }
  ),
};

export default nearbyStore;
