import React, { useState, useMemo, useCallback } from "react";
import { Label, ListGroup } from "reactstrap";
import { useStoreState } from "src/store";
import styles from "../index.module.scss";
import useOutsideClickHander from "src/components/scheduler/hooks/useOutsideClickHandler";
import useEscapeClickHandler from "src/components/scheduler/hooks/useEscapeClickHandler";
import DropdownListItem from "src/components/common/dropdown-list/DropdownListItem";
import ChevronDownIcon from "src/components/common/icons/ChevronDownIcon";

interface IProps {
  value: string[];
  setFieldValue: (id: "nearbyLocations", value: string[]) => void;
}

const FIELD_ID = "nearbyLocations";

const NearbyLocationsField: React.FC<IProps> = (props) => {
  const { value, setFieldValue } = props;
  const [open, setOpen] = useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  const nearbyLocations = useStoreState((state) => state.nearby.locations.data);

  const hide = useCallback(() => {
    if (open) setOpen(false);
  }, [open, setOpen]);

  useEscapeClickHandler(hide);
  useOutsideClickHander(ref, hide, open);

  const toggleLocation = useCallback(
    (id: string) => () => {
      let result = [];
      if (value.includes(id)) {
        result = value.filter((v) => v === id);
      } else {
        result = [...value, id];
      }
      setFieldValue(FIELD_ID, result);
    },
    [setFieldValue, value]
  );

  const selectAll = useCallback(() => setFieldValue(FIELD_ID, []), [
    setFieldValue,
  ]);

  const valueLabel = useMemo(() => {
    if (value.length === 0) return "None";
    return `${value.length} of ${nearbyLocations.length}`;
  }, [value, nearbyLocations]);

  return (
    <div className={`${styles.ShiftDropdownContainer} form-group`} ref={ref}>
      <Label for={FIELD_ID}>Locations</Label>
      <div className={styles.ShiftDropdownValue} onClick={() => setOpen(!open)}>
        {valueLabel}
        <ChevronDownIcon />
      </div>
      {open && (
        <div className={styles.ShiftDropdown}>
          <ListGroup flush>
            <DropdownListItem onClick={selectAll} active={value.length === 0}>
              No nearby locations
            </DropdownListItem>
            {nearbyLocations.map((location) => (
              <DropdownListItem
                key={location.id}
                active={value.includes(location.id)}
                onClick={toggleLocation(location.id)}
              >
                {location.name}
              </DropdownListItem>
            ))}
          </ListGroup>
        </div>
      )}
    </div>
  );
};

export default NearbyLocationsField;
