import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import classnames from "classnames";

interface IProps {
  tooltip?: string;
  children: React.ReactNode;
  id: string;
  className?: string;
  hasAlert?: boolean;
  onClick: (e: any) => void;
}

const IconButton: React.FC<IProps> = (props) => {
  const { tooltip, onClick, id, children, hasAlert, className } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const classes = classnames(className, "circle-btn", {
    "has-alert": hasAlert,
  });

  return (
    <>
      <button onClick={onClick} className={classes} id={id}>
        {children}
      </button>

      {tooltip && (
        <Tooltip
          hideArrow={true}
          placement="bottom"
          offset="0,0"
          isOpen={tooltipOpen}
          target={id}
          toggle={toggle}
        >
          {tooltip}
        </Tooltip>
      )}
    </>
  );
};

export default IconButton;
